import { CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const DISCLAIMERS_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.DISCLAIMERS;

export default () => ({
  // aliases for usage in form templates
  DISCLAIMERS_FIELD_ALIASES: {
    type: Object,
    default: () => DISCLAIMERS_FIELD_ALIASES,
  },
  [DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO]: Boolean,
});
