export default {
  accentGradientStyle: (state, { currentTheme }) => {
    const DEFAULT_FROM = '#17DF90';
    const DEFAULT_TO = '#8BF153';
    const GRADIENT_TEMPLATE = 'linear-gradient(218.56deg, {FROM} 2.99%, {TO} 109.59%)';
    if (currentTheme.accentGradientFrom && currentTheme.accentGradientTo) {
      return GRADIENT_TEMPLATE.replace('{FROM}', currentTheme.accentGradientFrom).replace(
        '{TO}',
        currentTheme.accentGradientTo
      );
    }
    return GRADIENT_TEMPLATE.replace('{FROM}', DEFAULT_FROM).replace('{TO}', DEFAULT_TO);
  },
  currentThemeIsDark: ({ theme }) => theme.dark,
  currentTheme: ({ theme }) => {
    if (theme.dark) {
      return theme.custom.dark || theme.default.dark;
    }
    return theme.custom.light || theme.default.light;
  },
  infoSidebarData: state => state.infoSidebarData,
  sidebarContent: state => state.sidebarContent,
  scrollDisabled: state => state.scroll.disabled,
  sidebarLoading: state => state.sidebarLoading,
  updateOrgDialog: state => state.updateOrgDialog,
  updatePolicyDialog: state => state.updatePolicyDialog,
  currentLang: state => state.currentLang,
  countriesOptions: state => state.countries.map(({ name, code }) => ({ text: name, value: code })),
  statesOptions: state =>
    Object.entries(state.states).reduce(
      (acc, { 0: key, 1: value }) => ({
        ...acc,
        [key]: value.map(({ name, code }) => ({ text: name, value: code })),
      }),
      {}
    ),
  countriesDictionary: state =>
    state.countries.reduce((acc, { name, code }) => ({ ...acc, [code]: name }), {}),
  statesDictionary: state =>
    Object.entries(state.states).reduce(
      (accSummary, { 1: value }) => ({
        ...accSummary,
        ...value.reduce((acc, { name, code }) => ({ ...acc, [code]: name }), {}),
      }),
      {}
    ),
};
