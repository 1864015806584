import { mapMutations, mapActions, mapGetters } from 'vuex';
import { claimStatuses } from '@/utils/options';

export default {
  data() {
    return {
      claimFilterOptions: {
        status: [
          {
            text: 'All statuses',
            value: 'all',
          },
          ...claimStatuses,
        ],
      },
    };
  },
  computed: {
    ...mapGetters('claims', ['claimFilters']),
    ...mapGetters('organizations', ['organizationsOptions']),
    claimDateFromFilter: {
      get() {
        return this.claimFilters.dateFrom;
      },
      set(dateFrom) {
        const dateString = dateFrom ? `${dateFrom} 00:00:01` : dateFrom;
        this.UPDATE_CLAIM_FILTERS({
          dateFrom: dateString,
        });
      },
    },
    claimDateToFilter: {
      get() {
        return this.claimFilters.dateTo;
      },
      set(dateTo) {
        const dateString = dateTo ? `${dateTo} 23:59:59` : dateTo;
        this.UPDATE_CLAIM_FILTERS({
          dateTo: dateString,
        });
      },
    },
    claimSearchQueryFilter: {
      get() {
        return this.claimFilters.searchQuery;
      },
      set(searchQuery) {
        this.UPDATE_CLAIM_FILTERS({
          searchQuery,
        });
      },
    },
    claimStatusFilter: {
      get() {
        return this.claimFilters.status;
      },
      set(status) {
        this.UPDATE_CLAIM_FILTERS({
          status,
        });
      },
    },
    claimOrganizationsFilter: {
      get() {
        return this.claimFilters.organizations;
      },
      set(organizations) {
        this.UPDATE_CLAIM_FILTERS({
          organizations,
        });
      },
    },
    claimClaimantNameFilter: {
      get() {
        return this.claimFilters.claimantName;
      },
      set(name) {
        this.UPDATE_CLAIM_FILTERS({
          claimantName: name,
        });
      },
    },
  },
  async created() {
    await this.fetchSportsUnions();
  },
  methods: {
    ...mapMutations('claims', ['UPDATE_CLAIM_FILTERS']),
    ...mapActions('claims', ['fetchClaims']),
    ...mapActions('organizations', ['fetchSportsUnions']),
    handleSortType(item) {
      const direction = item ? 'ASC' : 'DESC';
      this.UPDATE_CLAIM_FILTERS({
        sortBy: 'claimantFirstName',
        sortDesc: direction,
      });
    },
  },
};
