import { AnalyticsProvider } from '@/graphql/providers';

export default {
  async logEvent(context, { event, data }) {
    try {
      const { data: response } = await AnalyticsProvider.logEvent({ event, data });
      return response;
    } catch (error) {
      console.error(error);
    }
    return false;
  },
};
