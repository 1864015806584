<template>
  <div>
    <slot v-if="isAbleTo"></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import checkUserAccessAbility from '@/utils/helpers/permissions';

export default {
  name: 'access-control',
  props: {
    action: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', ['userRole']),
    isAbleTo() {
      return checkUserAccessAbility(this.userRole, this.action);
    },
  },
};
</script>
