import PhDialog from '@/components/modals/PhDialog';

export default {
  props: {
    value: Boolean,
  },
  components: {
    PhDialog,
  },
  computed: {
    shown: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    handleDialogClose() {
      this.$emit('close');
    },
  },
};
