import BaseProvider from './BaseProvider';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';

class UserProvider extends BaseProvider {
  authorizeUser(email, password) {
    return this.mutate(mutations.login, {
      loginInput: {
        email,
        password,
      },
    });
  }

  getUser() {
    return this.query(queries.me);
  }

  restoreUserPasswordByEmail(email) {
    return this.mutate(mutations.restore, {
      restorePasswordInput: {
        email,
      },
    });
  }

  updateUserPasswordByToken(token, password, confirmPassword) {
    return this.mutate(mutations.newPassword, {
      newPasswordInput: {
        confirmPassword,
        password,
        token,
      },
    });
  }

  updateUser(id, updateUserInput) {
    return this.mutate(mutations.updateUser, {
      id,
      updateUserInput,
    });
  }

  activateUser(id) {
    return this.mutate(mutations.activateUser, { id });
  }

  deactivateUser(id) {
    return this.mutate(mutations.deactivateUser, { id });
  }

  changePassword({ currentPassword, newPassword, confirmPassword }) {
    return this.mutate(mutations.changePassword, {
      changePasswordInput: {
        currentPassword,
        newPassword,
        confirmPassword,
      },
    });
  }

  changePasswordComplete({ token }) {
    return this.mutate(mutations.changePasswordComplete, {
      changePasswordCompleteInput: {
        token,
      },
    });
  }
}

export default UserProvider;
