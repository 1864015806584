export default {
  params: {
    title: '',
    description: '',
    status: '',
    type: '',
    btnText: '',
    contentTextAlign: '',
  },
  resolveDialogFunc: () => {},
  shown: false,
};
