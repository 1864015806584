import { env } from '@/utils/config';
import { CaptchaProvider } from '@/graphql/providers';

const { RECAPTCHA_SITEKEY } = env;
// const RECAPTCHA_SITEKEY = `6LctO88UAAAAAGk815qsR8kQ5I5G9-HnvLljGvBi`;

const state = {
  mounted: false,
};

const mutations = {
  SET_MOUNTED(ctx, value) {
    Object.assign(ctx, { mounted: !!value });
  },
};

const actions = {
  mountCaptcha(context) {
    return new Promise((resolve, reject) => {
      if (window && document) {
        try {
          const body = document.querySelector('body');
          if (body) {
            if (RECAPTCHA_SITEKEY) {
              const onloadCallback = () => {
                context.commit('SET_MOUNTED', true);
                resolve(true);
              };
              // set onloadCallback to window, so that reCAPTCHA could find & execute it
              window.onloadCallback = onloadCallback;
              // create & configure the script tag
              const script = document.createElement('script');
              script.setAttribute(
                'src',
                `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=${RECAPTCHA_SITEKEY}`
              );
              script.async = true;
              script.defer = true;
              // append script tag to the end of the <body>
              body.appendChild(script);
            } else {
              throw new Error("RECAPTCHA_SITEKEY wasn't present in the app environment variables");
            }
          }
        } catch (err) {
          reject(err);
        }
      } else {
        reject(new Error('It appears the app is not running in a browser environment'));
      }
    });
  },
  executeAction(context, action) {
    return new Promise(async (resolve, reject) => {
      try {
        if (context.state.mounted && window.grecaptcha) {
          if (action) {
            const validateVerifyRecaptchaResponse = response =>
              Boolean(response && response.data && response.data.verifyRecaptcha);
            window.grecaptcha.execute(RECAPTCHA_SITEKEY, { action }).then(
              token => {
                context
                  .dispatch('validateToken', token)
                  .then(response => {
                    if (validateVerifyRecaptchaResponse(response)) {
                      resolve(response.data.verifyRecaptcha);
                    }
                    resolve(response);
                  })
                  .catch(reject);
              },
              reason => {
                reject(new Error(reason));
              }
            );
          } else {
            throw new Error('The provided action was invalid');
          }
        } else {
          resolve();
        }
      } catch (err) {
        reject(err);
      }
    });
  },
  executeLoginCaptcha(context) {
    return context.dispatch('executeAction', 'login');
  },
  executeSignUpCaptcha(context) {
    return context.dispatch('executeAction', 'signUp');
  },
  validateToken(context, token) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await CaptchaProvider.validateCaptchaByTokenMutation(token);
        if (response && response.data && response.data.verifyRecaptcha) {
          resolve(response);
        } else {
          reject(new Error('Wrong reCAPTCHA validation response'));
        }
      } catch (err) {
        reject(err);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
