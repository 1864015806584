import { themes } from '@/utils/config';

export default {
  currentLang: 'ENG',
  infoSidebarData: {
    shown: false,
    id: '',
    content: 'organization',
  },
  updateOrgDialog: {
    show: false,
    content: 'organization',
    type: 'create',
  },
  updatePolicyDialog: {
    show: false,
    content: 'organization',
    type: 'create',
  },
  sidebarLoading: false,
  sidebarContent: {},
  logoUrl: '',
  theme: {
    dark: false,
    custom: {
      light: null,
      dark: null,
    },
    default: {
      light: themes.light,
      dark: null,
    },
  },
  scroll: {
    disabled: false,
  },
  countries: [],
  states: {},
};
