import compact from '@/utils/helpers/array/compact';
import store from '@/store/index';

const fetchUserOnRouteChange = (() => {
  let userFetched = false;
  return () =>
    new Promise(async resolve => {
      if (!userFetched) {
        await store
          .dispatch('user/fetchUser')
          .then(response => {
            userFetched = !!(response && response.me);
            resolve(userFetched);
          })
          .catch(() => {
            store.dispatch('user/logout');
          });
      }
      resolve(store.getters['user/authed']);
    });
})();

const getRolesRequiredForRoute = matched =>
  matched instanceof Array && matched.length
    ? compact(
        matched.reduce((acc, record) => {
          if (record.meta.requiredRoles instanceof Array) {
            // eslint-disable-next-line
            acc = acc.concat(record.meta.requiredRoles)
          }
          return acc;
        }, [])
      )
    : [];

const redirectToLogin = (to, from, next) => {
  next({
    name: 'login',
    query: {
      redirect: to.fullPath,
    },
  });
};

const redirectToLogout = (to, from, next) => {
  next({
    name: 'logout',
  });
};

const redirectToRoot = (to, from, next) => {
  next(store.getters['user/homeRoute']);
};

export default async (to, from, next) => {
  const tokenPresent = !!(store.getters['user/authToken'] || localStorage.getItem('token'));
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresUnauth = to.matched.some(record => record.meta.requiresAuth === false);
  const requiresChangePassword = to.fullPath.includes('change-token');

  // fetch user if token is present in order to determine further redirects
  const authed =
    tokenPresent && !store.getters['user/authed']
      ? await fetchUserOnRouteChange()
      : store.getters['user/authed'];

  // after async user fetching (or not fetching) - proceed to determine further redirects
  if (requiresAuth) {
    if (authed) {
      // check if authed user can access the 'to' route by role
      const requiredRoles = getRolesRequiredForRoute(to.matched);
      const requiresRole = requiredRoles.length > 0;
      if (requiresRole) {
        const authedByRole =
          requiredRoles &&
          to.matched.some(() => requiredRoles.includes(store.getters['user/userRole']));
        if (!authedByRole) {
          // logout the user if his role doesn't belong to this route
          redirectToLogout(to, from, next);
        }
      }
    } else {
      // redirect to login if requiresAuth & didn't manage to authorize the user
      redirectToLogin(to, from, next);
    }
  } else if (requiresUnauth && !requiresChangePassword) {
    if (authed) {
      redirectToRoot(to, from, next);
    }
  } else if (requiresUnauth && requiresChangePassword) {
    if (authed) {
      store.dispatch('user/logout');
    }
    store.dispatch('user/changePasswordComplete', to.query['change-token']);
  }
  next();
};
