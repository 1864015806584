import { CLAIM_FORM_FIELD_API_ALIASES } from '@/utils/constants';

export default form => {
  if (!(form instanceof Object)) {
    throw new Error('The provided "form" argument is not a valid Object');
  }
  return Object.entries(form).reduce((acc, [field, value]) => {
    if (field === 'coachEmail' || field === 'claimantEmail') {
      if (value !== null && value.length === 0) {
        // eslint-disable-next-line no-param-reassign
        value = null;
      }
    }

    if (CLAIM_FORM_FIELD_API_ALIASES[field]) {
      const fieldAlias = CLAIM_FORM_FIELD_API_ALIASES[field];
      // check if the field should be nested within an object
      if (fieldAlias.includes('.')) {
        // get future field placement object keys
        const fieldAliasObjectKeys = fieldAlias.split('.');
        if (fieldAliasObjectKeys.length) {
          (() => {
            let lastIterationObject = null;
            fieldAliasObjectKeys.forEach((key, i, arr) => {
              // populate an object within the accumulator
              // if the key is not the last
              if (i !== arr.length - 1) {
                if (i === 0) {
                  if (!acc[key]) {
                    acc[key] = {};
                  }
                  // set last iteration object for the further use
                  lastIterationObject = acc[key];
                } else {
                  if (!lastIterationObject[key]) {
                    // set last iteration object for the further use
                    lastIterationObject[key] = {};
                  }
                  // update last iteration object with the latest object assigned
                  lastIterationObject = lastIterationObject[key];
                }
              } else {
                lastIterationObject[key] = value;
                // reset last iteration object
                lastIterationObject = null;
              }
            });
          })();
        }
      } else {
        acc[fieldAlias] = value;
      }
    } else if (!acc.hasOwnProperty(field)) {
      acc[field] = value;
    }
    return acc;
  }, {});
};
