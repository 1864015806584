/* eslint no-param-reassign: 0 */

export default {
  SET_LOADING_CLAIMS(state, loading) {
    state.loading.claims = !!loading;
  },
  SET_LOADING_FNOLS(state, loading) {
    state.loading.fnols = !!loading;
  },
  UPDATE_CLAIMS(context, claims) {
    context.claims = claims;
  },
  UPDATE_CLAIM_FILTERS(context, fragment) {
    context.filters.claims = { ...context.filters.claims, ...fragment };
  },
  UPDATE_FNOLS(context, fnols) {
    context.fnols = fnols;
  },
  FILL_SUBMITTED_FNOL_ID(context, id) {
    context.submittedFnolId = id;
  },
  UPDATE_FNOL_FILTERS(context, fragment) {
    context.filters.fnols = { ...context.filters.fnols, ...fragment };
  },
};
