import BaseProvider from './BaseProvider';
import * as queries from '@/graphql/queries';
import * as mutations from '@/graphql/mutations';

class ClaimsProvider extends BaseProvider {
  createFnol(payload) {
    return this.mutate(mutations.createClaim, {
      createClaimInput: payload,
    });
  }

  createUSASAFnol(createUSASAClaimInput) {
    return this.mutate(mutations.createUSASAClaim, {
      createUSASAClaimInput,
    });
  }

  createFYSAFnol(createFYSAClaimInput) {
    return this.mutate(mutations.createFYSAClaim, {
      createFYSAClaimInput,
    });
  }

  createNAGHAFnol(createNAGHAClaimInput) {
    return this.mutate(mutations.createNAGHAClaim, {
      createNAGHAClaimInput,
    });
  }

  createColoradoFnol(createColoradoClaimInput) {
    return this.mutate(mutations.createColoradoClaim, {
      createColoradoClaimInput,
    });
  }

  createOlympicFnol(createOlympicClaimInput) {
    return this.mutate(mutations.createOlympicClaim, {
      createOlympicClaimInput,
    });
  }

  createCrawfordFnol(createCrawfordClaimInput) {
    return this.mutate(mutations.createCrawfordClaim, {
      createCrawfordClaimInput,
    });
  }

  fetchClaims(claimWhereFilter = {}, sortInput = {}) {
    return this.query(queries.claims, { claimWhereFilter, sortInput }, { fetchPolicy: 'no-cache' });
  }

  fetchClaimForFnolReview(token = '') {
    return this.query(queries.claimByToken, { token });
  }

  fetchFnols(fnolWhereFilter = {}, sortInput = {}) {
    return this.query(queries.fnols, { fnolWhereFilter, sortInput }, { fetchPolicy: 'no-cache' });
  }

  fetchClaimById(id) {
    return this.query(queries.claim, { id }, { fetchPolicy: 'no-cache' });
  }

  generateClaimPdf(id) {
    return this.mutate(mutations.generateClaimPdf, { id });
  }

  getClaimPdfUrl(id) {
    return this.query(queries.getClaimPdfUrl, { id });
  }

  confirmFnol({ token, name }) {
    return this.mutate(mutations.confirmFnol, {
      coachConfirmInput: { token, name },
    });
  }

  coachDecline({ token, reason, name }) {
    return this.mutate(mutations.coachDecline, {
      coachDeclineInput: { token, reason, name },
    });
  }

  setClaimFullyPaid(id) {
    return this.mutate(
      mutations.setClaimFullyPaid,
      { id },
      {},
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: queries.claim, variables: { id } }],
      }
    );
  }

  setClaimPartiallyPaid(id) {
    return this.mutate(
      mutations.setClaimPartiallyPaid,
      { id },
      {},
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: queries.claim, variables: { id } }],
      }
    );
  }

  setClaimDeclined(id, reason) {
    return this.mutate(
      mutations.setClaimDeclined,
      {
        declineClaimInput: {
          id,
          reason,
        },
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: queries.claim, variables: { id } }],
      }
    );
  }

  acceptFnol({ id }) {
    return this.mutate(mutations.acceptFnol, { acceptFnolInput: { id } });
  }

  declineFnol({ id, reason }) {
    return this.mutate(
      mutations.declineFnol,
      { declineFnolInput: { id, reason } },
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: queries.claim, variables: { id } }],
      }
    );
  }

  updateClaim(id, updateClaimInput) {
    return this.mutate(
      mutations.updateClaim,
      {
        id,
        updateClaimInput,
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: queries.claim, variables: { id } }],
      }
    );
  }

  updateFnol(id, updateClaimInput) {
    return this.mutate(
      mutations.updateClaim,
      {
        id,
        updateClaimInput,
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: queries.claim, variables: { id } }],
      }
    );
  }

  exportClaim(id) {
    return this.mutate(
      mutations.exportClaim,
      { id },
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: queries.claim, variables: { id } }],
      }
    );
  }

  generateCrawfordXML(id) {
    return this.query(queries.generateCrawfordXML, { id }, { fetchPolicy: 'no-cache' });
  }
}
export default ClaimsProvider;
