/* eslint camelcase: 0 */
import moment from 'moment';
import {
  getIncidentOccurCase,
  getBodyPartName,
  getBodySideName,
  getEventTypeName,
  getGenderName,
  getInjuryContactTypeName,
  getInjurySeverityTypeName,
  getInjuryTypeName,
  getMembershipTypeName,
  getOrganizationRoleName,
  getOlympicOrganizationRoleName,
  getPlayingSurfaceName,
} from '@/utils/helpers/app/glossary';
import { getClaimantAddressFromClaim } from '@/utils/helpers/app/claims';
import {
  DISPLAY_DATE_FORMAT,
  INJURY_CONTACT_TYPES,
  MEMBERSHIP_TYPES,
  SPORTS_UNION_TYPES,
  LOSS_TYPE_NAMES,
  CALLER_TYPE_NAMES,
  PROPERTY_OWNER_TYPES,
  PROPERTY_OWNER_TYPE_NAMES,
  BSI_INJURY_CODES,
  BSI_BODY_PARTS,
  // GL_CAUSE_CODES,
  // GL_DESCRIPTION_CODES,
} from '@/utils/constants';
import booleanToHuman from '@/utils/helpers/string/booleanToHuman';
import formatAddress from '@/utils/helpers/string/formatAddress';
import isValidString from '@/utils/helpers/string/isValidString';
import formatPhoneNumber from '@/utils/helpers/string/formatPhoneNumber';

export const properClaimInfo = (claim, isFnol = false) => {
  let structuredClaim = {};
  if (claim instanceof Object && Object.values(claim).length) {
    const {
      sportsUnion: { form, stateAdmins },
      claimantCoachPlayerType,
      areInjuredPersonCurrentlyEnrolledAccidentPlan,
      insuranceCompany,
      policyHoldersName,
      policyNumber,
      groupNumber,
      territoryWhereIncidentOccurred,
      isAthleteWasTreatedByCoach,
      coachDeclineReason,
      coachEmail,
      coachFirstName,
      coachLastName,
      coachPhone,
      coachSignature,
      contactType,
      contactOtherType,
      claimDeclineReason,
      claimNumber,
      claimantLastName,
      claimantFirstName,
      claimantMiddleName,
      claimantDOB,
      claimantGender,
      claimantRoleType,
      claimantEmail,
      claimantFathersFullName,
      claimantMothersFullName,
      claimantSignature,
      clubNameOfInjured,
      createdAt,
      eventType,
      nameAndTypeOfEvent,
      files,
      fnolDeclineReason,
      fnolNumber,
      nameOfEventOrTeamsCompetingAgainst,
      memberId,
      membershipType,
      membershipOtherType,
      isContact,
      isReceivedOnsite,
      isTakenByAmbulanceToHospital,
      incidentDate,
      incidentTime,
      incidentLocation,
      injuredBodyPart,
      injuredBodySide,
      injuryDescription,
      injuryType,
      severity,
      olympic,
      olympic_claimantRoleType,
      olympic_claimantParentsFullName,
      olympic_claimantParentsAddress,
      olympic_claimantParentsPhone,
      plainSurface,
      processedAt,
      healthInsurancePlan,
      stateAdmin,
      note,
      claimantHomePhone,
      claimantWorkPhone,
      nameOfTeam,
      nameOfLeague,
      isInjuredPersonAMedicareMedicaidBeneficiary,
      injuryAccidentOccur,
      olympic_didInjuryResultInDeath,
      stateAssociationNationwideAffiliate,
      playersHealthPolicyNumber,
    } = claim;

    const isNagha = form === SPORTS_UNION_TYPES.NAGHA;
    const isOlympic = form === SPORTS_UNION_TYPES.OLYMPIC;
    const isFysa = form === SPORTS_UNION_TYPES.FYSA;
    const isPlayer = claimantCoachPlayerType === 'PLAYER';
    const isCrawford = form === SPORTS_UNION_TYPES.CRAWFORD;

    structuredClaim = {
      ...(isCrawford && {
        'Policy #': playersHealthPolicyNumber,
      }),
      ...(createdAt && {
        'FNOL Creation Date': moment(createdAt).format(DISPLAY_DATE_FORMAT),
      }),
      ...(!isFnol &&
        processedAt && {
          'Claim creation date': moment(processedAt).format(DISPLAY_DATE_FORMAT),
        }),
      ...(isFnol && { 'Fnol #': fnolNumber }),
      ...(!isFnol && { 'Claim #': claimNumber }),
      ...(isValidString(fnolDeclineReason) && {
        'FNOL decline reason': fnolDeclineReason,
      }),
      ...{
        'Member ID':
          memberId ||
          (healthInsurancePlan &&
            healthInsurancePlan.length &&
            healthInsurancePlan[0].healthInsuranceMemberId) ||
          '-',
      },
      ...(isValidString(claimDeclineReason) && {
        'Claim decline reason': claimDeclineReason,
      }),
      ...(isValidString(eventType) && {
        'Type of Event': getEventTypeName(eventType),
      }),
      ...(isValidString(nameAndTypeOfEvent) && {
        'Name and Type of Event': nameAndTypeOfEvent,
      }),
      ...(isValidString(nameOfEventOrTeamsCompetingAgainst) && {
        'Name of Event': nameOfEventOrTeamsCompetingAgainst,
      }),
      ...(incidentDate && { 'Date of Incident': moment(incidentDate).format(DISPLAY_DATE_FORMAT) }),
      ...(incidentTime && { 'Time of Incident': incidentTime }),
      ...(incidentLocation && { 'Location of Incident': incidentLocation }),
      ...(injuryType && { 'Type of Injury': getInjuryTypeName(injuryType) }),
      ...(injuredBodyPart && { 'Injured Body Part': getBodyPartName(injuredBodyPart) }),
      ...(injuredBodySide && { 'Body Side': getBodySideName(injuredBodySide) }),
      // injury contact info
      ...(typeof isContact === 'boolean' && {
        'Is A Contact Injury': booleanToHuman(isContact),
      }),
      ...(isContact && {
        'Injury Contact Type':
          contactType === INJURY_CONTACT_TYPES.OTHER
            ? contactOtherType
            : getInjuryContactTypeName(contactType),
      }),
      ...(severity && { Severity: getInjurySeverityTypeName(severity) }),
      ...(isValidString(plainSurface) && {
        'Playing surface': getPlayingSurfaceName(plainSurface),
      }),
      ...(!isNagha && { 'Received On-site Care': booleanToHuman(isReceivedOnsite) }),
      ...(!isNagha && { 'Taken by ambulance': booleanToHuman(isTakenByAmbulanceToHospital) }),
      'Name of Claimant': `${claimantFirstName}${
        claimantMiddleName ? ` ${claimantMiddleName}` : ''
      } ${claimantLastName}`,
      Gender: getGenderName(claimantGender),
      ...(claimantDOB && { 'Date of Birth': moment(claimantDOB).format(DISPLAY_DATE_FORMAT) }),
      ...(areInjuredPersonCurrentlyEnrolledAccidentPlan && {
        'Insurance Info':
          insuranceCompany ||
          (healthInsurancePlan &&
            healthInsurancePlan.length &&
            healthInsurancePlan[0].companyName) ||
          '-',
        'Policy Number':
          policyNumber ||
          (healthInsurancePlan &&
            healthInsurancePlan.length &&
            healthInsurancePlan[0].policyNumber) ||
          '-',
        'Name of Policyholder':
          policyHoldersName ||
          (healthInsurancePlan &&
            healthInsurancePlan.length &&
            healthInsurancePlan[0].policyHoldersName) ||
          '-',
        'Insurance Group':
          groupNumber ||
          (healthInsurancePlan && healthInsurancePlan.length && healthInsurancePlan[0].groupName) ||
          '-',
      }),
      Address: formatAddress(getClaimantAddressFromClaim(claim)),
      [isPlayer ? 'Pareant/Guardian email' : 'Email']: claimantEmail,
      ...(membershipType && {
        'Membership Type':
          membershipType === MEMBERSHIP_TYPES.OTHER
            ? membershipOtherType
            : getMembershipTypeName(membershipType),
      }),
      ...(!claim.usasa && {
        'Role in organization': olympic
          ? getOlympicOrganizationRoleName(olympic_claimantRoleType)
          : getOrganizationRoleName(claimantRoleType),
      }),
      // claimant's parents
      ...(isValidString(claimantFathersFullName) && {
        "Claimant's Father": claimantFathersFullName,
      }),
      ...(isValidString(claimantMothersFullName) && {
        "Claimant's Mother": claimantMothersFullName,
      }),
      ...(isValidString(olympic_claimantParentsFullName) && {
        "Claimant's Father or Mother": olympic_claimantParentsFullName,
      }),
      ...(isValidString(olympic_claimantParentsAddress) && {
        "Claimant's Father's or Mother's Address": olympic_claimantParentsAddress,
      }),
      ...(isValidString(olympic_claimantParentsPhone) && {
        [isFysa
          ? 'Parent/Guardian Phone'
          : "Claimant's Father's or Mother's Phone"]: olympic_claimantParentsPhone,
      }),
      ...(injuryDescription && {
        'Description of the Incident': injuryDescription,
      }),
      'Signature of the claimant': claimantSignature,
      // coach info
      ...(isValidString(clubNameOfInjured) && {
        'Club name of injured': clubNameOfInjured,
      }),
      ...(isValidString(territoryWhereIncidentOccurred) && {
        'Territory where incident occurred': territoryWhereIncidentOccurred,
      }),
      ...(typeof isAthleteWasTreatedByCoach &&
        !isNagha && {
          'Athlete was treated by Trainer and/or Official': booleanToHuman(
            isAthleteWasTreatedByCoach
          ),
        }),
      ...(isValidString(coachFirstName) &&
        isValidString(coachLastName) && {
          'Name of coach': `${coachFirstName} ${coachLastName}`,
        }),
      ...(isValidString(coachEmail) && { 'Email of the coach': coachEmail }),
      ...(isValidString(coachPhone) && { 'Phone of coach': coachPhone }),
      ...(isValidString(coachDeclineReason) && {
        'Coach decline reason': coachDeclineReason,
      }),
      ...(isValidString(coachSignature) && {
        'Signature of the coach': coachSignature,
      }),
      ...(isValidString(stateAdmin) &&
        stateAdmins &&
        stateAdmins.length > 0 && {
          'State Association/Affiliate': stateAdmins.find(a => a.id === stateAdmin).affiliate,
        }),
      ...(isValidString(claimantHomePhone) && {
        [isPlayer ? 'Pareant/Guardian Home phone' : 'Home phone number']: claimantHomePhone,
      }),
      ...(isValidString(claimantWorkPhone) && {
        [isPlayer ? 'Pareant/Guardian Work phone' : 'Work phone number']: claimantWorkPhone,
      }),
      ...(isValidString(nameOfTeam) && {
        'Name of team': nameOfTeam,
      }),
      ...(isValidString(nameOfLeague) && {
        'Name of league': nameOfLeague,
      }),
      ...(isValidString(stateAssociationNationwideAffiliate) && {
        'State Association': stateAssociationNationwideAffiliate,
      }),
      'Is the injured person a Medicare/Medicaid beneficiary?': booleanToHuman(
        isInjuredPersonAMedicareMedicaidBeneficiary
      ),
      ...(isNagha &&
        isValidString(injuryAccidentOccur) && {
          'Did Accident Occur': getIncidentOccurCase(injuryAccidentOccur),
        }),
      ...((isNagha || isOlympic) && {
        'Did Injury Result in Death': booleanToHuman(olympic_didInjuryResultInDeath),
      }),
      ...(isValidString(note) && {
        Note: note,
      }),
      ...(isCrawford && {
        'Loss Type': LOSS_TYPE_NAMES[claim.lossType],
        'Policy State': claim.policyState,
        // 'GL Cause': GL_CAUSE_CODES[claim.glCauseCode],
        // 'GL Description': GL_DESCRIPTION_CODES[claim.glCauseCode][claim.glDescriptionCode],
        'Injured Body Part Code': BSI_BODY_PARTS[claim.claimantInjuredBodyPartCode],
        'Injury Code': BSI_INJURY_CODES[claim.claimantInjuryCode],
        'Injury Description': claim.claimantInjuryDescription,
        'Injured Doing': claim.claimantInjuredDoing,
        'Loss Location': {
          Name: claim.lossLocation.name,
          Address: formatAddress(claim.lossLocation.address),
          'Is on premises?': claim.lossLocation.isOnPremises ? 'Yes' : 'No',
        },
        'Risk Location': {
          Name: claim.riskLocation.name,
          Address: formatAddress(claim.riskLocation.address),
          'Work Phone': formatPhoneNumber(claim.riskLocation.workPhone, '+1'),
        },
        ...(claim.properties &&
          claim.properties.length && {
            Properties: claim.properties.map(p => ({
              Description: p.description,
              'Damage Description': p.damageDescription,
              'Owner Type': PROPERTY_OWNER_TYPE_NAMES[p.owner.type],
              'Owner Name':
                p.owner.type === PROPERTY_OWNER_TYPES.COMPANY
                  ? p.owner.name
                  : [p.owner.firstName, p.owner.lastName].join(' '),
              'Owner Work Phone': formatPhoneNumber(p.owner.workPhone),
              'Owner Home Phone': formatPhoneNumber(p.owner.homePhone),
              'Owner Address': formatAddress(p.owner.address),
            })),
          }),
        Caller: {
          Name: [claim.caller.firstName, claim.caller.lastName].join(' '),
          'Work Phone': formatPhoneNumber(claim.caller.workPhone),
          Type: CALLER_TYPE_NAMES[claim.caller.type],
        },
        ...(claim.claimNumber && {
          Exported: claim.exported ? 'Yes' : 'No',
        }),
      }),
    };
    if (files instanceof Array && files.length) {
      structuredClaim.files = files;
    }
    return structuredClaim;
  }
  return structuredClaim;
};
