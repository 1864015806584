import getDefaultState from './helpers/getDefaultState';

export default {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_AUTH_TOKEN(state, token = '') {
    state.token = token;
    localStorage.setItem('token', token);
  },
};
