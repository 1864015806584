import { USER_PERMISSIONS } from '@/utils/permissions';

const checkUserAccessAbility = (role = 'SUPER_ADMIN', action) => {
  if (USER_PERMISSIONS[role] && USER_PERMISSIONS[role][action]) {
    return USER_PERMISSIONS[role][action].can;
  }
  return false;
};

export default checkUserAccessAbility;
