<template>
  <v-img
    @click="handleClick"
    :src="logoSrc"
    :height="height"
    width="auto"
    class="logo d-inline-flex"
    contain
  />
</template>

<script>
const DEFAULT_SRC = '/img/logo/ph-claims.svg';

export default {
  name: 'Logo',
  props: {
    large: Boolean,
    src: {
      type: String,
    },
    defaultHeight: {
      type: [Number, String],
      default: 43,
    },
    largeHeight: {
      type: Number,
      default: 66,
    },
  },
  computed: {
    logoSrc() {
      if (typeof this.src !== 'undefined') {
        return this.src;
      }
      return this.$store.state.app.logoUrl || DEFAULT_SRC;
    },
    height() {
      return this.large ? this.largeHeight : this.defaultHeight;
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  flex-grow: 0;
}
</style>
