import Clean from '@/layouts/Clean';
import Dashboard from '@/layouts/Dashboard';

const layoutsComponents = {
  Dashboard,
  Clean,
};

const layoutsHandler = {
  get(target, name) {
    return target[name] || Dashboard;
  },
};

const layouts = new Proxy(layoutsComponents, layoutsHandler);

export default layouts;
