export default {
  props: {
    externalFormErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    hasExternalFormError(field) {
      try {
        return !!this.externalFormErrors[field];
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    getExternalFormError(field) {
      try {
        return this.externalFormErrors[field] || '';
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
};
