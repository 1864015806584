import { USASA_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';
import { isValidString } from '@/utils/helpers/string';

const HEALTH_FIELD_ALIASES = USASA_CLAIM_FORM_FIELD_ALIASES.HEALTH;

const getHealthInsuranceSubForm = () => ({
  [HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_COMPANY_NAME]: '',
  [HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_GROUP_NAME]: '',
  [HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_POLICY_HOLDERS_NAME]: '',
  [HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_MEMBER_ID]: '',
  [HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_POLICY_NUMBER]: '',
});

export default {
  data() {
    return {
      // health insurance info
      healthInsuranceForms: {},
    };
  },
  methods: {
    handleFormFieldInput({ field, value }, formKey = 'form') {
      if (isValidString(field)) {
        const fieldsArray = field.split('.');
        if (fieldsArray.length > 2) {
          this.handleSubFormFieldInput({ field, value });
        } else if (fieldsArray.length === 2) {
          const [key, subKey] = fieldsArray;
          this[formKey][key][subKey] = value;
        }
      }
    },
    handleSubFormFieldInput({ field, value }) {
      if (isValidString(field)) {
        const [rootFormKey, subFormKey, subFormField] = field.split('.');
        if (
          rootFormKey === 'healthInsuranceForms' &&
          isValidString(subFormKey) &&
          isValidString(subFormField)
        ) {
          const subForm = this.form[rootFormKey][subFormKey];
          if (subForm instanceof Object && subForm.hasOwnProperty(subFormField)) {
            subForm[subFormField] = value;
          }
        }
      }
    },
    addHealthInsuranceSubForm() {
      const subFormKeys = Object.keys(this.form.healthInsuranceForms);
      const latestIndex = subFormKeys.reduce((acc, key) => {
        if (isValidString(key)) {
          // eslint-disable-next-line
          const [namespace, stringIndex] = key.split('_')
          const index = Number.parseInt(stringIndex, 10);
          if (!Number.isNaN(index) && index > acc) {
            // eslint-disable-next-line
            acc = index
          }
        }
        return acc;
      }, 0);
      if (latestIndex >= 0) {
        this.form.healthInsuranceForms = Object.assign({}, this.form.healthInsuranceForms, {
          [`plan_${latestIndex + 1}`]: getHealthInsuranceSubForm(),
        });
      }
    },
    deleteHealthInsuranceSubForm(formKey) {
      if (
        isValidString(formKey) &&
        formKey !== 'plan_0' &&
        this.form.healthInsuranceForms[formKey] instanceof Object
      ) {
        const { [formKey]: formToDelete, ...remainingForms } = this.form.healthInsuranceForms;
        this.form.healthInsuranceForms = remainingForms;
      }
    },
    initHealthInsuranceSubForms() {
      this.form.healthInsuranceForms = {
        plan_0: getHealthInsuranceSubForm(),
      };
    },
    resetHealthInsuranceSubForms() {
      if (Object.values(this.form.healthInsuranceForms).length) {
        this.form.healthInsuranceForms = {};
      }
    },
  },
};
