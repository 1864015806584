/* eslint no-param-reassign: 0 */
import { isValidString } from '@/utils/helpers/string';

export default error => {
  window.thatSpecificError = error;
  if (
    error instanceof Object &&
    error.graphQLErrors instanceof Array &&
    error.graphQLErrors.length
  ) {
    return error.graphQLErrors.reduce((acc, err) => {
      if (err.message instanceof Object) {
        const { message } = err;
        if (isValidString(message.message)) {
          acc += `${err.message.message} \n`;
        } else if (isValidString(message.error)) {
          acc += `${err.message.error} \n`;
        }
      } else if (isValidString(err.message)) {
        acc += `${err.message} \n`;
      }
      return acc;
    }, '');
  }
  return error.message;
};
