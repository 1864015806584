<!-- prettier-ignore -->
<template>
  <div class="fill-height">
    <v-slide-x-reverse-transition>
      <InfoSidebar v-if="infoSidebarData.shown" @closeSideBar="handleToggleInfoSidebar">
        <SidebarMenu
          v-if="!sidebarLoading"
          class="app__sidebar-menu"
          slot="menu"
          @sidebarEdit="handleEditEntity"
          @sidebarSA="handleSA"
          @sidebarBA="handleBA"
          @sidebarPI="handlePI"
          @sidebarDelete="handleDeleteOrg"
        />
        <Preloader slot="content" v-if="sidebarLoading" :value="sidebarLoading" />
        <component
          v-else
          @inviteAdminClick="showInviteAdminDialog"
          @addPolicyClick="showAddPolicyDialog"
          slot="content"
          :is="OrgInfo"
          :type="infoSidebarData.content"
          :data="getProperContent"
          :orgLogo="getOrgsLogo"
        ></component>
        <div slot="footer" v-if="contentIsOrgs">
          <v-btn style="display: none" type="submit" depressed rounded>Open Claims</v-btn>
        </div>
        <div slot="footer" v-if="contentIsClaims" class="app__sidebar-footer-wrapper">
          <h4 v-if="claimNeedsExport" class="app__sidebar-footer-heading mb-3">Export Claim</h4>
          <div v-if="claimNeedsExport" class="app__sidebar-footer mb-3">
            <v-btn
              @click="handleClaimExport()"
              :disabled="claimExportBtnDisabled"
              :outlined="true"
              min-width="150"
              color="success"
              depressed
              rounded
            >
              Export
            </v-btn>
          </div>
          <h4 v-if="development" class="app__sidebar-footer-heading mb-3">Select claim status</h4>
          <div class="app__sidebar-footer">
            <v-btn
              v-if="development"
              @click="handleStatusChange('fully')"
              :disabled="claimStatusChangeBtnsDisabled"
              :outlined="!checkClaimStatusIs('FULLY_PAID')"
              min-width="150"
              color="success"
              depressed
              class="ml-4"
              rounded
            >
              Paid
            </v-btn>
            <v-btn
              v-if="development"
              @click="handleStatusChange('part')"
              :disabled="claimStatusChangeBtnsDisabled"
              :outlined="!checkClaimStatusIs('PARTIALLY_PAID')"
              min-width="150"
              depressed
              color="success"
              class="ml-4"
              rounded
            >
              Partially Paid
            </v-btn>
            <v-btn
              v-if="development"
              @click="handleStatusChange('decline')"
              :disabled="claimStatusChangeBtnsDisabled"
              :outlined="!checkClaimStatusIs('DECLINED')"
              min-width="150"
              color="success"
              class="ml-4"
              rounded
            >
              Declined
            </v-btn>
            <v-btn
              v-if="!!claimPdfDownloadUrl[infoSidebarData.id]"
              :href="claimPdfDownloadUrl[infoSidebarData.id]"
              target="_blank"
              class="ml-4"
              text
            >
              Download PDF
            </v-btn>
            <v-btn
              v-else
              @click="handleDownloadClaimPdf"
              :disabled="claimGetPdfUrlBtnDisabled"
              :loading="claimGetPdfUrlBtnDisabled"
              class="ml-4"
              text
            >
              Get PDF Link
            </v-btn>
            <v-btn
              v-if="showViewCrawfordXmlBtn"
              @click="handleClaimGenerateCrawfordXml"
              :disabled="claimViewXmlBtnDisabled"
              :loading="claimViewXmlBtnDisabled"
              class="ml-4"
              text
            >
              View XML
            </v-btn>
          </div>
        </div>
        <div slot="footer" v-if="contentIsFnols && !contentIsDeclinedFnol" class="app__sidebar-footer app__sidebar-footer--start">
          <v-btn
            @click="handleFnolAccept"
            :disabled="fnolStatusChangeBtnsDisabled"
            color="success"
            class="ml-4"
            depressed
            rounded
          >
            Accept
          </v-btn>
          <v-btn
            @click="handleFnolDecline"
            :disabled="fnolStatusChangeBtnsDisabled"
            color="error"
            class="ml-4"
            text
          >
            Decline
          </v-btn>
          <div class="line-break"></div>
          <v-btn
            v-if="claimPdfDownloadUrl[infoSidebarData.id]"
            :href="claimPdfDownloadUrl[infoSidebarData.id]"
            target="_blank"
            class="ml-4"
            text
          >
            Download PDF
          </v-btn>
          <v-btn
            v-else
            @click="handleDownloadClaimPdf"
            :disabled="claimGetPdfUrlBtnDisabled"
            :loading="claimGetPdfUrlBtnDisabled"
            class="ml-4"
            text
          >
            Get PDF Link
          </v-btn>
          <v-btn
            v-if="showViewCrawfordXmlBtn"
            @click="handleClaimGenerateCrawfordXml"
            :disabled="claimViewXmlBtnDisabled"
            :loading="claimViewXmlBtnDisabled"
            class="ml-4"
            text
          >
            View XML
          </v-btn>
        </div>
      </InfoSidebar>
    </v-slide-x-reverse-transition>
    <v-app-bar class="app__header" color="backgroundLight" elevation="0" clipped-right app>
      <v-container class="py-0" fill-height space-between center>
        <Logo @click="redirectToHomeRoute" />
        <v-spacer />
        <MenuLinks class="app__menu-links" v-if="authed && items.length" :items="items" />
        <ContactUsLink  />
        <UserBadge v-if="authed" :user="profile" @logout="handleLogout"></UserBadge>
      </v-container>
    </v-app-bar>
    <v-content class="app__content content fill-height">
      <router-view />
    </v-content>
    <InputDialog
      @dialogSubmitted="rejectEntity"
      v-model="rejectionDialogShown"
      :title="contentIsClaims ? 'Decline CLAIM' : 'Decline FNOL'"
      :loading="declineDialogLoading"
    />
    <InviteAdminDialog @close="hideInviteAdminDialog" @inviteSuccess="refreshSidebarInfo" :organizationId="inviteAdminDialog.brokerOrganizationId" :value="inviteAdminDialog.shown" />
    <AddPolicyDialog @close="hideAddPolicyDialog" @addSuccess="refreshSidebarInfo" :organizationId="addPolicyDialog.brokerOrganizationId" :value="addPolicyDialog.shown" />
    <CodeDialog @close="hideCodeDialog" :value="codeDialog.shown" :content="codeDialog.content" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { loadingState, sidebarContent, withCountryStateOptions } from '@/mixins';
import { CLAIM_STATUSES, FNOL_STATUSES, SPORTS_UNION_TYPES, USER_ROLES } from '@/utils/constants';
import { isValidString } from '@/utils/helpers/string';
import InfoSidebar from '@/components/modals/InfoSidebar';
import InviteAdminDialog from '@/components/modals/InviteAdminDialog';
import AddPolicyDialog from '@/components/modals/AddPolicyDialog';
import ContactUsLink from '@/components/ContactUsLink';
import Logo from '@/components/Logo';
import UserBadge from '@/components/UserBadge';
import Preloader from '@/components/Preloader';
import SidebarMenu from '@/components/SidebarMenu';
import MenuLinks from '@/components/MenuLinks';
import getMenuItemsByRole from '@/utils/getMenuItemsByRole';
import { env } from '@/utils/config';

const InputDialog = () => import('@/components/modals/InputDialog');
const CodeDialog = () => import('@/components/modals/CodeDialog');

const LOADING_STATE_NAMESPACES = {
  CLAIM_STATUS_CHANGE: 'claimStatusChangeLoadingStateNamespace',
  DELETE_ORG: 'deleteOrgLoadingStateNamespace',
  FNOL_STATUS_CHANGE: 'fnolStatusChangeLoadingStateNamespace',
  CLAIM_IS_BEING_EXPORTED: 'claimIsBeingExported',
  CLAIM_CRAWFORD_XML_IS_BEING_GENERATED: 'claimCrawfordXmlIsBeingGenerated',
  CLAIM_GETTING_PDF_URL: 'claimGettingPdfurl',
};

export default {
  name: 'DashboardLayout',
  components: {
    UserBadge,
    Logo,
    InfoSidebar,
    InviteAdminDialog,
    SidebarMenu,
    MenuLinks,
    Preloader,
    InputDialog,
    CodeDialog,
    ContactUsLink,
    AddPolicyDialog,
  },
  mixins: [loadingState, sidebarContent, withCountryStateOptions],
  data() {
    return {
      OrgInfo: () => import('@/components/modals/InfoSidebar/OrgInfo'),
      menuItems: getMenuItemsByRole(),
      inviteAdminDialog: {
        shown: false,
        brokerOrganizationId: '',
      },
      addPolicyDialog: {
        shown: false,
        brokerOrganizationId: '',
      },
      rejectionDialogShown: false,
      codeDialog: {
        shown: false,
        content: '',
      },
      claimPdfDownloadUrl: {},
      development: env.DEVELOPMENT,
    };
  },
  computed: {
    ...mapGetters('user', ['profile', 'userRole', 'homeRoute', 'authed']),
    ...mapGetters('app', ['infoSidebarData', 'sidebarLoading', 'sidebarContent']),
    currentSidebarComponent() {
      return this.OrgInfo;
    },
    contentIsClaims() {
      return this.infoSidebarData.content === 'claim';
    },
    contentIsDeclinedFnol() {
      return (
        this.sidebarContent instanceof Object &&
        this.sidebarContent.fnolStatus === FNOL_STATUSES.DECLINED
      );
    },
    contentIsFnols() {
      return this.infoSidebarData.content === 'fnol';
    },
    contentIsOrgs() {
      return (
        this.infoSidebarData.content === 'brokerOrganization' ||
        this.infoSidebarData.content === 'organization'
      );
    },
    items() {
      return this.menuItems[this.userRole] ? this.menuItems[this.userRole].items : [];
    },
    claimStatus() {
      const { status } = this.sidebarContent;
      return status;
    },
    claimStatusChangeBtnsDisabled() {
      return (
        this.loadingState[LOADING_STATE_NAMESPACES.CLAIM_STATUS_CHANGE] ||
        (isValidString(this.claimStatus) && this.claimStatus !== CLAIM_STATUSES.PENDING)
      );
    },
    claimExportBtnDisabled() {
      return this.loadingState[LOADING_STATE_NAMESPACES.CLAIM_IS_BEING_EXPORTED];
    },
    claimViewXmlBtnDisabled() {
      return this.loadingState[LOADING_STATE_NAMESPACES.CLAIM_CRAWFORD_XML_IS_BEING_GENERATED];
    },
    claimGetPdfUrlBtnDisabled() {
      return this.loadingState[LOADING_STATE_NAMESPACES.CLAIM_GETTING_PDF_URL];
    },
    declineDialogLoading() {
      return this.claimStatusChangeBtnsDisabled || this.fnolStatusChangeBtnsDisabled;
    },
    fnolStatusChangeBtnsDisabled() {
      return this.loadingState[LOADING_STATE_NAMESPACES.FNOL_STATUS_CHANGE];
    },
    isCrawfordClaim() {
      return (
        (this.contentIsClaims || this.contentIsFnols) &&
        this.sidebarContent.sportsUnion &&
        this.sidebarContent.sportsUnion.form === SPORTS_UNION_TYPES.CRAWFORD
      );
    },
    showViewCrawfordXmlBtn() {
      return this.isCrawfordClaim && this.$userIs(USER_ROLES.SUPER_ADMIN);
    },
    claimNeedsExport() {
      return (
        this.isCrawfordClaim && this.checkClaimStatusIs('PENDING') && !this.sidebarContent.exported
      );
    },
  },
  watch: {
    $route() {
      if (this.infoSidebarData.shown) {
        this.toggleInfoSidebar();
      }
    },
  },
  methods: {
    ...mapActions('app', [
      'refreshSidebarInfo',
      'toggleInfoSidebar',
      'showUpdateOrgDialog',
      'showUpdatePolicyDialog',
    ]),
    ...mapActions('organizations', ['deleteSportsUnion']),
    ...mapActions('brokerOrganizations', ['deleteBrokerOrganization']),
    ...mapActions('claims', [
      'setClaimFullyPaid',
      'setClaimPartiallyPaid',
      'setClaimDeclined',
      'acceptFnol',
      'declineFnol',
      'exportClaim',
      'generateCrawfordXML',
      'getClaimPdfUrl',
    ]),
    checkClaimStatusIs(status) {
      return CLAIM_STATUSES[status] && this.claimStatus === CLAIM_STATUSES[status];
    },
    handleToggleInfoSidebar() {
      this.toggleInfoSidebar();
    },
    handleLogout() {
      this.$router.push({ name: 'logout' });
    },
    rejectEntity(reason) {
      if (this.infoSidebarData.content === 'claim') {
        this.setLoadingState(LOADING_STATE_NAMESPACES.CLAIM_STATUS_CHANGE, true);
        this.setClaimDeclined({
          id: this.infoSidebarData.id,
          reason,
        })
          .then(() => {
            this.rejectionDialogShown = false;
            this.toggleInfoSidebar();
          })
          .catch(error => {
            this.$dialog.error({ error });
            this.rejectionDialogShown = false;
          })
          .finally(() => {
            this.setLoadingState(LOADING_STATE_NAMESPACES.CLAIM_STATUS_CHANGE, false);
          });
      }
      if (this.infoSidebarData.content === 'fnol') {
        this.setLoadingState(LOADING_STATE_NAMESPACES.FNOL_STATUS_CHANGE, true);
        this.declineFnol({
          id: this.infoSidebarData.id,
          reason,
        })
          .then(() => {
            this.rejectionDialogShown = false;
            this.toggleInfoSidebar();
          })
          .catch(error => {
            this.$dialog.error({ error });
            this.rejectionDialogShown = false;
          })
          .finally(() => {
            this.setLoadingState(LOADING_STATE_NAMESPACES.FNOL_STATUS_CHANGE, false);
          });
      }
    },
    handleSA(content) {
      if (content === 'organization') {
        this.showUpdateOrgDialog({
          content: 'organization',
          type: 'stateAdmin',
        });
      }
    },
    handleBA(content) {
      if (content === 'brokerOrganization') {
        this.showUpdateOrgDialog({
          content: 'brokerOrganization',
          type: 'brokerAdmin',
        });
      }
    },
    handlePI(content) {
      if (content === 'brokerOrganization') {
        this.showUpdatePolicyDialog();
      }
    },
    handleEditEntity(content) {
      if (content === 'brokerOrganization') {
        this.showUpdateOrgDialog({
          content: 'brokerOrganization',
          type: 'edit',
        });
      } else if (content === 'fnol') {
        this.$router.push({
          name: 'fnolEdit',
          params: {
            id: this.infoSidebarData.id,
          },
        });
      } else if (content === 'claim') {
        this.$router.push({
          name: 'claimEdit',
          params: {
            id: this.infoSidebarData.id,
          },
        });
      } else if (content === 'organization') {
        this.showUpdateOrgDialog({
          content: 'organization',
          type: 'edit',
        });
      }
    },
    handleStatusChange(value) {
      if (value === 'fully') {
        this.setLoadingState(LOADING_STATE_NAMESPACES.CLAIM_STATUS_CHANGE, true);
        this.setClaimFullyPaid(this.infoSidebarData.id)
          .then(() => {
            this.toggleInfoSidebar();
          })
          .catch(error => {
            this.$dialog.error({ error });
          })
          .finally(() => {
            this.setLoadingState(LOADING_STATE_NAMESPACES.CLAIM_STATUS_CHANGE, false);
          });
      } else if (value === 'part') {
        this.setLoadingState(LOADING_STATE_NAMESPACES.CLAIM_STATUS_CHANGE, true);
        this.setClaimPartiallyPaid(this.infoSidebarData.id)
          .then(() => {
            this.toggleInfoSidebar();
          })
          .catch(error => {
            this.$dialog.error({ error });
          })
          .finally(() => {
            this.setLoadingState(LOADING_STATE_NAMESPACES.CLAIM_STATUS_CHANGE, false);
          });
      } else {
        this.handleClaimDecline();
      }
    },
    handleClaimExport() {
      this.setLoadingState(LOADING_STATE_NAMESPACES.CLAIM_IS_BEING_EXPORTED, true);
      this.exportClaim(this.infoSidebarData.id)
        .then(() => {
          this.toggleInfoSidebar();
        })
        .catch(error => {
          this.$dialog.error({ error });
        })
        .finally(() => {
          this.setLoadingState(LOADING_STATE_NAMESPACES.CLAIM_IS_BEING_EXPORTED, false);
        });
    },
    handleDownloadClaimPdf() {
      this.setLoadingState(LOADING_STATE_NAMESPACES.CLAIM_GETTING_PDF_URL, true);
      this.getClaimPdfUrl(this.infoSidebarData.id)
        .then(url => {
          this.claimPdfDownloadUrl = {
            ...this.claimPdfDownloadUrl,
            [this.infoSidebarData.id]: url,
          };
        })
        .catch(error => {
          this.$dialog.error({ error });
        })
        .finally(() => {
          this.setLoadingState(LOADING_STATE_NAMESPACES.CLAIM_GETTING_PDF_URL, false);
        });
    },
    handleClaimGenerateCrawfordXml() {
      this.setLoadingState(LOADING_STATE_NAMESPACES.CLAIM_CRAWFORD_XML_IS_BEING_GENERATED, true);
      this.generateCrawfordXML(this.infoSidebarData.id)
        .then(data => {
          this.showCodeDialog(data);
        })
        .catch(error => {
          this.$dialog.error({ error });
        })
        .finally(() => {
          this.setLoadingState(
            LOADING_STATE_NAMESPACES.CLAIM_CRAWFORD_XML_IS_BEING_GENERATED,
            false
          );
        });
    },
    handleFnolAccept() {
      this.setLoadingState(LOADING_STATE_NAMESPACES.FNOL_STATUS_CHANGE, true);
      this.acceptFnol(this.infoSidebarData.id)
        .then(({ status }) => {
          this.toggleInfoSidebar();
          this.$dialog.info({
            title: 'Success',
            description: "You've succesfully accepted an FNOL",
            status: status === CLAIM_STATUSES.CLAIM_SENT_TO_TPA ? 'PDF sent to the TPA' : '',
          });
        })
        .catch(error => {
          this.$dialog.error({ error });
        })
        .finally(() => {
          this.setLoadingState(LOADING_STATE_NAMESPACES.FNOL_STATUS_CHANGE, false);
        });
    },
    handleFnolDecline() {
      this.rejectionDialogShown = true;
    },
    handleClaimDecline() {
      this.rejectionDialogShown = true;
    },
    handleDeleteOrg() {
      const orgType = this.infoSidebarData.content;
      let title = '';
      if (orgType === 'brokerOrganization') {
        title = `You are about to delete ${this.sidebarContent.name ||
          ''} organization. Are you sure?`;
      } else {
        title = `You're about to delete ${this.sidebarContent.name || ''} organization, ${this
          .sidebarContent.type || ''}.
        Are you sure?
        Please notice: if you delete the sports union all the related FNOLs and claims will be archived`;
      }
      this.$dialog
        .confirm({
          title,
        })
        .then(res => {
          if (res) {
            if (orgType === 'brokerOrganization') {
              this.deleteBrokerOrganization();
            } else {
              this.deleteSportsUnion();
            }
          }
          this.toggleInfoSidebar();
        })
        .catch(error => {
          this.$dialog.error({ error });
        })
        .finally(() => {
          this.setLoadingState(LOADING_STATE_NAMESPACES.DELETE_ORG, false);
        });
    },
    redirectToHomeRoute() {
      if (this.$route.name !== this.homeRoute) {
        this.$router.push({ name: this.homeRoute });
      }
    },
    showInviteAdminDialog() {
      this.inviteAdminDialog.shown = true;
      this.inviteAdminDialog.brokerOrganizationId = this.sidebarContent.id;
    },
    hideInviteAdminDialog() {
      this.inviteAdminDialog.shown = false;
      this.inviteAdminDialog.brokerOrganizationId = '';
    },
    showAddPolicyDialog() {
      this.addPolicyDialog.shown = true;
      this.addPolicyDialog.brokerOrganizationId = this.sidebarContent.id;
    },
    hideAddPolicyDialog() {
      this.addPolicyDialog.shown = false;
      this.addPolicyDialog.brokerOrganizationId = '';
    },
    showCodeDialog(content) {
      this.codeDialog.content = content;
      this.codeDialog.shown = true;
    },
    hideCodeDialog() {
      this.codeDialog.shown = false;
      this.codeDialog.content = '';
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/base/index';

.app__menu-links {
  margin-right: 50px;
}
.app__header {
  &:after {
    content: '';
    height: 1px;
    width: 100%;
    background: $border-light;
    position: absolute;
  }
}
.app__sidebar-footer-heading {
  color: $text-semi-dark;
  text-transform: uppercase;
}
.app__sidebar-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &--start {
    justify-content: flex-start;
  }
}
.line-break {
  width: 100%;
}
</style>
