import { NAGHA_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const CLAIMANT_FIELD_ALIASES = NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT;

export default () => ({
  // aliases for usage in form templates
  CLAIMANT_FIELD_ALIASES: {
    type: Object,
    default: () => CLAIMANT_FIELD_ALIASES,
  },
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB]: [String, Object],
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER]: String,
  // CLAIMANT part two
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ADDITIONAL]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_HOME_PHONE]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_WORK_PHONE]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_STATUS]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_SEEN_PROVIDER]: Boolean,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_MEDICAID_BENEFICIARY]: Boolean,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_SS_NOR_HID_NUMBER]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_INJURED_PERSON_TYPE]: String,
});
