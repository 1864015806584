export const NAGHA_CLAIM_FORM_FIELD_ALIASES = {
  CLAIMANT: {
    CLAIMANT_FIRST_NAME: 'claimantFirstName',
    CLAIMANT_LAST_NAME: 'claimantLastName',
    CLAIMANT_DOB: 'claimantDateOfBirth',
    CLAIMANT_GENDER: 'claimantGender',
    // part two
    CLAIMANT_ADDRESS_STREET: 'claimantAddressStreet',
    CLAIMANT_ADDRESS_ADDITIONAL: 'claimantAddressAdditional',
    CLAIMANT_ADDRESS_CITY: 'claimantAddressCity',
    CLAIMANT_ADDRESS_STATE: 'claimantAddressState',
    CLAIMANT_ADDRESS_ZIP: 'claimantAddressZip',
    CLAIMANT_ADDRESS_COUNTRY: 'claimantAddressCountry',
    CLAIMANT_EMAIL: 'claimantEmail',
    CLAIMANT_HOME_PHONE: 'claimantHomePhone',
    CLAIMANT_WORK_PHONE: 'claimantWorkPhone',
    CLAIMANT_STATUS: 'claimantStatus',
    CLAIMANT_SEEN_PROVIDER: 'haveYouAlreadySeenAProvider',
    CLAIMANT_MEDICAID_BENEFICIARY: 'isInjuredPersonAMedicareMedicaidBeneficiary',
    CLAIMANT_SS_NOR_HID_NUMBER: 'SSNorHIDNumber',
    CLAIMANT_INJURED_PERSON_TYPE: 'injuredPersonType',
  },
  INCIDENT: {
    INCIDENT_DATE: 'incidentDate',
    INCIDENT_TIME: 'incidentTime',
    INCIDENT_LOCATION: 'incidentLocation',
    INCIDENT_BODY_PART_INJURED: 'incidentBodyPartInjured',
    INCIDENT_BODY_SIDE_INJURED: 'incidentBodySideInjured',
    INCIDENT_INJURY_TYPE: 'incidentInjuryType',
    INCIDENT_INJURY_OCCURED_AT: 'incidentInjuryOccuredAt',
    INCIDENT_INJURY_OCCURED_AT_OTHER: 'injuryOccurredAtOther',
    INCIDENT_EVENT_NAME_AND_TYPE: 'incidentEventNameAndType',
    INCIDENT_INJURY_DESCRIPTION: 'incidentInjuryDescription',
    INCEDENT_NAME_OF_TEAM: 'nameOfTeam',
    INCEDENT_CLAIMANT_CHECKBOX1: 'nagha_ClaimantCheckbox1',
    INCEDENT_CLAIMANT_CHECKBOX2: 'nagha_ClaimantCheckbox2',
    INCEDENT_CLAIMANT_SIGNATURE: 'claimantSignature',
    INCIDENT_INJURY_ACCIDENT_OCCUR: 'injuryAccidentOccur',
    INCIDENT_RESULT_IN_DEATH: 'olympic_didInjuryResultInDeath',
    INCEDENT_NAME_OF_LEAGUE: 'nameOfLeague',
    INCIDENT_NOTE: 'note',
  },
  COACH: {
    COACH_EMAIL: 'coachEmail',
    COACH_FIRST_NAME: 'coachFirstName',
    COACH_LAST_NAME: 'coachLastName',
  },
  HEALTH: {
    HEALTH_INSURANCE_COMPANY_NAME: 'healthInsuranceCompanyName',
    HEALTH_INSURANCE_GROUP_NAME: 'healthInsuranceGroupName',
    HEALTH_INSURANCE_POLICY_HOLDERS_NAME: 'healthInsurancePolicyHoldersName',
    HEALTH_INSURANCE_MEMBER_ID: 'healthInsuranceMemberId',
    HEALTH_INSURANCE_POLICY_NUMBER: 'healthInsurancePolicyNumber',
    HEALTH_CLAIMANT_HAS_HEALTHCARE: 'haveMedicalOrHCOrEnrolledIndividualOrEmployeeOrDepMem',
  },
  PROVIDER: {
    NAME: 'providerName',
    ADDRESS: 'providerAddress',
    EMAIL: 'providerEmail',
    PHONE_NUMBER: 'providerPhoneNumber',
  },
  DISCLAIMERS: {
    COACH_READ_AND_CONFIRMED_FNOL: 'coachReadAndConfirmedFnol',
    READ_AND_AGREE_TO_CLAIMS_INFO: 'readAndAgreeToClaimsInfo',
  },
};

export const NAGHA_CLAIM_FORM_FIELD_API_ALIASES = {
  // CLAIMANT
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_FIRST_NAME]: 'claimantFirstName',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_LAST_NAME]: 'claimantLastName',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_DOB]: 'claimantDOB',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_GENDER]: 'claimantGender',

  // CLAIMANT part two
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_STREET]:
    'claimantAddress.streetAddress',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_ADDITIONAL]:
    'claimantAddress.additionalAddress',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_CITY]: 'claimantAddress.city',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_STATE]: 'claimantAddress.state',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_ZIP]: 'claimantAddress.zip',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_COUNTRY]: 'claimantAddress.country',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_EMAIL]: 'claimantEmail',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_HOME_PHONE]: 'claimantHomePhone',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_WORK_PHONE]: 'claimantWorkPhone',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_STATUS]: 'claimantStatus',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_SEEN_PROVIDER]: 'haveYouAlreadySeenAProvider',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_MEDICAID_BENEFICIARY]:
    'isInjuredPersonAMedicareMedicaidBeneficiary',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_SS_NOR_HID_NUMBER]: 'SSNorHIDNumber',
  // INCEDENT
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_DATE]: 'incidentDate',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_TIME]: 'incidentTime',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_LOCATION]: 'incidentLocation',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_BODY_PART_INJURED]: 'injuredBodyPart',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_BODY_SIDE_INJURED]: 'injuredBodySide',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_TYPE]: 'injuryType',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_OCCURED_AT]: 'injuryOccurredAt',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_OCCURED_AT_OTHER]:
    'injuryOccurredAtOther',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_ACCIDENT_OCCUR]: 'injuryAccidentOccur',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCEDENT_NAME_OF_LEAGUE]: 'nameOfLeague',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_NOTE]: 'note',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_EVENT_NAME_AND_TYPE]: 'nameAndTypeOfEvent',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_DESCRIPTION]: 'injuryDescription',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCEDENT_NAME_OF_TEAM]: 'nameOfTeam',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCEDENT_CLAIMANT_CHECKBOX1]: 'nagha_claimantCheckbox1',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCEDENT_CLAIMANT_CHECKBOX2]: 'nagha_claimantCheckbox2',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCEDENT_CLAIMANT_SIGNATURE]: 'claimantSignature',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_RESULT_IN_DEATH]:
    'olympic_didInjuryResultInDeath',
  // HEALTH PLAN
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_INSURANCE_COMPANY_NAME]: 'companyName',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_INSURANCE_GROUP_NAME]: 'groupName',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_INSURANCE_POLICY_HOLDERS_NAME]: 'policyHoldersName',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_INSURANCE_MEMBER_ID]: 'memberId',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_INSURANCE_POLICY_NUMBER]: 'policyNumber',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_CLAIMANT_HAS_HEALTHCARE]:
    'haveMedicalOrHCOrEnrolledIndividualOrEmployeeOrDepMem',

  // PROVIDER INFORMATION
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.PROVIDER.NAME]: 'providerName',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.PROVIDER.ADDRESS]: 'providerAddress',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.PROVIDER.EMAIL]: 'providerEmail',
  [NAGHA_CLAIM_FORM_FIELD_ALIASES.PROVIDER.PHONE_NUMBER]: 'providerPhoneNumber',
};

export const INJURED_PERSON_TYPES = {
  PARTICIPANT: 'PARTICIPANT',
  STAFF_MEMBER: 'STAFF_MEMBER',
  OTHER: 'OTHER',
};

export const INJURED_PERSON_TYPES_NAMES = {
  [INJURED_PERSON_TYPES.PARTICIPANT]: 'Participant',
  [INJURED_PERSON_TYPES.STAFF_MEMBER]: 'Staff member',
  [INJURED_PERSON_TYPES.OTHER]: 'Other',
};
