<template>
  <PhDialog v-model="shown" @close="handleDialogClose" :heading="title" fullscreen hideFooter>
    <template v-slot:content>
      <v-layout column fill-height justify-center class="invite-admin-dialog__body">
        <div class="invite-admin-dialog__form-wrapper">
          <InviteAdminForm v-bind.sync="form" :disabled="loading" ref="form"></InviteAdminForm>
        </div>
        <div class="invite-admin-dialog__form-footer-wrapper mt-12">
          <v-layout justify-end>
            <v-btn @click="submitInviteAdminForm" :loading="loading">Add</v-btn>
          </v-layout>
        </div>
      </v-layout>
    </template>
  </PhDialog>
</template>

<script>
import { mapActions } from 'vuex';
import { dialogWrapper } from '@/mixins';
import InviteAdminForm from '@/components/forms/InviteAdminForm';

export default {
  name: 'InviteAdminDialog',
  mixins: [dialogWrapper],
  components: {
    InviteAdminForm,
  },
  props: {
    title: {
      type: String,
      default: 'Invite an admin',
    },
    organizationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
      },
      loading: false,
    };
  },
  watch: {
    value(newVal) {
      if (newVal === false) {
        this.resetInviteAdminForm();
      }
    },
  },
  methods: {
    ...mapActions('brokerOrganizations', ['inviteAdminToBrokerOrganization']),
    resetInviteAdminForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      } else {
        throw new Error("The form wasn't mounted");
      }
    },
    async submitInviteAdminForm() {
      try {
        if (this.$refs.form) {
          const submit = this.$refs.form.submit();
          if (submit) {
            this.loading = true;
            const success = await this.inviteAdminToBrokerOrganization({
              name: this.form.name,
              email: this.form.email,
              brokerId: this.organizationId,
            });
            if (success) {
              this.$emit('inviteSuccess');
              this.$dialog.info({
                title: 'Success',
                description: 'You have successfully invited an admin to this organization',
              });
            } else {
              throw new Error(
                "Couldn't invite an admin to this organization. Please make sure the email is correct and that this person doesn't already have access to this organization"
              );
            }
            this.loading = false;
          }
        } else {
          throw new Error("The form wasn't mounted");
        }
      } catch (error) {
        this.loading = false;
        this.$dialog.error({ error });
      }
    },
  },
};
</script>

<style lang="scss">
.invite-admin-dialog__body {
  width: 100%;
  max-width: 358px;
  margin-left: auto;
  margin-right: auto;
}
</style>
