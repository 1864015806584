export const properOrgInfo = org => {
  let structuredOrg = {};
  if (org instanceof Object && Object.values(org).length) {
    const {
      name,
      address: { country, city, state, zip, streetAddress },
      url,
      broker,
    } = org;
    structuredOrg = {
      'Broker Org Name': broker && broker.name,
      name,
      street: streetAddress,
      country,
      state,
      city,
      zip,
      url,
    };
    return structuredOrg;
  }
  return structuredOrg;
};
