import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import brokerOrganizations from './modules/brokerOrganizations';
import claims from './modules/claims';
import files from './modules/files';
import organizations from './modules/organizations';
import globalDialog from './modules/globalDialog';
import user from './modules/user';
import analytics from './modules/analytics';
import captcha from './modules/captcha';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    brokerOrganizations,
    claims,
    files,
    globalDialog,
    organizations,
    user,
    analytics,
    captcha,
  },
});
