import Vue from 'vue';
import i18n from './i18n.js';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/globalMethods';
import './plugins/globalComponents';
import './plugins/filters';
import './scss/_vuetify.scss';
import './scss/_application.scss';
import AccessControl from '@/components/AccessControl';

Vue.component('access-control', AccessControl);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
}).$mount('#app');
