export default {
  watch: {
    $route: {
      handler(val) {
        this.queryToggleToggler(val);
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.query.toggle_target && this.$route.query.toggle_type) {
      this.queryToggleToggler(this.$route);
    }
  },
  methods: {
    queryToggleHandler() {},
    queryToggleToggler({ query = {} }) {
      if (!query || !Object.keys(query).length) return;
      this.queryToggleHandler({
        target: query.toggle_target,
        type: query.toggle_type,
      });
    },
  },
};
