export default {
  methods: {
    handleFieldInput(field, value) {
      this.$emit(`update:${field}`, value);
      this.$emit('fieldInput', {
        field,
        value,
      });
    },
    handleNonSyncFieldInput(field, value) {
      this.$emit('fieldInput', {
        field,
        value,
      });
    },
  },
};
