/* eslint-disable */
/* eslint no-prototype-builtins: 0 */

import { USASA_CLAIM_FORM_FIELD_API_ALIASES } from '@/utils/constants'
import { flattenObject } from '@/utils/helpers/object'

const reverseClaimFormFieldApiAliases = payload => {
  if (!(payload instanceof Object)) {
    throw new Error('The provided "payload" argument is not a valid Object')
  }
  return Object.entries(payload).reduce((acc, [field, value]) => {
    acc[value] = field
    return acc
  }, {})
}

const CLAIM_API_PAYLOAD_FIELD_ALIASES = reverseClaimFormFieldApiAliases(
  USASA_CLAIM_FORM_FIELD_API_ALIASES
)

const convertClaimApiPayloadToAliases = payload => {
  if (!(payload instanceof Object)) {
    throw new Error('The provided "payload" argument is not a valid Object')
  }
  return Object.entries(payload).reduce((acc, [field, value]) => {
    if (CLAIM_API_PAYLOAD_FIELD_ALIASES[field]) {
      acc[CLAIM_API_PAYLOAD_FIELD_ALIASES[field]] = value
    } else if (value instanceof Object && field !== 'healthInsurancePlan') {
      const {__typename, ...pld} = value
      value = pld
      if(field == 'providerInformation') {
        acc['providerInformation'] = value
      } else {
        const flattenedValueObject = flattenObject({
          [field]: value,
        })
        Object.entries(flattenedValueObject).forEach(([flatField, flatValue]) => {
          if (CLAIM_API_PAYLOAD_FIELD_ALIASES[flatField]) {
            acc[CLAIM_API_PAYLOAD_FIELD_ALIASES[flatField]] = flatValue
          }
        })
      }
    } else if (value instanceof Array) {
      const healthInsuranceObject = value.reduce((ac, arrVal, i) => {
        const healthInsuranceValue =
          arrVal instanceof Object &&
          Object.entries(arrVal).reduce((accum, [key, val]) => {
            // transform independend value object here
            if (CLAIM_API_PAYLOAD_FIELD_ALIASES[key]) {
              accum[CLAIM_API_PAYLOAD_FIELD_ALIASES[key]] = val
            }
            return accum
          }, {})
        ac[`plan_${i}`] = healthInsuranceValue
        acc['healthInsuranceForms'] = { ...ac }
        return ac
      }, {})
    } else {
      acc[field] = value
        if(field == 'providerInformation') {
          acc[field] = {}
        }
    }
    return acc
  }, {})
}

export default (payload, fields = []) => {
  if (!(payload instanceof Object)) {
    throw new Error('The provided "payload" argument is not a valid Object')
  }
  const aliasedForm = convertClaimApiPayloadToAliases(payload)
  if (fields instanceof Array && fields.length) {
    return fields.reduce((acc, field) => {
      if (aliasedForm.hasOwnProperty(field)) {
        acc[field] = aliasedForm[field]
      }
      return acc
    }, {})
  }
  return aliasedForm
}
