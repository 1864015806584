import {
  CONDITIONS_OF_INJURED_TEETH,
  CONDITIONS_OF_INJURED_TEETH_NAMES,
  BODY_PARTS,
  BODY_PART_NAMES,
  BODY_SIDES,
  BODY_SIDE_NAMES,
  CLAIM_STATUSES,
  CLAIM_STATUS_NAMES,
  EVENT_TYPES,
  EVENT_TYPE_NAMES,
  FNOL_STATUSES,
  FNOL_STATUS_NAMES,
  GENDERS,
  GENDER_NAMES,
  INJURY_CONTACT_TYPES,
  INJURY_CONTACT_TYPE_NAMES,
  INJURY_SEVERITY_TYPES,
  INJURY_SEVERITY_TYPE_NAMES,
  INJURY_TYPES,
  INJURY_TYPE_NAMES,
  MEMBERSHIP_TYPES,
  MEMBERSHIP_TYPE_NAMES,
  OLYMPIC_ORGANIZATION_ROLES,
  OLYMPIC_ORGANIZATION_ROLE_NAMES,
  OLYMPIC_TEAM_NAMES,
  OLYMPIC_TEAM_NAMES_NAMES,
  ORGANIZATION_ROLES,
  ORGANIZATION_ROLE_NAMES,
  PLAYING_SURFACES,
  PLAYING_SURFACES_NAMES,
  CALLER_TYPES,
  CALLER_TYPE_NAMES,
  LOSS_TYPES,
  LOSS_TYPE_NAMES,
  PROPERTY_OWNER_TYPES,
  PROPERTY_OWNER_TYPE_NAMES,
  BSI_INJURY_CODES,
  BSI_BODY_PARTS,
  GL_CAUSE_CODES,
  GL_DESCRIPTION_CODES,
} from '@/utils/constants';
import { getOptionsFromConstants, sortOptionsAlphabetically } from '@/utils/helpers/array';

const olympicOrganizationRoles = [
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.ATHLETIC_TRAINER],
    value: OLYMPIC_ORGANIZATION_ROLES.ATHLETIC_TRAINER,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.COACH_MANAGER],
    value: OLYMPIC_ORGANIZATION_ROLES.COACH_MANAGER,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.DOCTOR],
    value: OLYMPIC_ORGANIZATION_ROLES.DOCTOR,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.ATHLETE],
    value: OLYMPIC_ORGANIZATION_ROLES.ATHLETE,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.OTHER],
    value: OLYMPIC_ORGANIZATION_ROLES.OTHER,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.ROOKIE],
    value: OLYMPIC_ORGANIZATION_ROLES.ROOKIE,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.YOUTH],
    value: OLYMPIC_ORGANIZATION_ROLES.YOUTH,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.HS],
    value: OLYMPIC_ORGANIZATION_ROLES.HS,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.COLLEGE],
    value: OLYMPIC_ORGANIZATION_ROLES.COLLEGE,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.SENIOR_CLUB],
    value: OLYMPIC_ORGANIZATION_ROLES.SENIOR_CLUB,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.TOUCH],
    value: OLYMPIC_ORGANIZATION_ROLES.TOUCH,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.REFEREE],
    value: OLYMPIC_ORGANIZATION_ROLES.REFEREE,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.COACH],
    value: OLYMPIC_ORGANIZATION_ROLES.COACH,
  },
  {
    text: OLYMPIC_ORGANIZATION_ROLE_NAMES[OLYMPIC_ORGANIZATION_ROLES.ADMIN],
    value: OLYMPIC_ORGANIZATION_ROLES.ADMIN,
  },
];

const olympicTeamNames = sortOptionsAlphabetically([
  {
    text: OLYMPIC_TEAM_NAMES_NAMES[OLYMPIC_TEAM_NAMES.M_NATIONAL_15S],
    value: OLYMPIC_TEAM_NAMES.M_NATIONAL_15S,
  },
  {
    text: OLYMPIC_TEAM_NAMES_NAMES[OLYMPIC_TEAM_NAMES.W_NATIONAL_15S],
    value: OLYMPIC_TEAM_NAMES.W_NATIONAL_15S,
  },
  {
    text: OLYMPIC_TEAM_NAMES_NAMES[OLYMPIC_TEAM_NAMES.M_NATIONAL_7S],
    value: OLYMPIC_TEAM_NAMES.M_NATIONAL_7S,
  },
  {
    text: OLYMPIC_TEAM_NAMES_NAMES[OLYMPIC_TEAM_NAMES.W_NATIONAL_7S],
    value: OLYMPIC_TEAM_NAMES.W_NATIONAL_7S,
  },
]);

const conditionsOfInjuredTeeth = sortOptionsAlphabetically([
  {
    text: CONDITIONS_OF_INJURED_TEETH_NAMES[CONDITIONS_OF_INJURED_TEETH.NATURAL],
    value: CONDITIONS_OF_INJURED_TEETH.NATURAL,
  },
  {
    text: CONDITIONS_OF_INJURED_TEETH_NAMES[CONDITIONS_OF_INJURED_TEETH.FILLED],
    value: CONDITIONS_OF_INJURED_TEETH.FILLED,
  },
  {
    text: CONDITIONS_OF_INJURED_TEETH_NAMES[CONDITIONS_OF_INJURED_TEETH.CAPPED],
    value: CONDITIONS_OF_INJURED_TEETH.CAPPED,
  },
  {
    text: CONDITIONS_OF_INJURED_TEETH_NAMES[CONDITIONS_OF_INJURED_TEETH.ARTIFICIAL],
    value: CONDITIONS_OF_INJURED_TEETH.ARTIFICIAL,
  },
]);
const bodyParts = sortOptionsAlphabetically([
  {
    text: BODY_PART_NAMES[BODY_PARTS.HEAD],
    value: BODY_PARTS.HEAD,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.FACE],
    value: BODY_PARTS.FACE,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.NECK_OT_SPINE],
    value: BODY_PARTS.NECK_OT_SPINE,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.HIP_OR_PELVIS],
    value: BODY_PARTS.HIP_OR_PELVIS,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.KNEE],
    value: BODY_PARTS.KNEE,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.ANKLE],
    value: BODY_PARTS.ANKLE,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.SHOULDER_OR_PROXIMAL_HUMERUS],
    value: BODY_PARTS.SHOULDER_OR_PROXIMAL_HUMERUS,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.ELBOW],
    value: BODY_PARTS.ELBOW,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.WRIST],
    value: BODY_PARTS.WRIST,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.QUADRICEPS],
    value: BODY_PARTS.QUADRICEPS,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.HAMSTRING_OR_THIGH],
    value: BODY_PARTS.HAMSTRING_OR_THIGH,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.LOWER_LEG_OR_CALF],
    value: BODY_PARTS.LOWER_LEG_OR_CALF,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.CHEST],
    value: BODY_PARTS.CHEST,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.BACK],
    value: BODY_PARTS.BACK,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.TRICEPS],
    value: BODY_PARTS.TRICEPS,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.BICEPS],
    value: BODY_PARTS.BICEPS,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.FOREARMS],
    value: BODY_PARTS.FOREARMS,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.TRAPEZIUS],
    value: BODY_PARTS.TRAPEZIUS,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.ABDOMINALS],
    value: BODY_PARTS.ABDOMINALS,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.OTHER],
    value: BODY_PARTS.OTHER,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.FOOT],
    value: BODY_PARTS.FOOT,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.HAND],
    value: BODY_PARTS.HAND,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.SHIN],
    value: BODY_PARTS.SHIN,
  },
  {
    text: BODY_PART_NAMES[BODY_PARTS.CERVICAL],
    value: BODY_PARTS.CERVICAL,
  },
]);

const bodySides = [
  {
    text: BODY_SIDE_NAMES[BODY_SIDES.LEFT],
    value: BODY_SIDES.LEFT,
  },
  {
    text: BODY_SIDE_NAMES[BODY_SIDES.RIGHT],
    value: BODY_SIDES.RIGHT,
  },
  {
    text: BODY_SIDE_NAMES[BODY_SIDES.NOT_ON_BODY],
    value: BODY_SIDES.NOT_ON_BODY,
  },
  {
    text: BODY_SIDE_NAMES[BODY_SIDES.MIDLINE],
    value: BODY_SIDES.MIDLINE,
  },
  {
    text: BODY_SIDE_NAMES[BODY_SIDES.BILATERAL],
    value: BODY_SIDES.BILATERAL,
  },
];

const claimStatuses = [
  {
    text: CLAIM_STATUS_NAMES[CLAIM_STATUSES.DECLINED],
    value: CLAIM_STATUSES.DECLINED,
  },
  {
    text: CLAIM_STATUS_NAMES[CLAIM_STATUSES.FULLY_PAID],
    value: CLAIM_STATUSES.FULLY_PAID,
  },
  {
    text: CLAIM_STATUS_NAMES[CLAIM_STATUSES.PARTIALLY_PAID],
    value: CLAIM_STATUSES.PARTIALLY_PAID,
  },
  {
    text: CLAIM_STATUS_NAMES[CLAIM_STATUSES.PENDING],
    value: CLAIM_STATUSES.PENDING,
  },
  {
    text: CLAIM_STATUS_NAMES[CLAIM_STATUSES.CLAIM_SENT_TO_TPA],
    value: CLAIM_STATUSES.CLAIM_SENT_TO_TPA,
  },
];

const eventTypes = [
  {
    text: EVENT_TYPE_NAMES[EVENT_TYPES.MATCH_OR_TOURNAMENT],
    value: EVENT_TYPES.MATCH_OR_TOURNAMENT,
  },
  {
    text: EVENT_TYPE_NAMES[EVENT_TYPES.ON_FIELD_PRACTICE],
    value: EVENT_TYPES.ON_FIELD_PRACTICE,
  },
  {
    text: EVENT_TYPE_NAMES[EVENT_TYPES.OFF_FIELD_PRACTICE],
    value: EVENT_TYPES.OFF_FIELD_PRACTICE,
  },
];

const fnolStatuses = [
  {
    text: FNOL_STATUS_NAMES[FNOL_STATUSES.CONFIRMED],
    value: FNOL_STATUSES.CONFIRMED,
  },
  {
    text: FNOL_STATUS_NAMES[FNOL_STATUSES.DECLINED],
    value: FNOL_STATUSES.DECLINED,
  },
  {
    text: FNOL_STATUS_NAMES[FNOL_STATUSES.PENDING],
    value: FNOL_STATUSES.PENDING,
  },
];

const incidentOccuredAtLocations = [
  {
    text: 'Game',
    value: 'GAME',
  },
  {
    text: 'Practice',
    value: 'PRACTICE',
  },
  {
    text: 'Travel',
    value: 'TRAVEL',
  },
  {
    text: 'Race',
    value: 'RACE',
  },
  {
    text: 'Trail work',
    value: 'TRAIL_WORK',
  },
  {
    text: 'Other',
    value: 'OTHER',
  },
];

const incidentOccurCases = [
  {
    text: 'During a policyholder programmed, sponsored & supervised, or sanctioned activity?',
    value: 'POLICYHOLDER_ACTIVITY',
  },
  {
    text: 'On activity premises?',
    value: 'ACTIVITY_PREMISES',
  },
  {
    text: 'While traveling directly and uninterruptedly to or from the athletic event?',
    value: 'TRAVELLING_DIRECTLY_AND_UNINTERRUPTEDLY_TO_OR_FROM_THE_ATHLETIC_EVENT',
  },
  {
    text: 'During intercollegiate/scholastic athletic practice?',
    value: 'INTERCOLLEGIATE_SCHOLASTIC_ATHLETIC_PRACTICE',
  },
  {
    text: 'During intercollegiate/scholastic athletic competition?',
    value: 'INTERCOLLEGIATE_SCHOLASTIC_ATHLETIC_COMPETITION',
  },
];

const genders = [
  {
    text: GENDER_NAMES[GENDERS.MALE],
    value: GENDERS.MALE,
  },
  {
    text: GENDER_NAMES[GENDERS.FEMALE],
    value: GENDERS.FEMALE,
  },
];

const injuryContacts = [
  {
    text: 'Contact',
    value: true,
  },
  {
    text: 'Non-contact',
    value: false,
  },
];

const injuryContactTypes = [
  {
    text: INJURY_CONTACT_TYPE_NAMES[INJURY_CONTACT_TYPES.TACKLE_DEFENDER],
    value: INJURY_CONTACT_TYPES.TACKLE_DEFENDER,
  },
  {
    text: INJURY_CONTACT_TYPE_NAMES[INJURY_CONTACT_TYPES.TACKLE_ATTACKER],
    value: INJURY_CONTACT_TYPES.TACKLE_ATTACKER,
  },
  {
    text: INJURY_CONTACT_TYPE_NAMES[INJURY_CONTACT_TYPES.RUCK],
    value: INJURY_CONTACT_TYPES.RUCK,
  },
  {
    text: INJURY_CONTACT_TYPE_NAMES[INJURY_CONTACT_TYPES.SCRUM],
    value: INJURY_CONTACT_TYPES.SCRUM,
  },
  {
    text: INJURY_CONTACT_TYPE_NAMES[INJURY_CONTACT_TYPES.MAUL],
    value: INJURY_CONTACT_TYPES.MAUL,
  },
  {
    text: INJURY_CONTACT_TYPE_NAMES[INJURY_CONTACT_TYPES.OTHER],
    value: INJURY_CONTACT_TYPES.OTHER,
  },
];

const injurySeverityTypes = [
  {
    text: INJURY_SEVERITY_TYPE_NAMES[INJURY_SEVERITY_TYPES.REPORT_ONLY],
    value: INJURY_SEVERITY_TYPES.REPORT_ONLY,
  },
  {
    text: INJURY_SEVERITY_TYPE_NAMES[INJURY_SEVERITY_TYPES.MINOR],
    value: INJURY_SEVERITY_TYPES.MINOR,
  },
  {
    text: INJURY_SEVERITY_TYPE_NAMES[INJURY_SEVERITY_TYPES.SERIOUS],
    value: INJURY_SEVERITY_TYPES.SERIOUS,
  },
  {
    text: INJURY_SEVERITY_TYPE_NAMES[INJURY_SEVERITY_TYPES.CRITICAL],
    value: INJURY_SEVERITY_TYPES.CRITICAL,
  },
  {
    text: INJURY_SEVERITY_TYPE_NAMES[INJURY_SEVERITY_TYPES.FATALITY],
    value: INJURY_SEVERITY_TYPES.FATALITY,
  },
];

const injuryTypes = sortOptionsAlphabetically([
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.SPRAIN],
    value: INJURY_TYPES.SPRAIN,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.STRAIN],
    value: INJURY_TYPES.STRAIN,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.FRACTURE],
    value: INJURY_TYPES.FRACTURE,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.CONTUSION],
    value: INJURY_TYPES.CONTUSION,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.LACERATION],
    value: INJURY_TYPES.LACERATION,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.CONCUSSION],
    value: INJURY_TYPES.CONCUSSION,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.OVERUSE],
    value: INJURY_TYPES.OVERUSE,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.OTHER],
    value: INJURY_TYPES.OTHER,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.DISLOCATION],
    value: INJURY_TYPES.DISLOCATION,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.SUBLUXATION],
    value: INJURY_TYPES.SUBLUXATION,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.CONDITIONS],
    value: INJURY_TYPES.CONDITIONS,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.ACL_TEAR],
    value: INJURY_TYPES.ACL_TEAR,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.ASTHMA],
    value: INJURY_TYPES.ASTHMA,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.ALLERGIC_REACTION_OR_INSECT_BITE],
    value: INJURY_TYPES.ALLERGIC_REACTION_OR_INSECT_BITE,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.BONE_INJURY],
    value: INJURY_TYPES.BONE_INJURY,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.BURSITIS],
    value: INJURY_TYPES.BURSITIS,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.CARTILAGE_INJURY],
    value: INJURY_TYPES.CARTILAGE_INJURY,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.COMPARTMENT_INJURY],
    value: INJURY_TYPES.COMPARTMENT_INJURY,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.CYST],
    value: INJURY_TYPES.CYST,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.FRACTURE2],
    value: INJURY_TYPES.FRACTURE2,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.HEADACHES],
    value: INJURY_TYPES.HEADACHES,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.HEAT_ILLNESS],
    value: INJURY_TYPES.HEAT_ILLNESS,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.ILLNESS],
    value: INJURY_TYPES.ILLNESS,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.INJURY_CLEARANCE],
    value: INJURY_TYPES.INJURY_CLEARANCE,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.INFECTION],
    value: INJURY_TYPES.INFECTION,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.INSTABILITY],
    value: INJURY_TYPES.INSTABILITY,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.LABRAL_TEAR],
    value: INJURY_TYPES.LABRAL_TEAR,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.MAINTENANCE],
    value: INJURY_TYPES.MAINTENANCE,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.NEUROLOGICAL_CONDITION],
    value: INJURY_TYPES.NEUROLOGICAL_CONDITION,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.OTHER_MEDICAL],
    value: INJURY_TYPES.OTHER_MEDICAL,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.PATELLOFEMORAL_SYNDROME],
    value: INJURY_TYPES.PATELLOFEMORAL_SYNDROME,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.PLANTAR_FASCIITIS],
    value: INJURY_TYPES.PLANTAR_FASCIITIS,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.SEIZURE],
    value: INJURY_TYPES.SEIZURE,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.SPRAIN2],
    value: INJURY_TYPES.SPRAIN2,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.TENDON_INJURY],
    value: INJURY_TYPES.TENDON_INJURY,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.WOUND_DERMAL],
    value: INJURY_TYPES.WOUND_DERMAL,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.DEFORMITY],
    value: INJURY_TYPES.DEFORMITY,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.INSTABILITY2],
    value: INJURY_TYPES.INSTABILITY2,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.TENDON_INJURY2],
    value: INJURY_TYPES.TENDON_INJURY2,
  },
  {
    text: INJURY_TYPE_NAMES[INJURY_TYPES.DISORDER],
    value: INJURY_TYPES.DISORDER,
  },
]);

const membershipTypes = [
  {
    text: MEMBERSHIP_TYPE_NAMES[MEMBERSHIP_TYPES.YOUTH],
    value: MEMBERSHIP_TYPES.YOUTH,
  },
  {
    text: MEMBERSHIP_TYPE_NAMES[MEMBERSHIP_TYPES.HS],
    value: MEMBERSHIP_TYPES.HS,
  },
  {
    text: MEMBERSHIP_TYPE_NAMES[MEMBERSHIP_TYPES.COLLEGES],
    value: MEMBERSHIP_TYPES.COLLEGES,
  },
  {
    text: MEMBERSHIP_TYPE_NAMES[MEMBERSHIP_TYPES.CLUB],
    value: MEMBERSHIP_TYPES.CLUB,
  },
  {
    text: MEMBERSHIP_TYPE_NAMES[MEMBERSHIP_TYPES.OTHER],
    value: MEMBERSHIP_TYPES.OTHER,
  },
];

const organizationRoles = [
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.ATHLETE],
    value: ORGANIZATION_ROLES.ATHLETE,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.COACH],
    value: ORGANIZATION_ROLES.COACH,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.OFFICIAL],
    value: ORGANIZATION_ROLES.OFFICIAL,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.OTHER],
    value: ORGANIZATION_ROLES.OTHER,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.ATHLETIC_TRAINER],
    value: ORGANIZATION_ROLES.ATHLETIC_TRAINER,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.COACH_MANAGER],
    value: ORGANIZATION_ROLES.COACH_MANAGER,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.DOCTOR],
    value: ORGANIZATION_ROLES.DOCTOR,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.ROOKIE],
    value: ORGANIZATION_ROLES.ROOKIE,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.YOUTH],
    value: ORGANIZATION_ROLES.YOUTH,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.HS],
    value: ORGANIZATION_ROLES.HS,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.COLLEGE],
    value: ORGANIZATION_ROLES.COLLEGE,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.SENIOR_CLUB],
    value: ORGANIZATION_ROLES.SENIOR_CLUB,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.TOUCH],
    value: ORGANIZATION_ROLES.TOUCH,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.REFEREE],
    value: ORGANIZATION_ROLES.REFEREE,
  },
  {
    text: ORGANIZATION_ROLE_NAMES[ORGANIZATION_ROLES.ADMIN],
    value: ORGANIZATION_ROLES.ADMIN,
  },
];

const playingSurfaces = sortOptionsAlphabetically(
  getOptionsFromConstants(PLAYING_SURFACES, PLAYING_SURFACES_NAMES)
);

const olympicPlayingSurfaces = (() => {
  const { ASTROTURF, DIRT, FIELD_TURF, GRASS, MULTI_PURPOSE } = PLAYING_SURFACES;
  return sortOptionsAlphabetically(
    getOptionsFromConstants(
      { ASTROTURF, DIRT, FIELD_TURF, GRASS, MULTI_PURPOSE },
      PLAYING_SURFACES_NAMES
    )
  );
})();

const lossTypes = Object.values(LOSS_TYPES).map(v => ({ text: LOSS_TYPE_NAMES[v], value: v }));

const propertyOwnerTypes = getOptionsFromConstants(PROPERTY_OWNER_TYPES, PROPERTY_OWNER_TYPE_NAMES);

const callerTypes = getOptionsFromConstants(CALLER_TYPES, CALLER_TYPE_NAMES);

const bsiInjuryCodes = sortOptionsAlphabetically(
  Object.entries(BSI_INJURY_CODES).map(([k, v]) => ({
    text: v,
    value: k,
  }))
);

const bsiBodyParts = sortOptionsAlphabetically(
  Object.entries(BSI_BODY_PARTS).map(([k, v]) => ({
    text: v,
    value: k,
  }))
);

const glCauseCodes = Object.entries(GL_CAUSE_CODES).map(([k, v]) => ({
  text: v,
  value: k,
}));

const glDescriptionCodes = Object.entries(GL_DESCRIPTION_CODES).reduce(
  (acc, [cause, descriptions]) => {
    return Object.assign(acc, {
      [cause]: Object.entries(descriptions).map(([code, name]) => ({
        text: name,
        value: code,
      })),
    });
  },
  {}
);

export {
  incidentOccuredAtLocations,
  conditionsOfInjuredTeeth,
  bodyParts,
  bodySides,
  claimStatuses,
  eventTypes,
  fnolStatuses,
  genders,
  injuryContacts,
  injuryContactTypes,
  injurySeverityTypes,
  injuryTypes,
  membershipTypes,
  olympicOrganizationRoles,
  olympicTeamNames,
  organizationRoles,
  playingSurfaces,
  olympicPlayingSurfaces,
  incidentOccurCases,
  lossTypes,
  propertyOwnerTypes,
  bsiInjuryCodes,
  bsiBodyParts,
  callerTypes,
  glCauseCodes,
  glDescriptionCodes,
};
