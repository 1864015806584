import { USASA_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const CLAIMANT_FIELD_ALIASES = USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT;
const INCIDENT_FIELD_ALIASES = USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT;
const COACH_FIELD_ALIASES = USASA_CLAIM_FORM_FIELD_ALIASES.COACH;

export default () => ({
  // aliases for usage in form templates
  CLAIMANT_FIELD_ALIASES: {
    type: Object,
    default: () => CLAIMANT_FIELD_ALIASES,
  },
  INCIDENT_FIELD_ALIASES: {
    type: Object,
    default: () => INCIDENT_FIELD_ALIASES,
  },
  COACH_FIELD_ALIASES: {
    type: Object,
    default: () => COACH_FIELD_ALIASES,
  },
  [INCIDENT_FIELD_ALIASES.INCIDENT_DATE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_TIME]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]: String,
  // [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT]: String,
  // [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT_OTHER]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_ON]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT_OTHER]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME_AND_TYPE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_DESCRIPTION]: String,
  [INCIDENT_FIELD_ALIASES.INCEDENT_NAME_OF_TEAM]: String,
  [INCIDENT_FIELD_ALIASES.INCEDENT_NAME_OF_LEAGUE]: String,
  [INCIDENT_FIELD_ALIASES.INCEDENT_STATE_ASSICOATION_AFFILATE]: String,
  [INCIDENT_FIELD_ALIASES.INCEDENT_REGION]: String,
  [COACH_FIELD_ALIASES.COACH_FIRST_NAME]: String,
  [COACH_FIELD_ALIASES.COACH_LAST_NAME]: String,
  // [COACH_FIELD_ALIASES.COACH_FULL_NAME]: String,
  [COACH_FIELD_ALIASES.COACH_EMAIL]: String,
  [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_CHECKBOX]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_SIGNATURE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_NOTE]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_COACH_PLAYER_TYPE]: String,
});
