import { mapActions, mapGetters } from 'vuex';
import isValidString from '@/utils/helpers/string/isValidString';

export default {
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      passwordForm: {
        password: '',
        confirmPassword: '',
      },
    };
  },
  computed: {
    ...mapGetters('user', ['homeRoute']),
  },
  methods: {
    ...mapActions('user', ['updatePasswordByToken']),
    async submitPasswordForm() {
      try {
        const success = await this.updatePassword(this.passwordForm);
        if (success) {
          this.$dialog.info({
            title: 'Success',
            description:
              "You've successfully set a new password for your account. Close this window to proceed to your dashboard",
          });
          this.$router.push({ name: this.homeRoute });
        }
        return success;
      } catch (error) {
        console.error(error);
        this.$dialog.error({ error });
        return error;
      }
    },
    async updatePassword({ password, confirmPassword }) {
      try {
        if (!isValidString(this.token)) {
          throw new Error("The link you've followed to get here seems to be broken.");
        }
        if (!isValidString(password)) {
          throw new Error('No password to set');
        }
        if (password !== confirmPassword) {
          throw new Error("The passwords don't match");
        }
        const success = await this.updatePasswordByToken({
          token: this.token,
          password,
          confirmPassword,
        });
        return success;
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
