import { sortOptionsAlphabetically } from '@/utils/helpers/array';

export default {
  loadingOrganizations: state => state.loadingState.loading,
  organizations: state => state.organizations,
  organizationsOptions: state =>
    sortOptionsAlphabetically(
      state.organizations.map(({ id, name }) => ({ text: name, value: id })),
      'text'
    ),
  organizationsLoaded: state => state.loadingState.loaded,
};
