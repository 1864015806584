import { OLYMPIC_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const COACH_FIELD_ALIASES = OLYMPIC_CLAIM_FORM_FIELD_ALIASES.COACH;

export default () => ({
  // aliases for usage in form templates
  COACH_FIELD_ALIASES: {
    type: Object,
    default: () => COACH_FIELD_ALIASES,
  },
  [COACH_FIELD_ALIASES.COACH_INJURED_TREATED_BY_OFFICIALS]: Boolean,
});
