import isValidString from '@/utils/helpers/string/isValidString';

export default {
  methods: {
    sanitizePhoneNumberInput(value) {
      return value ? value.replace(/\D/g, '') : value;
    },
    handlePhoneNumberInput(field, value) {
      this.handleFieldInput(field, this.sanitizePhoneNumberInput(value));
    },
    formatPhoneNumber(value) {
      if (!isValidString(value)) {
        return value;
      }

      const digits = value.replace(/\D/g, '');
      const [code, first, second] = [digits.slice(0, 3), digits.slice(3, 6), digits.slice(6)];
      let result = code;

      if (code.length === 3) {
        result = `(${result})`;
      }

      if (first.length) {
        result += ` ${first}`;
      }

      if (second.length) {
        result += `-${second}`;
      }

      return result;
    },
    preventNondigits(evt) {
      const event = evt || window.event;
      const keys = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '0',
        'Backspace',
        'Delete',
        'Tab',
        'ArrowLeft',
        'ArrowRight',
      ];

      if (!keys.includes(event.key)) {
        event.preventDefault();
        return false;
      }

      return true;
    },
  },
};
