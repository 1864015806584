import { VTextField } from 'vuetify/lib';
import getDefaultValidationProps from '@/utils/helpers/vuetify/getDefaultValidationProps';

export default (component => ({
  functional: true,
  name: 'VTextField',
  render(h, context) {
    return h(
      component,
      {
        ...context.data,
        props: {
          ...getDefaultValidationProps(context),
          color: context.props.color || 'success',
          outlined: context.props.outlined !== false,
        },
      },
      context.children
    );
  },
}))(VTextField);
