const CREATE_CLAIM_INPUT = {
  sportsUnionId: 'ID!',
  claimantLastName: 'String!',
  claimantFirstName: 'String!',
  claimantGender: 'GenderType!',
  claimantDOB: 'String!',
  claimantEmail: 'String!',
  memberId: 'String',
  claimantFathersFullName: 'String',
  claimantMothersFullName: 'String',
  insuranceCompany: 'String!',
  policyHoldersName: 'String!',
  policyNumber: 'String!',
  groupNumber: 'String',
  incidentDate: 'String!',
  incidentTime: 'String!',
  incidentLocation: 'String',
  injuredBodyPart: 'InjuredBodyPartType',
  injuredBodySide: 'InjuredBodySideType',
  injuryType: 'InjuryType',
  severity: 'SeverityType',
  isReceivedOnsite: 'Boolean',
  injuryDescription: 'String',
  eventType: 'EventType',
  isContact: 'Boolean!',
  contactType: 'ContactType',
  contactOtherType: 'String',
  olympic_dentalInvolvedTeeth: 'String',
  olympic_dentalConditionTeeth: 'DentalConditionOfInjuredTeeth',
  olympic_didInjuryResultInDeath: 'Boolean',
  olympic_claimantParentsFullName: 'String',
  olympic_claimantParentsAddress: 'String',
  olympic_claimantParentsPhone: 'String',
  olympic_nameOfTeam: 'String',
  olympic_street: 'String',
  olympic_city: 'String',
  olympic_state: 'String',
  olympic_zip: 'String',
  olympic_socialSecurityNumber: 'String!',
  olympic_superVisorFirstName: 'String',
  olympic_superVisorLastName: 'String',
  olympic_superVisorTitle: 'String',
  olympic_checkbox1: 'Boolean',
  olympic_checkbox2: 'Boolean',
  olympic_checkbox3: 'Boolean',
  olympic_claimantRoleType: 'OlympicClaimantRoleType',
  olympic_haveMedicalCare: 'Boolean',
  plainSurface: 'PlainSurface',
  claimantSignature: 'String!',
  coachEmail: 'String!',
  coachFirstName: 'String',
  coachLastName: 'String',
  coachPhone: 'String',
  clubNameOfInjured: 'String',
  territoryWhereIncidentOccurred: 'String',
  isAthleteWasTreatedByCoach: 'Boolean',
  note: 'String',
};

export default form => {
  if (!(form instanceof Object)) {
    throw new Error('The provided form argument is not a valid Object');
  }
  return Object.entries(form).reduce((acc, [field, value]) => {
    if (CREATE_CLAIM_INPUT[field]) {
      acc[field] = value;
    }
    return acc;
  }, {});
};
