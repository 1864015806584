<template>
  <v-form @submit.prevent="submit" ref="form" class="invite-admin-form">
    <div class="invite-admin-form__content">
      <div class="invite-admin-form__fields">
        <v-text-field
          @input="handleFieldInput('name', arguments[0])"
          :value="name"
          :rules="['required', 'fullName']"
          :disabled="disabled"
          label="Name"
        />
        <v-text-field
          @input="handleFieldInput('email', arguments[0])"
          :value="email"
          :rules="['required', 'email']"
          :disabled="disabled"
          label="Email"
          type="email"
        />
      </div>
    </div>
  </v-form>
</template>

<script>
import syncForm from '@/mixins/syncForm';
import validateForm from '@/mixins/validateForm';

export default {
  name: 'InviteAdminForm',
  mixins: [syncForm, validateForm],
  props: {
    disabled: Boolean,
    name: String,
    email: String,
  },
};
</script>

<style lang="scss" scoped>
.invite-admin-form__footer {
  margin-top: 1.125rem;
}
</style>
