import { isValidString } from '@/utils/helpers/string';

const getDefaultLoadingState = () => ({
  default: false,
});

export default {
  data() {
    return {
      loadingState: getDefaultLoadingState(),
    };
  },
  methods: {
    resetLoadingState() {
      this.loadingState = getDefaultLoadingState();
    },
    setLoadingState(namespace, value) {
      if (isValidString(namespace)) {
        if (this.loadingState.hasOwnProperty(namespace)) {
          this.loadingState[namespace] = !!value;
        } else {
          this.loadingState = Object.assign({}, this.loadingState, {
            [namespace]: !!value,
          });
        }
      } else {
        this.loadingState.default = !!value;
      }
    },
  },
};
