/* eslint func-names: 0 */
import { flattenObject } from '@/utils/helpers/object';
import { isValidString } from '@/utils/helpers/string';

const getDefaultExternalErrorsState = () => ({
  namespaces: {},
  errors: {},
  unWatchers: {},
});

const getRawErrorsForNamespaceData = nData => {
  const errorObject = {};
  Object.keys(nData).forEach(key => {
    errorObject[key] = '';
  });
  return errorObject;
};

export default {
  data() {
    return {
      externalErrors: getDefaultExternalErrorsState(),
    };
  },
  computed: {
    externalErrorsMap() {
      return flattenObject(this.externalErrors.errors);
    },
  },
  methods: {
    clearExternalErrorsForNamespace(namespace) {
      if (!isValidString(namespace)) {
        throw new Error('The "namespace" argument is not a valid string');
      }
      if (!this.externalErrors.errors.hasOwnProperty(namespace)) {
        throw new Error(`There's no such namespaces as "${namespace}" initialized`);
      }
      this.externalErrors.errors[namespace] = getRawErrorsForNamespaceData(
        this.externalErrors.errors[namespace]
      );
    },
    hasAnyExternalErrorsForNamespace(namespace) {
      if (this.externalErrors.errors[namespace] instanceof Object) {
        return Object.values(this.externalErrors.errors[namespace]).some(val => isValidString(val));
      }
      return false;
    },
    hasExternalError(namespace, field) {
      try {
        return !!this.externalErrors.errors[namespace][field];
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    initExternalErrors(namespace, namespaceData) {
      if (!isValidString(namespace)) {
        throw new Error('The "namespace" argument is not a valid string');
      }
      if (!(namespaceData instanceof Object)) {
        throw new Error('The "namespaceData" argument is not a valid object');
      }
      if (this.externalErrors.namespaces[namespace]) {
        this.unInitExternalErrorsForNamespace(namespace);
      }
      const initNamespaceData = (nSpace, nData) => {
        this.externalErrors.namespaces = Object.assign({}, this.externalErrors.namespaces, {
          [nSpace]: nData,
        });
      };
      const initNamespaceErrors = (nSpace, nData) => {
        const errorObject = getRawErrorsForNamespaceData(nData);
        this.externalErrors.errors = Object.assign({}, this.externalErrors.errors, {
          [nSpace]: errorObject,
        });
      };
      const initNamespaceDataChangesWatcher = nSpace => {
        const unWatcher = this.$watch(
          `externalErrors.namespaces.${nSpace}`,
          function(updatedData) {
            if (this.hasAnyExternalErrorsForNamespace(nSpace)) {
              const updatedErrorState = getRawErrorsForNamespaceData(updatedData);
              this.externalErrors.errors[nSpace] = updatedErrorState;
            }
          },
          {
            deep: true,
          }
        );
        this.externalErrors.unWatchers = Object.assign({}, this.externalErrors.unWatchers, {
          [nSpace]: unWatcher,
        });
      };
      // invoke init functions
      initNamespaceData(namespace, namespaceData);
      initNamespaceErrors(namespace, namespaceData);
      initNamespaceDataChangesWatcher(namespace);
    },
    getExternalError(namespace, field) {
      try {
        return this.externalErrors.errors[namespace][field] || '';
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    setExternalError(namespace, errorObject) {
      if (!isValidString(namespace)) {
        throw new Error('The "namespace" argument is not a valid string.');
      }
      if (!(this.externalErrors.errors[namespace] instanceof Object)) {
        throw new Error(`There's no such error namespace as "${namespace}".`);
      }
      if (!(errorObject instanceof Object)) {
        throw new Error('The "errors" argument is not a valid object.');
      }
      Object.entries(errorObject).forEach(([key, value]) => {
        if (
          isValidString(key) &&
          isValidString(value) &&
          this.externalErrors.errors[namespace].hasOwnProperty(key)
        ) {
          this.externalErrors.errors[namespace][key] = value;
        }
      });
    },
    unInitExternalErrors() {
      this.externalErrors = getDefaultExternalErrorsState();
    },
    unInitExternalErrorsForNamespace(namespace) {
      if (!isValidString(namespace)) {
        throw new Error(`There's no such namespace as "${namespace}" initialized`);
      }
      this.externalErrors.namespaces = Object.assign({}, this.externalErrors.namespaces, {
        [namespace]: undefined,
      });
      this.externalErrors.errors = Object.assign({}, this.externalErrors.errors, {
        [namespace]: undefined,
      });
      if (typeof this.externalErrors.unWatchers[namespace] === 'function') {
        this.externalErrors.unWatchers[namespace]();
      }
    },
  },
};
