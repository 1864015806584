<template>
  <PhDialog v-model="shown" @close="handleDialogClose" :heading="title" fullscreen hideFooter>
    <template v-slot:content>
      <v-layout column justify-center class="add-policy-dialog__body">
        <div class="add-policy-dialog__form-wrapper">
          <AddPolicyForm v-bind.sync="form" :disabled="loading" ref="form"></AddPolicyForm>
        </div>
        <div class="add-policy-dialog__form-footer-wrapper mt-12">
          <v-layout justify-end>
            <v-btn @click="submitAddPolicyForm" :loading="loading">Add</v-btn>
          </v-layout>
        </div>
      </v-layout>
    </template>
  </PhDialog>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { dialogWrapper } from '@/mixins';
import AddPolicyForm from '@/components/forms/AddPolicyForm';

export default {
  name: 'AddPolicyDialog',
  mixins: [dialogWrapper],
  components: {
    AddPolicyForm,
  },
  props: {
    title: {
      type: String,
      default: 'Add Policy Information',
    },
    organizationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        policyNumber: '',
        effectiveDate: '',
        expiredDate: '',
        carrier: '',
        deductible: '',
        coinsurance: '',
        benefitPeriod: '',
        perInjuryLimit: '',
        physicalTherapySublimit: '',
        tpaName: '',
        email: '',
      },
      loading: false,
    };
  },
  watch: {
    value(newVal) {
      if (newVal === false) {
        this.resetAddPolicyForm();
      }
    },
  },
  methods: {
    ...mapActions('brokerOrganizations', ['addPolicyToBrokerOrganization']),
    resetAddPolicyForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      } else {
        throw new Error("The form wasn't mounted");
      }
    },
    async submitAddPolicyForm() {
      try {
        if (this.$refs.form) {
          if (
            moment(this.form.expiredDate, 'YYYY-MM-DD').isBefore(
              this.form.effectiveDate,
              'YYYY-MM-DD'
            )
          ) {
            throw new Error('The expiration date must be not earlier than the effective date');
          }
          const submit = this.$refs.form.submit();
          if (submit) {
            this.loading = true;
            const success = await this.addPolicyToBrokerOrganization({
              policyNumber: this.form.policyNumber,
              effectiveDate: moment(this.form.effectiveDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
              expiredDate: moment(this.form.expiredDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
              carrier: this.form.carrier,
              deductible: this.form.deductible,
              coinsurance: this.form.coinsurance,
              benefitPeriod: this.form.benefitPeriod,
              perInjuryLimit: this.form.perInjuryLimit,
              physicalTherapySublimit: this.form.physicalTherapySublimit,
              tpaName: this.form.tpaName,
              email: this.form.email,
              brokerId: this.organizationId,
            });
            if (success) {
              this.$emit('addSuccess');
              this.$dialog.info({
                title: 'Success',
                description:
                  'You have successfully added a policy information to this organization',
              });
            } else {
              throw new Error("Couldn't add a policy information to this organization.");
            }
          }
        } else {
          throw new Error("The form wasn't mounted");
        }
      } catch (error) {
        this.$dialog.error({ error });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/base/index';
.add-policy-dialog__body {
  width: 100%;
  max-width: 358px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8px;
  @include bp-up(sm) {
    max-width: 764px;
  }
}
</style>
