import BaseProvider from './BaseProvider';
import * as queries from '@/graphql/queries';

class AppProvider extends BaseProvider {
  fetchCountries() {
    return this.query(queries.countries, { fetchPolicy: 'no-cache' });
  }
}

export default AppProvider;
