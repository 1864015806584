<template>
  <v-btn @click="handleClick" :ripple="false" class="close-btn" text>
    <span class="close-btn__label">
      {{ $capitalizeStart(label) }}
    </span>
    <v-icon size="18" end>
      close
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'CloseBtn',
  props: {
    label: {
      type: String,
      default: 'Close',
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss">
.close-btn {
  &.v-btn {
    .v-btn__content {
      text-transform: none;
    }
  }
}
.close-btn__label {
  display: inline-block;
  font-size: 14px;
  margin-right: 4px;
}
</style>
