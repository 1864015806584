/* eslint camelcase: 0 */

export default claim => {
  if (claim instanceof Object) {
    if (claim.olympic) {
      const { olympic_street, olympic_city, olympic_state, olympic_zip } = claim;
      return {
        country: '',
        city: olympic_city || '',
        state: olympic_state || '',
        zip: olympic_zip || '',
        streetAddress: olympic_street || '',
      };
    }
    const { claimantAddress } = claim;
    if (claimantAddress instanceof Object) {
      const { city, country, state, streetAddress, zip } = claimantAddress;
      return {
        country: country || '',
        city: city || '',
        state: state || '',
        zip: zip || '',
        streetAddress: streetAddress || '',
      };
    }
  }
  return null;
};
