import { VSelect } from 'vuetify/lib';
import getDefaultValidationProps from '@/utils/helpers/vuetify/getDefaultValidationProps';

export default (component => ({
  functional: true,
  name: 'VSelect',
  render(h, context) {
    return h(
      component,
      {
        ...context.data,
        props: {
          ...getDefaultValidationProps(context),
          appendIcon: context.props.appendIcon || 'keyboard_arrow_down',
          color: context.props.color || 'success',
          flat: context.props.flat !== false,
          outlined: context.props.outlined !== false,
          solo: context.props.solo !== false,
        },
      },
      context.children
    );
  },
}))(VSelect);
