/* eslint no-underscore-dangle: 0, no-trailing-spaces: 0 */

import moment from 'moment';
import { API_DATE_FORMAT } from '@/utils/constants';

class Time {
  // classes properties

  static engine = moment;

  static formats = {
    api: API_DATE_FORMAT,
  };

  // classes "private" methods

  static _createDate(date) {
    if (date) {
      if (this._validateDate(date)) {
        return this.engine(date);
      }
      this._throwError('Invalid datetime');
    }
    return this.engine();
  }

  static _throwError(message) {
    const className = 'Time';
    const errorMessage = typeof message === 'string' && message.length ? message : 'Error';
    throw new Error(`${className}: ${errorMessage}`);
  }

  static _validateDate(date) {
    if (
      (typeof date === 'string' && date.length) ||
      (typeof date === 'number' && !Number.isNaN(date))
    ) {
      const attemptedEngineDate = this.engine(date);
      return attemptedEngineDate.isValid();
    }
    return Boolean(date instanceof Date);
  }

  static _validateDateAndFormat(date, format) {
    if (date && typeof format === 'string' && format.length) {
      const attemptedDate = this.engine(date);
      if (!attemptedDate.isValid()) {
        return false;
      }
      const formattedDate = attemptedDate.format(format);
      return this._validateDate(formattedDate);
    }
    return false;
  }

  static _validateFormat(format) {
    if (typeof format === 'string' && format.length) {
      const attemptedFormattedDate = this.engine().format(format);
      return this._validateDate(attemptedFormattedDate);
    }
    return false;
  }

  // classes public methods

  static getRawDate(date) {
    if (date && this._validateDate(date)) {
      return this._createDate(date);
    }
    return this._createDate();
  }

  static getAPIFormattedDate(date = null) {
    return this.getFormattedDate(date, this.formats.api);
  }

  static getFormattedDate(date = null, format) {
    if (date && format) {
      if (this._validateDateAndFormat(date, format)) {
        return this._createDate(date).format(format);
      }
      this._throwError('Invalid datetime or format');
    } else if (format) {
      if (this._validateFormat(format)) {
        return this._createDate().format(format);
      }
      this._throwError('Invalid format');
    } else {
      this._throwError('Invalid format');
    }
    return date;
  }
}

export default Time;
