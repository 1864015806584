<template>
  <transition name="fade" appear>
    <v-container
      v-if="value"
      fluid
      class="preloader"
      :class="{ 'preloader--fullscreen': fullscreen, 'preloader--transparent': transparent }"
    >
      <v-progress-circular indeterminate color="accent" />
    </v-container>
  </transition>
</template>

<script>
export default {
  name: 'Preloader',
  props: {
    value: Boolean,
    fullscreen: {
      type: Boolean,
      default: false,
    },
    transparent: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  &:not(.preloader--transparent) {
    background-color: $background-lightest;
  }
  &--fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 999;
  }
}
</style>
