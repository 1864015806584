<template>
  <div class="user-badge">
    <v-menu
      v-model="menu"
      :max-width="150"
      :close-on-content-click="true"
      :nudge-width="200"
      offset-y
    >
      <template v-slot:activator="{ on }">
        <div class="user-badge__container" v-on="on">
          <div class="user-badge__part">
            <div class="user-badge__name">
              {{ user.name }}
            </div>
            <div class="user-badge__role">
              {{ prettyRole[user.role] || '' }}
            </div>
          </div>
          <div class="user-badge__avatar-wrapp">
            <img
              v-if="userPhotoSrc"
              :src="userPhotoSrc"
              :alt="`Avatar of ${user.name}`"
              class="user-badge__avatar"
            />
          </div>
        </div>
      </template>
      <v-card>
        <v-divider></v-divider>
        <v-list>
          <router-link :to="{ name: 'profile' }">
            <v-list-item>
              <v-list-item-title>
                My Profile
              </v-list-item-title>
            </v-list-item>
          </router-link>
          <v-list-item @click="handleLogout">
            <v-list-item-title>
              <strong>
                Log out
              </strong>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { getRemoteFilePath, isValidString } from '@/utils/helpers/string';

const ROLES_TRANSLATE = {
  SUPER_ADMIN: 'Super admin',
  BROKER_ADMIN: 'Admin',
};

export default {
  name: 'UserBadge',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      prettyRole: ROLES_TRANSLATE,
    };
  },
  computed: {
    userPhotoSrc() {
      if (this.user instanceof Object && this.user.photo instanceof Object) {
        try {
          const {
            photo: { path },
          } = this.user;
          return isValidString(path) ? getRemoteFilePath(path) : '';
        } catch (err) {
          console.error(err);
        }
      }
      return '';
    },
  },
  methods: {
    handleLogout() {
      this.$emit('logout');
    },
  },
};
</script>

<style lang="scss">
.user-badge {
  cursor: pointer;
}

.user-badge__container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-badge__part {
  flex: 0 1 auto;
  margin-right: 8px;
  text-align: right;
}

.user-badge__name {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
}

.user-badge__role {
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
}

.user-badge__avatar-wrapp {
  width: 43px;
  height: 43px;
  display: block;
  border-radius: 50%;
  background: #bfbfbf;
  overflow: hidden;
}
.user-badge__avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
