export default {
  SHOW_DIALOG(state, payload) {
    state.shown = typeof payload !== 'undefined' ? payload : true;
  },
  SET_PARAMS(state, config) {
    state.params = Object.assign({}, state.params, config);
  },
  SET_RESOLVE_FUNC(state, func) {
    state.resolveDialogFunc = func;
  },
  RESOLVE_DIALOG(state, payload) {
    state.resolveDialogFunc(payload);
  },
};
