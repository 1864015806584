import moment from 'moment';
import isValidString from '@/utils/helpers/string/isValidString';
import { AppProvider, OrganizationsProvider, ClaimsProvider } from '@/graphql/providers';
import { ANALYTIC_EVENTS } from '@/utils/constants';

export default {
  setCustomTheme({ commit, state }, { theme, dark = false }) {
    if (!(theme instanceof Object)) {
      throw new Error("The required 'theme' argument is not a valid object");
    }
    const themeToSet = {
      ...(dark ? state.theme.default.dark : state.theme.default.light),
      ...theme,
    };
    commit('SET_CUSTOM_THEME', { theme: themeToSet, dark });
  },
  resetCustomTheme({ commit }, payload) {
    commit('SET_CUSTOM_THEME', {
      theme: null,
      dark: payload instanceof Object && payload.hasOwnProperty('dark') ? payload.dark : false,
    });
  },
  setLogoUrl({ commit }, url) {
    if (typeof url === 'string') {
      commit('SET_LOGO_URL', url);
    } else {
      throw new Error('The provided url argument was invalid');
    }
  },
  updateAppLocale(state, locale) {
    state.commit('UPDATE_APP_LOCALE', locale);
  },
  toggleInfoSidebar(state) {
    state.commit('TOGGLE_INFO_SIDEBAR');
  },
  disableScroll({ commit }) {
    commit('SET_SCROLL_DISABLED', true);
  },
  enableScroll({ commit }) {
    commit('SET_SCROLL_DISABLED', false);
  },
  showUpdateOrgDialog(state, data) {
    state.commit('SHOW_UPDATE_ORG_DIALOG', data);
  },
  hideUpdateOrgDialog(state) {
    state.commit('HIDE_UPDATE_ORG_DIALOG');
  },
  showUpdatePolicyDialog(state) {
    state.commit('SHOW_UPDATE_POLICY_DIALOG');
  },
  hideUpdatePolicyDialog(state) {
    state.commit('HIDE_UPDATE_POLICY_DIALOG');
  },
  async openSidebarInfo({ commit, dispatch }, object) {
    try {
      commit('OPEN_SIDEBAR_INFO', object);
      commit('SET_SIDEBAR_LOADING');
      if (object.content === 'brokerOrganization') {
        await dispatch('populateBrokerOrganizationSidebarInfo', object);
      }
      if (object.content === 'organization') {
        await dispatch('populateOrganizationSidebarInfo', object);
      }
      if (object.content === 'claim' || object.content === 'fnol') {
        await dispatch('populateClaimSidebarInfo', object);
      }
      commit('UNSET_SIDEBAR_LOADING');
      return Promise.resolve();
    } catch (error) {
      dispatch('globalDialog/showErrorDialog', { error }, { root: true });
      commit('UNSET_SIDEBAR_LOADING');
      dispatch('toggleInfoSidebar');
      return Promise.reject(error);
    }
  },
  async populateBrokerOrganizationSidebarInfo({ commit }, { id, refreshCache }) {
    let content = {};
    const brokerOrgData = await OrganizationsProvider.fetchBrokerOrganizationById(id, refreshCache);
    if (
      brokerOrgData.data &&
      brokerOrgData.data.brokerOrganization &&
      brokerOrgData.data.brokerOrganization instanceof Object
    ) {
      content = { ...brokerOrgData.data.brokerOrganization };
      commit('UPDATE_SIDEBAR_CONTENT', brokerOrgData.data.brokerOrganization);
    }

    const policyData = await OrganizationsProvider.fetchPolicyByBrokerId(id, refreshCache);
    if (policyData.data && policyData.data.policys && policyData.data.policys instanceof Object) {
      content = {
        ...content,
        policys: policyData.data.policys.map(
          ({
            id: policyId,
            policyNumber,
            effectiveDate,
            expiredDate,
            carrier,
            deductible,
            coinsurance,
            benefitPeriod,
            perInjuryLimit,
            physicalTherapySublimit,
            tpaName,
            email,
            brokerId,
          }) => ({
            id: policyId,
            policyNumber,
            effectiveDate: moment(effectiveDate).format('MM/DD/YYYY'),
            expiredDate: moment(expiredDate).format('MM/DD/YYYY'),
            carrier,
            deductible,
            coinsurance,
            benefitPeriod,
            perInjuryLimit,
            physicalTherapySublimit,
            tpaName,
            email,
            brokerId,
          })
        ),
      };
    }

    if (Object.keys(content).length) {
      commit('UPDATE_SIDEBAR_CONTENT', content);
    }

    return Promise.resolve();
  },
  async populateClaimSidebarInfo({ commit, dispatch }, { id, content }) {
    const data = await ClaimsProvider.fetchClaimById(id);
    if (data.data && data.data.claim && data.data.claim instanceof Object) {
      commit('UPDATE_SIDEBAR_CONTENT', data.data.claim);
      dispatch(
        'analytics/logEvent',
        {
          event:
            content === 'fnol' ? ANALYTIC_EVENTS.USER_VIEW_FNOL : ANALYTIC_EVENTS.USER_VIEW_CLAIM,
          data: data.data.claim.id,
        },
        {
          root: true,
        }
      );
    }
    return Promise.resolve();
  },
  async populateOrganizationSidebarInfo({ commit }, object) {
    const data = await OrganizationsProvider.fetchSportsUnionById(object.id);
    if (data.data && data.data.sportsUnion && data.data.sportsUnion instanceof Object) {
      commit('UPDATE_SIDEBAR_CONTENT', data.data.sportsUnion);
    }
    return Promise.resolve();
  },
  async refreshSidebarInfo({ dispatch, state }) {
    try {
      const { id, content } = state.infoSidebarData;
      if (isValidString(id) && isValidString(content)) {
        switch (content) {
          case 'brokerOrganization':
            return dispatch('populateBrokerOrganizationSidebarInfo', {
              id,
              content,
            });
          case 'claim':
          case 'fnol':
            return dispatch('populateClaimSidebarInfo', { id, content });
          case 'organization':
            return dispatch('populateOrganizationSidebarInfo', { id, content });
          default:
            return Promise.resolve();
        }
      }
    } catch (error) {
      console.error(error);
    }
    return Promise.resolve();
  },
  async fetchCountries({ commit, state }) {
    if (!state.countries.length) {
      const {
        data: { countries },
      } = await AppProvider.fetchCountries();
      const { countriesToCommit, statesToCommit } = countries.reduce(
        (acc, country) => {
          const { states, ...rest } = country;
          acc.countriesToCommit.push(rest);
          acc.statesToCommit[rest.code] = states;
          return acc;
        },
        { countriesToCommit: [], statesToCommit: {} }
      );
      commit('SET_STATES', statesToCommit);
      commit('SET_COUNTRIES', countriesToCommit);
    }
  },
};
