/* eslint no-param-reassign: 0 */

export default {
  SET_CUSTOM_THEME(state, { theme, dark = false }) {
    if (state.theme.dark !== dark) {
      state.theme.dark = dark;
    }
    if (dark) {
      state.theme.custom.dark = theme;
    } else {
      state.theme.custom.light = theme;
    }
  },
  SET_LOGO_URL(state, url) {
    state.logoUrl = url;
  },
  SET_COUNTRIES(state, countries) {
    state.countries = countries;
  },
  SET_STATES(state, states) {
    state.states = states;
  },
  TOGGLE_INFO_SIDEBAR(state) {
    state.infoSidebarData.shown = !state.infoSidebarData.shown;
  },
  OPEN_SIDEBAR_INFO(state, object) {
    state.infoSidebarData.content = object.content;
    state.infoSidebarData.id = object.id;
    state.infoSidebarData.shown = true;
  },
  UPDATE_SIDEBAR_CONTENT(state, data) {
    state.sidebarContent = data;
  },
  SHOW_UPDATE_ORG_DIALOG(state, data) {
    state.updateOrgDialog.show = true;
    state.updateOrgDialog.content = data.content;
    state.updateOrgDialog.type = data.type;
  },
  HIDE_UPDATE_ORG_DIALOG(state) {
    state.updateOrgDialog.show = false;
  },
  SHOW_UPDATE_POLICY_DIALOG(state) {
    state.updatePolicyDialog.show = true;
  },
  HIDE_UPDATE_POLICY_DIALOG(state) {
    state.updatePolicyDialog.show = false;
  },
  SET_SCROLL_DISABLED(state, disabled) {
    state.scroll.disabled = disabled;
  },
  SET_SIDEBAR_LOADING(state) {
    state.sidebarLoading = true;
  },
  UNSET_SIDEBAR_LOADING(state) {
    state.sidebarLoading = false;
  },
  UPDATE_APP_LOCALE(state, locale) {
    state.currentLang = locale;
  },
};
