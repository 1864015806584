import { VBtn } from 'vuetify/lib';

export default (component => ({
  functional: true,
  name: 'VBtn',
  render(h, context) {
    return h(
      component,
      {
        ...context.data,
        props: {
          depressed: context.props.depressed === undefined || context.props.depressed,
          rounded: context.props.rounded !== false,
        },
      },
      context.children
    );
  },
}))(VBtn);
