import { FileProvider } from '@/graphql/providers';

export default {
  async uploadFile({ commit }, file) {
    try {
      if (file instanceof File) {
        commit('SET_LOADING', true);
        const {
          data: { uploadFile: response },
        } = await FileProvider.uploadFile(file);
        commit('SET_LOADING', false);
        return response;
      }
      throw new Error("The required argument 'file' is not a File");
    } catch (error) {
      commit('SET_LOADING', false);
      return Promise.reject(error);
    }
  },
  async uploadFilesArray({ commit }, files) {
    try {
      if (files instanceof Array && files.length) {
        commit('SET_LOADING', true);
        const response = await FileProvider.uploadFilesArray(files);
        commit('SET_LOADING', false);
        return response;
      }
      throw new Error("The required argument 'files' is not valid");
    } catch (error) {
      commit('SET_LOADING', false);
      return Promise.reject(error);
    }
  },
  async uploadFiles({ commit }, files) {
    try {
      if (files instanceof FileList) {
        commit('SET_LOADING', true);
        const response = await FileProvider.uploadFiles(files);
        commit('SET_LOADING', false);
        return response;
      }
      throw new Error("The required argument 'files' is not valid");
    } catch (error) {
      commit('SET_LOADING', false);
      return Promise.reject(error);
    }
  },
};
