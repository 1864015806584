/* eslint no-underscore-dangle: 0 */
import { ApolloLink } from 'apollo-link';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import EventEmitter from '@/utils/classes/EventEmitter';
import { env } from '@/utils/config';

class BaseProvider extends EventEmitter {
  constructor() {
    super();
    if (!BaseProvider._client) {
      BaseProvider._client = BaseProvider.createClient();
    }
  }

  static _apiUrl = env.API_URL;

  static _client = null;

  static get _token() {
    return localStorage.getItem('token');
  }

  static createClient() {
    const cache = new InMemoryCache();
    const authLink = setContext((_, { headers }) => {
      const token = BaseProvider._token;
      if (token) {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${token}`,
          },
        };
      }
      return headers;
    });
    const logoutLink = onError(({ graphQLErrors, networkError }) => {
      if (networkError && networkError.statusCode === 401) {
        this.dispatch('unauthorizedError', networkError);
      } else if (graphQLErrors instanceof Array && graphQLErrors.length) {
        const unauthedError = graphQLErrors.find(
          ({ message }) => message instanceof Object && message.statusCode === 401
        );
        if (unauthedError) {
          this.dispatch('unauthorizedError', unauthedError);
        }
      }
    });
    const uploadLink = createUploadLink({ uri: BaseProvider._apiUrl });
    const link = ApolloLink.from([authLink, logoutLink, uploadLink]);
    return new ApolloClient({
      cache,
      link,
    });
  }

  static resetCache() {
    if (
      BaseProvider._client instanceof Object &&
      typeof BaseProvider._client.clearStore === 'function'
    ) {
      BaseProvider._client.clearStore();
    } else {
      throw new Error("The client wasn't initialized");
    }
  }

  // eslint-disable-next-line
  get client() {
    return BaseProvider._client;
  }

  query(query, variables = {}, params = {}) {
    return this.client.query({ query, variables, ...params });
  }

  mutate(mutation, variables = {}, params = {}) {
    return this.client.mutate({ mutation, variables, ...params });
  }

  watchQuery(query, variables = {}, params = {}) {
    return this.client.watchQuery({ query, variables, params });
  }
}

export default BaseProvider;
