import { CRAWFORD_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const RISK_LOCATION_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.RISK_LOCATION;

export default () => ({
  // aliases for usage in form templates
  RISK_LOCATION_FIELD_ALIASES: {
    type: Object,
    default: () => RISK_LOCATION_FIELD_ALIASES,
  },
  [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_NAME]: String,
  [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE1]: String,
  [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE2]: String,
  [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_CITY]: String,
  [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_STATE]: String,
  [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_ZIP]: String,
  [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_COUNTRY]: String,
  [RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_WORK_PHONE]: String,
});
