<template>
  <a target="_blank" class="contactUs__link" href="https://help.playershealth.com/">
    <div class="contactUs__container">
      <img
        class="contactUs__image"
        src="@/assets/img/question-mark-small.png"
        alt="contact-us-link"
      />
      <div class="contactUs__text">
        Need help?
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'ContactUsLink',
};
</script>
<style lang="css">
.contactUs__link {
  color: inherit;
}
.contactUs__container {
  display: flex;
  gap: 4px;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
  width: 115px;
}
.contactUs__image {
  border-radius: 100%;
  width: 25px;
  height: 25px;
}
.contactUs__text {
  text-decoration: underline;
}
</style>
