export default {
  methods: {
    reset() {
      if (this.$refs.form) {
        return this.$refs.form.reset();
      }
      throw new Error("The form wasn't mounted");
    },
    submit() {
      const valid = this.validate();
      if (valid) {
        this.$emit('submit');
      }
      return valid;
    },
    validate() {
      if (this.$refs.form) {
        return this.$refs.form.validate();
      }
      throw new Error("The form wasn't mounted");
    },
  },
};
