export default {
  props: {
    debounceDurationMs: {
      type: Number,
      default: 800,
    },
    value: [String, Number],
  },
  data() {
    return {
      debounceTimeout: null,
    };
  },
  computed: {
    debouncedValue: {
      get() {
        return this.value;
      },
      set(query) {
        if (query !== this.value) {
          this.debounceValueUpdate(query);
        }
      },
    },
  },
  methods: {
    clearDebounceTimeout() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
    },
    setDebounceTimeout(callback) {
      this.debounceTimeout = setTimeout(callback, this.debounceDurationMs);
    },
    debounceValueUpdate(val) {
      const inputUpdateCallback = (value => () => {
        this.$emit('input', value);
      })(val);
      if (this.debounceTimeout) {
        this.clearDebounceTimeout();
      }
      this.setDebounceTimeout(inputUpdateCallback);
    },
  },
  beforeDestroy() {
    this.clearDebounceTimeout();
  },
};
