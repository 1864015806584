<template>
  <v-form @submit.prevent="submit" ref="form" class="add-policy-form">
    <div class="add-policy-form__content">
      <div class="add-policy-form__fields">
        <v-text-field
          @input="handleFieldInput('policyNumber', arguments[0])"
          :value="policyNumber"
          :rules="['required', 'maxlen250']"
          :disabled="disabled"
          label="Policy Number"
        />
        <v-text-field
          @input="handleFieldInput('effectiveDate', arguments[0])"
          :value="effectiveDate"
          :rules="['required', 'effectiveDate']"
          :disabled="disabled"
          label="Effective Date"
          type="date"
        />
        <v-text-field
          @input="handleFieldInput('expiredDate', arguments[0])"
          :value="expiredDate"
          :rules="['required', 'expiredDate']"
          :disabled="disabled"
          label="Expiration Date"
          type="date"
        />
        <v-text-field
          @input="handleFieldInput('carrier', arguments[0])"
          :value="carrier"
          :rules="['maxlen250']"
          :disabled="disabled"
          label="Carrier"
        />
        <v-text-field
          @input="handleFieldInput('deductible', arguments[0])"
          :value="deductible"
          :rules="['maxlen250']"
          :disabled="disabled"
          label="Deductible"
        />
        <v-text-field
          @input="handleFieldInput('coinsurance', arguments[0])"
          :value="coinsurance"
          :rules="['maxlen250']"
          :disabled="disabled"
          label="Coinsurance"
        />
        <v-text-field
          @input="handleFieldInput('benefitPeriod', arguments[0])"
          :value="benefitPeriod"
          :rules="['maxlen250']"
          :disabled="disabled"
          label="Benefit Period"
        />
        <v-text-field
          @input="handleFieldInput('perInjuryLimit', arguments[0])"
          :value="perInjuryLimit"
          :rules="['maxlen250']"
          :disabled="disabled"
          label="Per Injury Limit"
        />
        <v-text-field
          @input="handleFieldInput('physicalTherapySublimit', arguments[0])"
          :value="physicalTherapySublimit"
          :rules="['maxlen250']"
          :disabled="disabled"
          label="Physical Therapy Sub Limit"
        />
        <v-select
          @input="handleFieldInput('tpaName', arguments[0])"
          :value="tpaName"
          :disabled="disabled"
          :items="tpaOptions"
          label="TPA Name"
        />
        <v-text-field
          @input="handleFieldInput('email', arguments[0])"
          :value="email"
          :rules="['email']"
          :disabled="disabled"
          label="TPA Email"
          type="email"
        />
      </div>
    </div>
  </v-form>
</template>

<script>
import syncForm from '@/mixins/syncForm';
import validateForm from '@/mixins/validateForm';
import { POLICY_TPA_NAMES } from '../../utils/constants';

export default {
  name: 'AddPolicyForm',
  mixins: [syncForm, validateForm],
  props: {
    disabled: Boolean,
    policyNumber: String,
    effectiveDate: String,
    expiredDate: String,
    carrier: String,
    deductible: String,
    coinsurance: String,
    benefitPeriod: String,
    perInjuryLimit: String,
    physicalTherapySublimit: String,
    tpaName: String,
    email: String,
  },
  data() {
    return {
      tpaOptions: POLICY_TPA_NAMES,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/base/index';
.add-policy-form__fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3rem;
}
.add-policy-form__footer {
  margin-top: 1.125rem;
}
</style>
