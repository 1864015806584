import BaseProvider from './BaseProvider';
import * as mutations from '@/graphql/mutations';

class AnalyticsProvider extends BaseProvider {
  logEvent({ event, data }) {
    return this.mutate(mutations.logEvent, {
      event,
      data,
    });
  }
}

export default AnalyticsProvider;
