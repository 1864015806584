import { USER_ROLES } from '@/utils/constants';

export default {
  path: '/organizations',
  name: 'organizations',
  component: () => import('@/views/Organizations'),
  meta: {
    requiredRoles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.BROKER_ADMIN],
  },
};
