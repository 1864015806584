import BaseProvider from './BaseProvider';
import * as mutations from '@/graphql/mutations';

class FileProvider extends BaseProvider {
  uploadFile(file) {
    return this.mutate(mutations.uploadFile, {
      file,
    });
  }

  async uploadFiles(files) {
    try {
      if (files instanceof FileList) {
        const uploadPromisesArray = [];
        files.forEach(file => uploadPromisesArray.push(this.uploadFile(file)));
        const uploadPromises = await Promise.all(uploadPromisesArray);
        return {
          data: {
            uploadFiles: uploadPromises.map(upl => upl && upl.data && upl.data.uploadFile),
          },
        };
      }
      throw new Error("The required parameter 'files' is absent or invalid");
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async uploadFilesArray(files) {
    try {
      if (files instanceof Array && files.length) {
        const uploadPromisesArray = [];
        files.forEach(file => uploadPromisesArray.push(this.uploadFile(file)));
        const uploadPromises = await Promise.all(uploadPromisesArray);
        return {
          data: {
            uploadFiles: uploadPromises.map(upl => upl && upl.data && upl.data.uploadFile),
          },
        };
      }
      throw new Error("The required parameter 'files' is absent or invalid");
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
export default FileProvider;
