import { mapGetters } from 'vuex';
import { properClaimInfo } from '@/utils/helpers/app/sidebar/claimContent';
import { properOrgInfo } from '@/utils/helpers/app/sidebar/orgContent';
import { properBrokerOrgInfo } from '@/utils/helpers/app/sidebar/brokerOrgContent';

export default {
  computed: {
    ...mapGetters('app', ['infoSidebarData', 'sidebarContent']),
    getProperContent() {
      if (this.infoSidebarData.content === 'organization') {
        return properOrgInfo(this.sidebarContent);
      }
      if (this.infoSidebarData.content === 'brokerOrganization') {
        return properBrokerOrgInfo(this.sidebarContent);
      }
      if (this.infoSidebarData.content === 'fnol') {
        return properClaimInfo(this.sidebarContent, true);
      }
      return properClaimInfo(this.sidebarContent, false);
    },
    getOrgsLogo() {
      if (
        this.infoSidebarData.content === 'brokerOrganization' ||
        this.infoSidebarData.content === 'organization'
      ) {
        if (this.sidebarContent.logo) {
          return this.sidebarContent.logo;
        }
        if (
          this.sidebarContent.landingPageSettings &&
          this.sidebarContent.landingPageSettings.logo
        ) {
          return this.sidebarContent.landingPageSettings.logo;
        }
        return '';
      }
      return '';
    },
  },
};
