import { CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const CLAIMANT_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.CLAIMANT;

export default () => ({
  // aliases for usage in form templates
  CLAIMANT_FIELD_ALIASES: {
    type: Object,
    default: () => CLAIMANT_FIELD_ALIASES,
  },
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB]: [String, Number],
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_MEMBER_ID]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER]: [String, Object],
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_MIDDLE_INITIAL]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_FATHERS_FULL_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_MOTHERS_FULL_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ROLE_IN_ORG]: [String, Object],
});
