import { CRAWFORD_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const CALLER_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.CALLER;

export default () => ({
  // aliases for usage in form templates
  CALLER_FIELD_ALIASES: {
    type: Object,
    default: () => CALLER_FIELD_ALIASES,
  },
  [CALLER_FIELD_ALIASES.CALLER_FIRST_NAME]: String,
  [CALLER_FIELD_ALIASES.CALLER_LAST_NAME]: String,
  [CALLER_FIELD_ALIASES.CALLER_WORK_PHONE]: String,
  [CALLER_FIELD_ALIASES.CALLER_EMAIL]: String,
  [CALLER_FIELD_ALIASES.CALLER_TYPE]: String,
});
