import getClaimsFilterPayload from './helpers/getClaimsFilterPayload';
import getFnolsFilterPayload from './helpers/getFnolsFilterPayload';
import { filterClaimApiPayload, convertFormToClaimApiPayload } from '@/utils/helpers/app/claims';
import {
  filterOlympicClaimApiPayload,
  convertFormToOlympicClaimApiPayload,
} from '@/utils/helpers/app/claimsOlympic';
import {
  filterUsasaClaimApiPayload,
  convertFormToUsasaClaimApiPayload,
} from '@/utils/helpers/app/claimsUsasa';
import {
  filterNaghaClaimApiPayload,
  convertFormToNaghaClaimApiPayload,
} from '@/utils/helpers/app/claimsNagha';
import {
  filterCrawfordClaimApiPayload,
  convertFormToCrawfordClaimApiPayload,
} from '@/utils/helpers/app/claimsCrawford';
import isValidString from '@/utils/helpers/string/isValidString';
import { ClaimsProvider } from '@/graphql/providers';
import { SPORTS_UNION_TYPES } from '@/utils/constants';

export default {
  async submitClaimEditForm(context, { id, form, olympic = false }) {
    try {
      const apiReadyForm = olympic
        ? convertFormToOlympicClaimApiPayload(form)
        : convertFormToClaimApiPayload(form);
      const filteredApiReadyForm = olympic
        ? filterOlympicClaimApiPayload(apiReadyForm)
        : filterClaimApiPayload(apiReadyForm);
      const {
        data: { updateClaim },
      } = await ClaimsProvider.updateClaim(id, filteredApiReadyForm);
      return !!(updateClaim instanceof Object);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  async submitFNOLCreateForm(context, form) {
    try {
      const apiReadyForm = convertFormToClaimApiPayload(form);
      const filteredApiReadyForm = filterClaimApiPayload(apiReadyForm);
      const { data } = await ClaimsProvider.createFnol(filteredApiReadyForm);
      if (data && data.createClaim.id) {
        context.commit('FILL_SUBMITTED_FNOL_ID', data.createClaim.id);
      }
      return !!(data instanceof Object && data.createClaim);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  async submitUsasaFNOLCreateForm(context, form) {
    try {
      const apiReadyForm = convertFormToUsasaClaimApiPayload(form);
      const filteredApiReadyForm = filterUsasaClaimApiPayload(apiReadyForm);
      const { data } = await ClaimsProvider.createUSASAFnol(filteredApiReadyForm);
      if (data && data.createUSASAClaim.id) {
        context.commit('FILL_SUBMITTED_FNOL_ID', data.createUSASAClaim.id);
      }
      return !!(data instanceof Object && data.createUSASAClaim);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  async submitColoradoFNOLCreateForm(context, form) {
    try {
      const apiReadyForm = convertFormToUsasaClaimApiPayload(form);
      const filteredApiReadyForm = filterUsasaClaimApiPayload(apiReadyForm);
      const { data } = await ClaimsProvider.createColoradoFnol(filteredApiReadyForm);
      if (data && data.createColoradoClaim.id) {
        context.commit('FILL_SUBMITTED_FNOL_ID', data.createColoradoClaim.id);
      }
      return !!(data instanceof Object && data.createColoradoClaim);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  async submitFysaFNOLCreateForm(context, form) {
    try {
      const apiReadyForm = convertFormToUsasaClaimApiPayload(form);
      const filteredApiReadyForm = filterUsasaClaimApiPayload(apiReadyForm);
      const { data } = await ClaimsProvider.createFYSAFnol(filteredApiReadyForm);
      if (data && data.createFYSAClaim.id) {
        context.commit('FILL_SUBMITTED_FNOL_ID', data.createFYSAClaim.id);
      }
      return !!(data instanceof Object && data.createFYSAClaim);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  async submitNaghaFNOLCreateForm(context, form) {
    try {
      const apiReadyForm = convertFormToNaghaClaimApiPayload(form);
      const filteredApiReadyForm = filterNaghaClaimApiPayload(apiReadyForm);
      const { data } = await ClaimsProvider.createNAGHAFnol(filteredApiReadyForm);
      if (data && data.createNAGHAClaim.id) {
        context.commit('FILL_SUBMITTED_FNOL_ID', data.createNAGHAClaim.id);
      }
      return !!(data instanceof Object && data.createNAGHAClaim);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  async submitOlympicFNOLCreateForm(context, form) {
    try {
      const apiReadyForm = convertFormToOlympicClaimApiPayload(form);
      const filteredApiReadyForm = filterOlympicClaimApiPayload(apiReadyForm);
      const { data } = await ClaimsProvider.createOlympicFnol(filteredApiReadyForm);
      if (data && data.createOlympicClaim.id) {
        context.commit('FILL_SUBMITTED_FNOL_ID', data.createOlympicClaim.id);
      }
      return !!(data instanceof Object && data.createOlympicClaim);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  async submitCrawfordFNOLCreateForm(context, form) {
    try {
      const apiReadyForm = convertFormToCrawfordClaimApiPayload(form);
      const filteredApiReadyForm = filterCrawfordClaimApiPayload(apiReadyForm);
      const { data } = await ClaimsProvider.createCrawfordFnol(filteredApiReadyForm);
      if (data && data.createCrawfordClaim.id) {
        context.commit('FILL_SUBMITTED_FNOL_ID', data.createCrawfordClaim.id);
      }
      return !!(data instanceof Object && data.createCrawfordClaim);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  async submitFNOLEditForm(context, { id, form, formType }) {
    try {
      let apiReadyForm;
      let filteredApiReadyForm;

      if (formType === SPORTS_UNION_TYPES.DEFAULT) {
        apiReadyForm = convertFormToClaimApiPayload(form);
        filteredApiReadyForm = filterClaimApiPayload(apiReadyForm);
      }

      if (formType === SPORTS_UNION_TYPES.OLYMPIC) {
        apiReadyForm = convertFormToOlympicClaimApiPayload(form);
        filteredApiReadyForm = filterOlympicClaimApiPayload(apiReadyForm);
      }

      if (formType === SPORTS_UNION_TYPES.USASA) {
        apiReadyForm = convertFormToUsasaClaimApiPayload(form);
        filteredApiReadyForm = filterUsasaClaimApiPayload(apiReadyForm);
      }

      if (formType === SPORTS_UNION_TYPES.FYSA) {
        apiReadyForm = convertFormToUsasaClaimApiPayload(form);
        filteredApiReadyForm = filterUsasaClaimApiPayload(apiReadyForm);
      }

      if (formType === SPORTS_UNION_TYPES.NAGHA) {
        apiReadyForm = convertFormToNaghaClaimApiPayload(form);
        filteredApiReadyForm = filterNaghaClaimApiPayload(apiReadyForm);
      }

      if (formType === SPORTS_UNION_TYPES.COLORADOMTB) {
        apiReadyForm = convertFormToUsasaClaimApiPayload(form);
        filteredApiReadyForm = filterUsasaClaimApiPayload(apiReadyForm);
      }

      if (formType === SPORTS_UNION_TYPES.CRAWFORD) {
        apiReadyForm = convertFormToCrawfordClaimApiPayload(form);
        filteredApiReadyForm = filterCrawfordClaimApiPayload(apiReadyForm);
      }

      const {
        data: { updateClaim },
      } = await ClaimsProvider.updateFnol(id, filteredApiReadyForm);
      return !!(updateClaim instanceof Object);
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  async confirmFnol(context, { token, name }) {
    try {
      const { data } = ClaimsProvider.confirmFnol({ token, name });
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  async coachDecline(context, { token, name, reason }) {
    try {
      const { data } = ClaimsProvider.coachDecline({ token, name, reason });
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  fetchClaimById(context, id) {
    if (isValidString(id)) {
      return ClaimsProvider.fetchClaimById(id);
    }
    return Promise.reject(new Error('The required parameter "id" is invalid or absent'));
  },
  async fetchClaimForReviewByToken(context, token) {
    try {
      const { data } = await ClaimsProvider.fetchClaimForFnolReview(token);
      return data instanceof Object && data.claimByCoachToken;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  fetchClaims({ commit, getters }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_CLAIMS', true);
      const filterPayload = getClaimsFilterPayload(getters.claimFilters);
      ClaimsProvider.fetchClaims(filterPayload.output, filterPayload.outputSort)
        .then(response => {
          if (response.data && response.data.claims && response.data.claims instanceof Array) {
            commit('UPDATE_CLAIMS', response.data.claims);
          }
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          commit('SET_LOADING_CLAIMS', false);
        });
    });
  },
  fetchFnols({ commit, getters }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING_FNOLS', true);
      const filterPayload = getFnolsFilterPayload(getters.fnolFilters);
      ClaimsProvider.fetchFnols(filterPayload.output, filterPayload.outputSort)
        .then(response => {
          if (response.data && response.data.fnols && response.data.fnols instanceof Array) {
            commit('UPDATE_FNOLS', response.data.fnols);
          }
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          commit('SET_LOADING_FNOLS', false);
        });
    });
  },
  x({ commit }, filterFragment) {
    if (filterFragment instanceof Object) {
      commit('UPDATE_CLAIM_FILTERS', filterFragment);
    } else {
      throw new Error("The required 'filterFragment' argument is absent or invalid");
    }
  },
  updateFnolFilters({ commit }, filterFragment) {
    if (filterFragment instanceof Object) {
      commit('UPDATE_FNOL_FILTERS', filterFragment);
    } else {
      throw new Error("The required 'filterFragment' argument is absent or invalid");
    }
  },
  async setClaimFullyPaid({ dispatch }, id) {
    try {
      await ClaimsProvider.setClaimFullyPaid(id);
      const response = await dispatch('fetchClaims');
      return !!response.data;
    } catch (err) {
      dispatch('globalDialog/showErrorDialog', { error: err }, { root: true });
      return Promise.reject(err);
    }
  },
  async setClaimPartiallyPaid({ dispatch }, id) {
    try {
      await ClaimsProvider.setClaimPartiallyPaid(id);
      const response = await dispatch('fetchClaims');
      return !!response.data;
    } catch (err) {
      dispatch('globalDialog/showErrorDialog', { error: err }, { root: true });
      return Promise.reject(err);
    }
  },
  async setClaimDeclined({ dispatch }, { id, reason = '-' }) {
    try {
      await ClaimsProvider.setClaimDeclined(id, reason);
      const response = await dispatch('fetchClaims');
      return !!response.data;
    } catch (err) {
      dispatch('globalDialog/showErrorDialog', { error: err }, { root: true });
      return Promise.reject(err);
    }
  },
  async acceptFnol({ dispatch }, id) {
    try {
      const {
        data: { acceptFnol: claim },
      } = await ClaimsProvider.acceptFnol({ id });
      await dispatch('fetchFnols');
      return claim;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async generateClaimPdf({ commit, dispatch }, id) {
    return new Promise(resolve => {
      commit('SET_LOADING_FNOLS', true);
      ClaimsProvider.generateClaimPdf(id)
        .then(response => {
          window.location.href = response.data.generateClaimPdf;
          resolve(response);
        })
        .catch(err => {
          dispatch('globalDialog/showErrorDialog', { error: err }, { root: true });
        })
        .finally(() => {
          commit('SET_LOADING_FNOLS', false);
        });
    });
  },
  async getClaimPdfUrl({ dispatch }, id) {
    try {
      const response = await ClaimsProvider.getClaimPdfUrl(id);
      return response.data.getClaimPdfUrl;
    } catch (err) {
      dispatch('globalDialog/showErrorDialog', { error: err }, { root: true });
      return Promise.reject(err);
    }
  },
  async declineFnol({ dispatch }, { id, reason }) {
    try {
      await ClaimsProvider.declineFnol({ id, reason });
      const response = await dispatch('fetchFnols');
      return !!response.data;
    } catch (err) {
      dispatch('globalDialog/showErrorDialog', { error: err }, { root: true });
      return Promise.reject(err);
    }
  },
  async exportClaim({ dispatch }, id) {
    try {
      const response = await ClaimsProvider.exportClaim(id);
      return !!response.data;
    } catch (err) {
      dispatch('globalDialog/showErrorDialog', { error: err }, { root: true });
      return Promise.reject(err);
    }
  },
  async generateCrawfordXML({ dispatch }, id) {
    try {
      const response = await ClaimsProvider.generateCrawfordXML(id);
      return response.data.generateClaimCrawfordXml;
    } catch (err) {
      dispatch('globalDialog/showErrorDialog', { error: err }, { root: true });
      return Promise.reject(err);
    }
  },
};
