import { OLYMPIC_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const CLAIMANT_FIELD_ALIASES = OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT;

export default () => ({
  // aliases for usage in form templates
  CLAIMANT_FIELD_ALIASES: {
    type: Object,
    default: () => CLAIMANT_FIELD_ALIASES,
  },
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_CITY]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB]: [String, Number],
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_SSN]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER]: [String, Object],
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_FULL_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_ADDRESS]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_PARENTS_PHONE]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_ROLE_IN_ORG]: [String, Object],
});
