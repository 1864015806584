/* eslint no-param-reassign: 0 */

export default {
  SET_LOADED(context, loaded) {
    context.loadingState.loaded = !!loaded;
  },
  SET_LOADING(context, loading) {
    context.loadingState.loading = !!loading;
  },
  UPDATE_ORGANIZATIONS(context, orgs) {
    context.organizations = orgs;
  },
};
