import { OrganizationsProvider, UserProvider } from '@/graphql/providers';
import isValidString from '@/utils/helpers/string/isValidString';

export default {
  async createBrokerOrganization({ dispatch }, input) {
    try {
      await OrganizationsProvider.createBrokerOrganization(input);
      const response = await dispatch('fetchBrokerOrganizations');
      return !!response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async deleteBrokerOrganization(context) {
    const { id } = context.rootGetters['app/infoSidebarData'];
    try {
      await OrganizationsProvider.deleteBrokerOrganization(id);
      const response = await context.dispatch('fetchBrokerOrganizations');
      return !!response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  fetchBrokerOrganizations({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      OrganizationsProvider.fetchBrokerOrganizations()
        .then(response => {
          if (
            response.data &&
            response.data.brokerOrganizations &&
            response.data.brokerOrganizations instanceof Array
          ) {
            commit('UPDATE_BROKER_ORGANIZATIONS', response.data.brokerOrganizations);
          }
          commit('SET_LOADED', true);
          resolve(response);
        })
        .catch(error => {
          commit('SET_LOADED', false);
          reject(error);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
  async updateBrokerOrganization({ dispatch }, object) {
    try {
      await OrganizationsProvider.updateBrokerOrganization({
        id: object.id,
        updateBrokerOrganizationInput: object.form,
      });
      const response = await dispatch('fetchBrokerOrganizations');
      await dispatch(
        'app/populateBrokerOrganizationSidebarInfo',
        {
          id: object.id,
        },
        { root: true }
      );
      return !!response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async inviteAdminToBrokerOrganization(context, { name, email, brokerId }) {
    if (!isValidString(name)) {
      throw new Error('The required argument "name" is absent or invalid');
    }
    if (!isValidString(email)) {
      throw new Error('The required argument "email" is absent or invalid');
    }
    if (!isValidString(brokerId)) {
      throw new Error('The required argument "brokerId" is absent or invalid');
    }
    try {
      const { data } = await OrganizationsProvider.inviteAdminToBrokerOrganization({
        name,
        email,
        brokerId,
      });
      return !!data.invite;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async addPolicyToBrokerOrganization(
    context,
    {
      policyNumber,
      effectiveDate,
      expiredDate,
      carrier,
      deductible,
      coinsurance,
      benefitPeriod,
      perInjuryLimit,
      physicalTherapySublimit,
      tpaName,
      email,
      brokerId,
    }
  ) {
    if (!isValidString(policyNumber)) {
      throw new Error('The required argument "policyNumber" is absent or invalid');
    }
    if (!isValidString(effectiveDate)) {
      throw new Error('The required argument "effectiveDate" is absent or invalid');
    }
    if (!isValidString(expiredDate)) {
      throw new Error('The required argument "expiredDate" is absent or invalid');
    }
    try {
      const {
        data: { createPolicy },
      } = await OrganizationsProvider.addPolicyToBrokerOrganization({
        policyNumber,
        effectiveDate,
        expiredDate,
        carrier,
        deductible,
        coinsurance,
        benefitPeriod,
        perInjuryLimit,
        physicalTherapySublimit,
        tpaName,
        email,
        brokerId,
      });
      return !!createPolicy.id;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async resendAdminInviteToBrokerOrganization(_, { email }) {
    if (!isValidString(email)) {
      throw new Error('The required argument "email" is absent or invalid');
    }
    try {
      const { data } = await OrganizationsProvider.resendAdminInviteToBrokerOrganization({ email });
      return !!data.resendInvite;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateBrokerAdmin({ dispatch }, { adminId, brokerId, payload: { email, name } }) {
    if (!isValidString(name)) {
      throw new Error('The required argument "name" is absent or invalid');
    }
    if (!isValidString(email)) {
      throw new Error('The required argument "email" is absent or invalid');
    }
    if (!isValidString(adminId)) {
      throw new Error('The required argument "adminId" is absent or invalid');
    }
    if (!isValidString(brokerId)) {
      throw new Error('The required argument "brokerId" is absent or invalid');
    }
    try {
      const updateResponse = await UserProvider.updateUser(adminId, {
        email,
        name,
      });
      await dispatch('fetchBrokerOrganizations');
      await dispatch(
        'app/populateBrokerOrganizationSidebarInfo',
        {
          id: brokerId,
          refreshCache: true,
        },
        { root: true }
      );
      return !!updateResponse.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updatePolicyInformation(
    { dispatch },
    {
      id,
      payload: {
        policyNumber,
        effectiveDate,
        expiredDate,
        carrier,
        deductible,
        coinsurance,
        benefitPeriod,
        perInjuryLimit,
        physicalTherapySublimit,
        tpaName,
        email,
        brokerId,
      },
    }
  ) {
    if (!isValidString(policyNumber)) {
      throw new Error('The required argument "policyNumber" is absent or invalid');
    }
    if (!isValidString(effectiveDate)) {
      throw new Error('The required argument "effectiveDate" is absent or invalid');
    }
    if (!isValidString(expiredDate)) {
      throw new Error('The required argument "expiredDate" is absent or invalid');
    }
    try {
      const updateResponse = await OrganizationsProvider.updatePolicyInformation(id, {
        policyNumber,
        effectiveDate,
        expiredDate,
        brokerId,
        carrier,
        deductible,
        coinsurance,
        benefitPeriod,
        perInjuryLimit,
        physicalTherapySublimit,
        tpaName,
        email,
      });
      await dispatch(
        'app/populateBrokerOrganizationSidebarInfo',
        {
          id: brokerId,
          refreshCache: true,
        },
        { root: true }
      );
      return !!updateResponse.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async activateBrokerAdmin({ dispatch }, { adminId, brokerId }) {
    if (!isValidString(adminId)) {
      throw new Error('The required argument "adminId" is absent or invalid');
    }
    try {
      const { activateUser: activateResponse } = await UserProvider.activateUser(adminId);
      await dispatch('fetchBrokerOrganizations');
      await dispatch(
        'app/populateBrokerOrganizationSidebarInfo',
        {
          id: brokerId,
          refreshCache: true,
        },
        { root: true }
      );
      return activateResponse;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async removeBrokerAdmin({ dispatch }, { adminId, brokerId }) {
    if (!isValidString(adminId)) {
      throw new Error('The required argument "adminId" is absent or invalid');
    }
    try {
      const { deactivateUser: removeResponse } = await UserProvider.deactivateUser(adminId);
      await dispatch('fetchBrokerOrganizations');
      await dispatch(
        'app/populateBrokerOrganizationSidebarInfo',
        {
          id: brokerId,
          refreshCache: true,
        },
        { root: true }
      );
      return removeResponse;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
