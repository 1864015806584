import Vue from 'vue';
import VueRouter from 'vue-router';
import authCheck from './middlewares/authCheck';
import authRoutes from './routes/auth';
import publicRoutes from './routes/public';
import privateRoutes from './routes/private';
import landing from './routes/landing.js';

const notFoundRedirect = {
  path: '*',
  redirect: { name: 'notFound' },
};

Vue.use(VueRouter);

const routes = [...authRoutes, ...publicRoutes, ...privateRoutes, landing, notFoundRedirect];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(authCheck);

export default router;
