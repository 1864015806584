import getDefaultClaimFiltersState from './helpers/getDefaultClaimFiltersState';

export default {
  filters: {
    claims: getDefaultClaimFiltersState(),
    fnols: getDefaultClaimFiltersState(),
  },
  loading: {
    claims: false,
    fnols: false,
  },
  claims: [],
  fnols: [],
  submittedFnolId: '',
};
