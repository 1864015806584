import BaseProvider from './BaseProvider';
import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';

class OrganizationsProvider extends BaseProvider {
  getSportsUnionByUrl(url) {
    return this.query(queries.sportsUnionByUrl, { url });
  }

  fetchBrokerOrganizations() {
    return this.query(queries.brokerOrganizations);
  }

  fetchSportsUnions() {
    return this.query(queries.sportsUnions);
  }

  fetchSportsUnionById(id) {
    return this.query(queries.sportsUnion, { id });
  }

  fetchBrokerOrganizationById(id, refreshCache = false) {
    let options = {};
    if (refreshCache) {
      options = {
        ...options,
        fetchPolicy: 'network-only',
      };
    }
    return this.query(queries.brokerOrganization, { id }, options);
  }

  fetchPolicyByBrokerId(brokerId, refreshCache = false) {
    let options = {};
    if (refreshCache) {
      options = {
        ...options,
        fetchPolicy: 'network-only',
      };
    }
    return this.query(queries.policys, { brokerId }, options);
  }

  createSportsUnion(createSportsUnionInput) {
    return this.mutate(
      mutations.createSportsUnion,
      { createSportsUnionInput },
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: queries.sportsUnions }],
      }
    );
  }

  createBrokerOrganization(createBrokerOrganizationInput) {
    return this.mutate(
      mutations.createBrokerOrganization,
      { createBrokerOrganizationInput },
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: queries.brokerOrganizations }],
      }
    );
  }

  updateBrokerOrganization({ id, updateBrokerOrganizationInput }) {
    return this.mutate(
      mutations.updateBrokerOrganization,
      {
        id,
        updateBrokerOrganizationInput,
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: queries.brokerOrganizations },
          { query: queries.brokerOrganization, variables: { id } },
        ],
      }
    );
  }

  updateSportsUnion({ id, updateSportsUnionInput }) {
    return this.mutate(
      mutations.updateSportsUnion,
      {
        id,
        updateSportsUnionInput,
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: queries.sportsUnions },
          { query: queries.sportsUnion, variables: { id } },
        ],
      }
    );
  }

  deleteBrokerOrganization(id) {
    return this.mutate(
      mutations.deleteBrokerOrganization,
      { id },
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: queries.brokerOrganizations }],
      }
    );
  }

  deleteSportsUnion(id) {
    return this.mutate(
      mutations.deleteSportsUnion,
      { id },
      {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: queries.sportsUnions }],
      }
    );
  }

  inviteAdminToBrokerOrganization(inviteInput) {
    return this.mutate(
      mutations.invite,
      {
        inviteInput,
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: queries.brokerOrganization,
            variables: {
              id: inviteInput.brokerId,
            },
          },
        ],
      }
    );
  }

  addPolicyToBrokerOrganization(policyInput) {
    return this.mutate(
      mutations.createPolicy,
      {
        policyInput,
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: queries.policys,
            variables: {
              brokerId: policyInput.brokerId,
            },
          },
        ],
      }
    );
  }

  updatePolicyInformation(id, policyInput) {
    return this.mutate(
      mutations.updatePolicy,
      {
        id,
        policyInput,
      },
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: queries.policys,
            variables: {
              brokerId: policyInput.brokerId,
            },
          },
        ],
      }
    );
  }

  resendAdminInviteToBrokerOrganization(resendInviteInput) {
    return this.mutate(mutations.resendInvite, { resendInviteInput });
  }

  addSportsUnionStateAdmin({ id, stateAdminInput }) {
    return this.mutate(
      mutations.addStateAdmin,
      { id, stateAdminInput },
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: queries.sportsUnions },
          { query: queries.sportsUnion, variables: { id } },
        ],
      }
    );
  }

  removeSportsUnionStateAdmin({ id, stateAdminId }) {
    return this.mutate(
      mutations.removeStateAdmin,
      { id, stateAdminId },
      {
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: queries.sportsUnions },
          { query: queries.sportsUnion, variables: { id } },
        ],
      }
    );
  }
}

export default OrganizationsProvider;
