import { mapActions, mapGetters } from 'vuex';
import { fnolStatuses } from '@/utils/options';

export default {
  data() {
    return {
      fnolFilterOptions: {
        status: [
          {
            text: 'All statuses',
            value: 'all',
          },
          ...fnolStatuses,
        ],
      },
    };
  },
  computed: {
    ...mapGetters('claims', ['fnolFilters']),
    ...mapGetters('organizations', ['organizationsOptions']),
    fnolDateFromFilter: {
      get() {
        return this.fnolFilters.dateFrom;
      },
      set(dateFrom) {
        const dateString = dateFrom ? `${dateFrom} 00:00:01` : dateFrom;
        this.updateFnolFilters({
          dateFrom: dateString,
        });
      },
    },
    fnolDateToFilter: {
      get() {
        return this.fnolFilters.dateTo;
      },
      set(dateTo) {
        const dateString = dateTo ? `${dateTo} 23:59:59` : dateTo;
        this.updateFnolFilters({
          dateTo: dateString,
        });
      },
    },
    fnolSearchQueryFilter: {
      get() {
        return this.fnolFilters.searchQuery;
      },
      set(searchQuery) {
        this.updateFnolFilters({
          searchQuery,
        });
      },
    },
    fnolStatusFilter: {
      get() {
        return this.fnolFilters.status;
      },
      set(status) {
        this.updateFnolFilters({
          status,
        });
      },
    },
    fnolOrganizationsFilter: {
      get() {
        return this.fnolFilters.organizations;
      },
      set(organizations) {
        this.updateFnolFilters({
          organizations,
        });
      },
    },
    fnolClaimantNameFilter: {
      get() {
        return this.fnolFilters.claimantName;
      },
      set(name) {
        this.updateFnolFilters({ claimantName: name });
      },
    },
  },
  async created() {
    await this.fetchSportsUnions();
  },
  methods: {
    ...mapActions('claims', ['fetchFnols', 'updateFnolFilters']),
    ...mapActions('organizations', ['fetchSportsUnions']),
    handleSortType(item) {
      const direction = item ? 'ASC' : 'DESC';
      this.updateFnolFilters({
        sortBy: 'claimantFirstName',
        sortDesc: direction,
      });
    },
  },
};
