<template>
  <div class="info-sidebar">
    <v-layout align-center fill-height class="info-sidebar__wrapper" fluid>
      <CloseBtn class="info-sidebar__close" @click="handleClick" />
      <div class="info-sidebar__menu">
        <slot name="menu"></slot>
      </div>
      <div class="info-sidebar__content">
        <div class="info-sidebar__content-scroll">
          <slot name="content"></slot>
        </div>
      </div>
      <div class="info-sidebar__footer">
        <slot name="footer"></slot>
      </div>
    </v-layout>
  </div>
</template>

<script>
import CloseBtn from '@/components/buttons/CloseBtn';

export default {
  name: 'InfoSidebar',
  components: {
    CloseBtn,
  },
  methods: {
    handleClick() {
      this.$emit('closeSideBar');
    },
  },
};
</script>

<style lang="scss">
.info-sidebar {
  position: fixed;
  right: 0;
  top: 64px;
  height: calc(100vh - 64px);
  // min-width: 50%;
  min-width: 705px;
  max-width: 1005px;
  background: #fff;
  z-index: 10;
  box-shadow: 0px 10px 90px rgba(52, 45, 71, 0.2);
}

.info-sidebar__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-sidebar__close {
  position: absolute;
  left: 0px;
  top: 10px;
  z-index: 1;
}

.info-sidebar__content {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  width: 100%;
  padding: 34px 65px 18px 96px;
  position: relative;
  min-height: 0px;
}

.info-sidebar__content-scroll {
  height: 100%;
  overflow-y: auto;
  & > div {
    min-height: 100%;
  }
  .org-info {
    &--has-btns {
      margin-bottom: 96px;
    }
  }
  .org-info__btns {
    position: absolute;
    bottom: 18px;
    left: 96px;
    display: flex;
    gap: 60px;
    .v-btn--flat {
      background-color: $background-lightest;
    }
  }
}

.info-sidebar__footer {
  width: 100%;
  padding: 16px 96px;
  border-top: 1px solid #eceff6;
  min-height: 81px;
  flex-shrink: 0;
}

.info-sidebar__menu {
  position: absolute;
  right: 7px;
  top: 5px;
  z-index: 1;
}
</style>
