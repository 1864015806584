export default {
  props: {
    fullWidth: Boolean,
    attach: {
      type: Boolean,
      default: () => true,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
    value: [String, Number],
    label: String,
    maxDate: String,
    maxWidth: {
      type: Number,
      default: 200,
    },
    maxMenuWidth: {
      type: Number,
      default: 290,
    },
    minDate: String,
    placeholder: String,
    required: Boolean,
    rounded: Boolean,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    customStyle() {
      return this.fullWidth ? 'width: 100%;' : `max-width: ${this.maxWidth}px`;
    },
  },
  methods: {
    handleInput(value) {
      this.open = false;
      this.$emit('input', value);
    },
  },
};
