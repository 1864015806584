import { NAGHA_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const INCIDENT_FIELD_ALIASES = NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT;

export default () => ({
  // aliases for usage in form templates
  INCIDENT_FIELD_ALIASES: {
    type: Object,
    default: () => INCIDENT_FIELD_ALIASES,
  },
  [INCIDENT_FIELD_ALIASES.INCIDENT_DATE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_TIME]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT_OTHER]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_ACCIDENT_OCCUR]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME_AND_TYPE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_DESCRIPTION]: String,
  [INCIDENT_FIELD_ALIASES.INCEDENT_NAME_OF_TEAM]: String,
  [INCIDENT_FIELD_ALIASES.INCEDENT_NAME_OF_LEAGUE]: String,
  [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_CHECKBOX1]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_CHECKBOX2]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_SIGNATURE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_RESULT_IN_DEATH]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_NOTE]: String,
});
