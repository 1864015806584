// eslint-disable-next-line import/no-cycle
import store from '@/store';
import isValidString from '@/utils/helpers/string/isValidString';

export default address => {
  let output = '';
  if (address instanceof Object) {
    if (isValidString(address.streetAddress)) {
      output += address.streetAddress;
    }
    if (isValidString(address.city)) {
      output += `${isValidString(output) ? ', ' : ' '}${address.city}`;
    }
    if (isValidString(address.state)) {
      output += `${isValidString(output) ? ', ' : ' '}${store.getters['app/statesDictionary'][
        address.state
      ] || address.state}`;
    }
    if (isValidString(address.country)) {
      output += `${isValidString(output) ? ', ' : ' '}${store.getters['app/countriesDictionary'][
        address.country
      ] || address.country}`;
    }
    if (isValidString(address.zip)) {
      output += `${isValidString(output) ? ', ' : ' '}${address.zip}`;
    }
  }
  return output;
};
