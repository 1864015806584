import { CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const INCIDENT_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.INCIDENT;

export default () => ({
  // aliases for usage in form templates
  INCIDENT_FIELD_ALIASES: {
    type: Object,
    default: () => INCIDENT_FIELD_ALIASES,
  },
  loadingFiles: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_TPA_PROCESSING]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT]: [String, Object, Boolean],
  [INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT_TYPE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_DATE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_DESCRIPTION]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_TYPE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_TYPE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_OTHER_TYPE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_ONSITE_CARE_RECEIVED]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_OTHER_CONTACT_TYPE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_PLAYING_SURFACE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_SEVERITY]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_TAKEN_TO_HOSPITAL_BY_AMBULANCE]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_TIME]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_NOTE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES]: [Array, FileList],
});
