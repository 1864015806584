import { mapActions } from 'vuex';
import isValidString from '@/utils/helpers/string/isValidString';

export default {
  data() {
    return {
      claimEditPageParams: {
        loading: false,
        ready: false,
        stateAdmins: [],
        complianceWarning:
          'It is important that all information requested on this claim form be provided. Omissions of information will cause delay in claim processing.',
        formPurpose: 'incident report',
        organizationId: '',
        organizationLogo: '',
        organizationName: '',
        entity: null,
      },
    };
  },
  computed: {
    claimEditPageTitle() {
      return `${this.claimEditPageParams.organizationName} ${this.claimEditPageParams.formPurpose}`;
    },
    claimIsOlympic() {
      return Boolean(
        this.claimEditPageParams.entity instanceof Object && this.claimEditPageParams.entity.olympic
      );
    },
    claimIsUsasa() {
      return Boolean(
        this.claimEditPageParams.entity instanceof Object && this.claimEditPageParams.entity.usasa
      );
    },
  },
  methods: {
    ...mapActions('claims', ['fetchClaimById']),
    populateClaimEditPageParamsFromClaim(claim) {
      if (claim instanceof Object) {
        this.claimEditPageParams.entity = Object.freeze(claim);
        const {
          sportsUnion: { id, logo, name, stateAdmins },
        } = claim;
        if (isValidString(id)) {
          this.claimEditPageParams.organizationId = id;
        }
        if (stateAdmins && !!stateAdmins.length) {
          this.claimEditPageParams.stateAdmins = stateAdmins;
        }
        if (isValidString(logo)) {
          this.claimEditPageParams.organizationLogo = logo;
        }
        if (isValidString(name)) {
          this.claimEditPageParams.organizationName = name;
        }
      }
    },
    async initClaimEditPageParams() {
      try {
        const { id } = this.$route.params;
        if (!isValidString(id)) {
          throw new Error('No "id" is present in the url');
        }
        this.claimEditPageParams.loading = true;
        const {
          data: { claim },
        } = await this.fetchClaimById(id);
        this.populateClaimEditPageParamsFromClaim(claim);
        this.claimEditPageParams.loading = false;
        this.claimEditPageParams.ready = true;
        return !!(claim instanceof Object);
      } catch (error) {
        this.claimEditPageParams.loading = false;
        this.claimEditPageParams.ready = false;
        await this.$dialog.error(error);
        this.$router.push({ name: 'notFound' });
        return false;
      }
    },
  },
};
