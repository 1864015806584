export default (items, sortField = 'text', additionalFormatter = arr => arr) => {
  if (items instanceof Array && items.length) {
    const copiedItems = [...items];
    return additionalFormatter(
      copiedItems.sort((a, b) => {
        if (a && a instanceof Object && b && b instanceof Object) {
          if (a[sortField] < b[sortField]) {
            return -1;
          }
          if (a[sortField] > b[sortField]) {
            return 1;
          }
        }
        return 0;
      })
    );
  }
  return additionalFormatter(items);
};
