import { CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const COACH_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.COACH;

export default () => ({
  // aliases for usage in form templates
  COACH_FIELD_ALIASES: {
    type: Object,
    default: () => COACH_FIELD_ALIASES,
  },
  [COACH_FIELD_ALIASES.COACH_INJURED_TREATED_BY_OFFICIALS]: Boolean,
  [COACH_FIELD_ALIASES.COACH_INJURED_CLUB_NAME]: String,
  [COACH_FIELD_ALIASES.COACH_EMAIL]: String,
  [COACH_FIELD_ALIASES.COACH_FIRST_NAME]: String,
  [COACH_FIELD_ALIASES.COACH_INCIDENT_TERRITORY]: String,
  [COACH_FIELD_ALIASES.COACH_LAST_NAME]: String,
  [COACH_FIELD_ALIASES.COACH_PHONE]: String,
  [COACH_FIELD_ALIASES.COACH_SIGNATURE]: String,
});
