<template>
  <v-app
    :class="{
      'app--scroll-disabled': scrollDisabled,
      ...appClasses,
      showCaptchaBadge: showCaptchaBadge,
    }"
    :style="globalCssVars"
    class="app"
    id="claims-app-root"
  >
    <component :is="layout"></component>
    <PhGlobalDialog />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import layouts from '@/layouts';
import PhGlobalDialog from '@/components/modals/PhGlobalDialog';

export default {
  name: 'App',
  components: {
    PhGlobalDialog,
  },
  computed: {
    ...mapGetters('app', [
      'accentGradientStyle',
      'currentTheme',
      'currentThemeIsDark',
      'scrollDisabled',
    ]),
    showCaptchaBadge() {
      return this.$route.meta.showCaptchaBadge;
    },
    appClasses() {
      return this.$route.meta.appClasses
        ? Object.values(this.$route.meta.appClasses).reduce(
            (acc, val) => ({
              ...acc,
              [val]: true,
            }),
            {}
          )
        : {};
    },
    globalCssVars() {
      return {
        '--accent-gradient': this.accentGradientStyle,
      };
    },
    layout() {
      return layouts[this.$route.meta.layout];
    },
  },
  watch: {
    currentTheme(newTheme, oldTheme) {
      try {
        if (JSON.stringify(newTheme) !== JSON.stringify(oldTheme)) {
          this.setVuetifyTheme(newTheme, this.currentThemeIsDark);
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  methods: {
    ...mapActions('captcha', ['mountCaptcha']),
    setVuetifyTheme(theme, dark = false) {
      if (dark) {
        this.$vuetify.theme.themes.dark = theme;
        this.$vuetify.theme.isDark = true;
      } else {
        this.$vuetify.theme.themes.light = theme;
        this.$vuetify.theme.isDark = false;
      }
    },
  },
  mounted() {
    this.mountCaptcha();
  },
};
</script>

<style lang="scss" scoped>
#claims-app-root.app {
  &--scroll-disabled {
    overflow: hidden;
    max-height: stretch;
  }
}
</style>
