<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    :z-index="11"
    offset-y
    absolute
  >
    <template v-slot:activator="{ on }">
      <DotMenu v-if="menuNeeded" v-on="on" />
    </template>

    <v-card>
      <v-divider></v-divider>

      <v-list>
        <v-list-item v-if="editEnabled" @click="handleEdit">
          <v-list-item-title>
            <strong>
              {{ editLinkContent }}
            </strong>
          </v-list-item-title>
          <v-list-item-action>
            <SvgIcon icon="pencil" />
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="SAEnabled" @click="handleSA">
          <v-list-item-title>
            <strong>
              {{ SALinkContent }}
            </strong>
          </v-list-item-title>
          <v-list-item-action>
            <SvgIcon icon="pencil" />
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="BAEnabled" @click="handleBA">
          <v-list-item-title>
            <strong>
              {{ BALinkContent }}
            </strong>
          </v-list-item-title>
          <v-list-item-action>
            <SvgIcon icon="pencil" />
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="BAEnabled" @click="handlePI">
          <v-list-item-title>
            <strong>
              {{ PILinkContent }}
            </strong>
          </v-list-item-title>
          <v-list-item-action>
            <SvgIcon icon="pencil" />
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="deleteEnabled" @click="handleDelete">
          <v-list-item-title>
            <strong>
              {{ deleteLinkContent }}
            </strong>
          </v-list-item-title>
          <v-list-item-action>
            <SvgIcon icon="delete-icon" />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import { USER_ROLES } from '@/utils/constants';
import DotMenu from '@/components/buttons/DotMenu';

export default {
  name: 'SidebarMenu',
  components: {
    DotMenu,
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapGetters('app', ['infoSidebarData', 'sidebarContent']),
    menuNeeded() {
      return this.editEnabled;
    },
    editLinkContent() {
      const contentType = this.infoSidebarData.content;
      switch (contentType) {
        case 'fnol':
          return 'Edit FNOL';
        case 'claim':
          return 'Edit Claim';
        case 'organization':
        case 'brokerOrganization':
          return 'Edit Org';
        default:
          return 'Edit';
      }
    },
    SALinkContent() {
      return `State Admin's`;
    },
    BALinkContent() {
      return `Admin's`;
    },
    PILinkContent() {
      return `Policy Information`;
    },
    deleteLinkContent() {
      const contentType = this.infoSidebarData.content;
      switch (contentType) {
        case 'organization':
          return 'Delete Org';
        case 'brokerOrganization':
          return 'Delete Org';
        default:
          return '';
      }
    },
    editEnabled() {
      const contentType = this.infoSidebarData.content;
      switch (contentType) {
        case 'fnol':
        case 'brokerOrganization':
          return true;
        case 'organization':
          return this.$userIs(USER_ROLES.SUPER_ADMIN);
        default:
          return false;
      }
    },
    SAEnabled() {
      const contentType = this.infoSidebarData.content;
      switch (contentType) {
        case 'organization':
          return this.$userIs(USER_ROLES.SUPER_ADMIN) && this.sidebarContent.form === 'USASA';
        default:
          return false;
      }
    },
    BAEnabled() {
      const contentType = this.infoSidebarData.content;
      switch (contentType) {
        case 'brokerOrganization':
          return this.$userIs(USER_ROLES.SUPER_ADMIN);
        default:
          return false;
      }
    },
    deleteEnabled() {
      const contentType = this.infoSidebarData.content;
      switch (contentType) {
        case 'brokerOrganization':
          return this.$userIs(USER_ROLES.SUPER_ADMIN);
        case 'organization':
          return this.$userIs(USER_ROLES.SUPER_ADMIN);
        default:
          return false;
      }
    },
  },
  methods: {
    handleEdit() {
      this.$emit('sidebarEdit', this.infoSidebarData.content);
    },
    handleSA() {
      this.$emit('sidebarSA', this.infoSidebarData.content);
    },
    handleBA() {
      this.$emit('sidebarBA', this.infoSidebarData.content);
    },
    handlePI() {
      this.$emit('sidebarPI', this.infoSidebarData.content);
    },
    handleDelete() {
      this.$emit('sidebarDelete');
    },
  },
};
</script>
