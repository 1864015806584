import { mapActions } from 'vuex';
import { getRemoteFilePath } from '@/utils/helpers/string';
import isValidString from '@/utils/helpers/string/isValidString';

export default {
  data() {
    return {
      organizationPageParams: {
        loading: false,
        ready: false,
        olympic: false,
        complianceWarning:
          'It is important that all information requested on this claim form be provided. Omissions of information will cause delay in claim processing.',
        formPurpose: 'incident report',
        organizationId: '',
        organizationName: 'USA Rugby',
        logoUrl: '/img/logo/us-rugby.png',
        theme: null,
        form: 'DEFAULT',
      },
    };
  },
  computed: {
    pageTitle() {
      if (
        typeof this.organizationPageParams.formPurpose === 'string' &&
        this.organizationPageParams.formPurpose.length &&
        typeof this.organizationPageParams.organizationName === 'string' &&
        this.organizationPageParams.organizationName.length
      ) {
        return `${this.organizationPageParams.organizationName} ${this.organizationPageParams.formPurpose}`;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('app', ['setCustomTheme', 'resetCustomTheme']),
    ...mapActions('organizations', ['fetchSportsUnionByUrl']),
    populateClaimSubmitPageParamsFromOrganization({
      id,
      name,
      landingPageSettings,
      logo,
      olympic,
      form,
      formIsInjuryOnly,
      stateAdmins,
      address,
      broker,
    }) {
      const US_RUGBY_THEME = {
        accent: '#C41230',
        accentGradientFrom: '#881919',
        accentGradientTo: '#DF4760',
      };
      const USASA_THEME = {
        accent: '#EC2024',
        accentGradientFrom: '#EC2024',
        accentGradientTo: '#EC2024',
      };
      this.organizationPageParams.broker = broker;
      if (isValidString(name)) {
        this.organizationPageParams.organizationName = name;
      }
      this.organizationPageParams.address = {};
      if (address && isValidString(address.state)) {
        this.organizationPageParams.address.state = address.state;
      }
      if (stateAdmins && !!stateAdmins.length) {
        this.organizationPageParams.stateAdmins = stateAdmins;
      }
      if (isValidString(id)) {
        this.organizationPageParams.organizationId = id;
        Object.assign(this.organizationPageParams, {
          olympic,
          form,
          formIsInjuryOnly,
        });
      }
      if (logo instanceof Object && isValidString(logo.path)) {
        this.organizationPageParams.logoUrl = getRemoteFilePath(logo.path);
      }
      if (
        landingPageSettings &&
        landingPageSettings.logo instanceof Object &&
        isValidString(landingPageSettings.logo.path)
      ) {
        this.organizationPageParams.logoUrl = getRemoteFilePath(landingPageSettings.logo.path);
      }
      if (form === 'USASA') {
        this.organizationPageParams.theme = USASA_THEME;
      } else {
        this.organizationPageParams.theme = US_RUGBY_THEME;
      }
      this.setCustomTheme({ theme: this.organizationPageParams.theme });
      if (form === 'USASA') {
        this.organizationPageParams.formPurpose = ' ';
        // this.organizationPageParams.complianceWarning = `
        //   <p>Another FNOL/claim form should be integrated for a new sports union org.</p>
        //   <p>Below you can find the list of fields.</p>
        //   <p>This form is filed by an adult athlete, which means that the injured person is the same as Claimant.</p>
        // `;
      }
    },
    populateClaimParamsFromOrganizationImmediatly(payload) {
      this.populateClaimSubmitPageParamsFromOrganization(payload);
      this.organizationPageParams.loading = false;
      this.organizationPageParams.ready = true;
    },
    async initOrganizationPageParams(type = 'sportsUnion') {
      try {
        const { slug } = this.$route.params;
        if (!isValidString(slug)) {
          throw new Error('No slug is present in the url');
        }
        this.organizationPageParams.loading = true;
        let initialized = false;
        if (type === 'sportsUnion') {
          initialized = await this.initOrganizationPageParamsFromSportsUnion(slug);
        }
        this.organizationPageParams.loading = false;
        this.organizationPageParams.ready = initialized;
        if (!initialized) {
          this.$router.push({ name: 'notFound' });
        }
        return initialized;
      } catch (error) {
        this.organizationPageParams.loading = false;
        this.organizationPageParams.ready = false;
        await this.$dialog.error(error);
        this.$router.push({ name: 'notFound' });
        return false;
      }
    },
    async initOrganizationPageParamsFromSportsUnion(url) {
      try {
        const { data } = await this.fetchSportsUnionByUrl(url);
        const dataIsValid = !!(data instanceof Object && data.sportsUnionByUrl instanceof Object);
        if (dataIsValid) {
          this.populateClaimSubmitPageParamsFromOrganization(data.sportsUnionByUrl);
        }
        return dataIsValid;
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    },
  },
  beforeDestroy() {
    if (this.organizationPageParams.theme) {
      this.resetCustomTheme();
    }
  },
};
