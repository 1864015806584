const CREATE_CLAIM_INPUT = {
  sportsUnionId: 'ID!',
  claimantFirstName: 'String!',
  claimantLastName: 'String!',
  claimantDOB: 'String!',
  claimantGender: 'GenderType!',
  claimantAddress: 'ClaimantAddressInput!',
  claimantHomePhone: 'String',
  claimantWorkPhone: 'String',
  claimantEmail: 'String!',
  claimantStatus: 'ClaimantStatus!',
  claimantCoachPlayerType: 'ClaimantCoachPlayerType',
  haveYouAlreadySeenAProvider: 'Boolean',
  providerInformation: 'ProviderInformationInput',
  isInjuredPersonAMedicareMedicaidBeneficiary: 'Boolean!',
  SSNorHIDNumber: 'String',
  areInjuredPersonCurrentlyEnrolledAccidentPlan: 'Boolean!',
  healthInsurancePlan: '[HealthInsurancePlanInput!]',
  employerOrSpousesEmployerOrBursarsOfficeEmailAddress: 'String',
  incidentDate: 'String',
  incidentTime: 'String',
  incidentLocation: 'String',
  injuredBodyPart: 'InjuredBodyPartType',
  injuredBodySide: 'InjuredBodySideType',
  injuryType: 'InjuryType',
  injuryOccurredAt: 'InjuryOccurredAt!',
  injuryOccurredAtOther: 'String',
  nameAndTypeOfEvent: 'String!',
  injuryOccurredOn: 'InjuryOccurredOn',
  injuryDescription: 'String',
  nameOfTeam: 'String!',
  stateAssociationNationwideAffiliate: 'String!',
  region: 'String!',
  usasa_claimantCheckbox1: 'Boolean',
  claimantSignature: 'String!',
  coachEmail: 'String!',
  olympic_claimantParentsPhone: 'String',
  coachFullName: 'String!',
  coachFirstName: 'String!',
  coachLastName: 'String!',
  stateAdmin: 'ID!',
  note: 'String',
};

export default form => {
  if (!(form instanceof Object)) {
    throw new Error('The provided form argument is not a valid Object');
  }
  return Object.entries(form).reduce((acc, [field, value]) => {
    if (CREATE_CLAIM_INPUT[field]) {
      acc[field] = value;
    }
    return acc;
  }, {});
};
