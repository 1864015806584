import { CRAWFORD_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const PROPERTY_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.PROPERTY;

export default () => ({
  // aliases for usage in form templates
  PROPERTY_FIELD_ALIASES: {
    type: Object,
    default: () => PROPERTY_FIELD_ALIASES,
  },
  [PROPERTY_FIELD_ALIASES.PROPERTY_DESCRIPTION]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_DAMAGE_DESCRIPTION]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_TYPE]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_COMPANY_NAME]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_PERSON_FIRST_NAME]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_PERSON_LAST_NAME]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_LINE1]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_LINE2]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_CITY]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_STATE]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_ZIP]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_COUNTRY]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_WORK_PHONE]: String,
  [PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_HOME_PHONE]: String,
});
