const reverseParams = payload =>
  Object.entries(payload).reduce(
    (acc, { 0: key, 1: value }) => ({
      ...acc,
      [value]: key,
    }),
    {}
  );

export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_FORMAT = 'DD MMM YYYY';

// dynamic info screens start

export const INFO_SCREEN_TYPES = {
  THANK_YOU: 'THANK_YOU',
};

export const INFO_SCREEN_TYPES_PARAMS = {
  [INFO_SCREEN_TYPES.THANK_YOU]: 'thanks',
};

export const INFO_SCREEN_TYPES_PARAMS_ALIAS = reverseParams(INFO_SCREEN_TYPES_PARAMS);

export const THANK_YOU_TYPES = {
  COACH_SUBMIT: 'COACH_SUBMIT',
  FNOL_SUBMIT: 'FNOL_SUBMIT',
};

export const THANK_YOU_TYPES_PARAMS = {
  [THANK_YOU_TYPES.COACH_SUBMIT]: 'coach',
  [THANK_YOU_TYPES.FNOL_SUBMIT]: 'fnol',
};

export const SPORTS_UNION_TYPES = {
  DEFAULT: 'DEFAULT',
  OLYMPIC: 'OLYMPIC',
  USASA: 'USASA',
  FYSA: 'FYSA',
  NAGHA: 'NAGHA',
  COLORADOMTB: 'COLORADOMTB',
  CRAWFORD: 'CRAWFORD',
};

export const THANK_YOU_TYPES_PARAMS_ALIAS = reverseParams(THANK_YOU_TYPES_PARAMS);

// dynamic onfo screens end

export const ANALYTIC_EVENTS = {
  CLAIMANT_OPEN_FNOL: 'CLAIMANT_OPEN_FNOL',
  CLAIMANT_SUBMIT_FNOL: 'CLAIMANT_SUBMIT_FNOL',
  COACH_OPEN_FNOL: 'COACH_OPEN_FNOL',
  COACH_ACCEPT_FNOL: 'COACH_ACCEPT_FNOL',
  COACH_DECLINING_FNOL: 'COACH_DECLINING_FNOL',
  USER_SIGN_IN_SUCCESFUL: 'USER_SIGN_IN_SUCCESFUL',
  USER_SIGN_IN_FAILED: 'USER_SIGN_IN_FAILED',
  USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',
  USER_RESTORE_PASSWORD: 'USER_RESTORE_PASSWORD',
  USER_VIEW_FNOL: 'USER_VIEW_FNOL',
  USER_VIEW_CLAIM: 'USER_VIEW_CLAIM',
  USER_UPDATE_FNOL: 'USER_UPDATE_FNOL',
  USER_CHANGE_FNOL_STATUS: 'USER_CHANGE_FNOL_STATUS',
  USER_CHANGE_CLAIM_STATUS: 'USER_CHANGE_CLAIM_STATUS',
  USER_UPDATE_PROFILE: 'USER_UPDATE_PROFILE',
};

export const OLYMPIC_TEAM_NAMES = {
  M_NATIONAL_15S: 'M_NATIONAL_15S',
  W_NATIONAL_15S: 'W_NATIONAL_15S',
  M_NATIONAL_7S: 'M_NATIONAL_7S',
  W_NATIONAL_7S: 'W_NATIONAL_7S',
};
export const OLYMPIC_TEAM_NAMES_NAMES = {
  [OLYMPIC_TEAM_NAMES.M_NATIONAL_15S]: 'Men’s National 15s',
  [OLYMPIC_TEAM_NAMES.W_NATIONAL_15S]: 'Women’s National 15s',
  [OLYMPIC_TEAM_NAMES.M_NATIONAL_7S]: 'Men’s National 7s',
  [OLYMPIC_TEAM_NAMES.W_NATIONAL_7S]: 'Women’s National 7s',
};

export const PLAYING_SURFACES = {
  MULTI_PURPOSE: 'MULTI_PURPOSE',
  FIELD_TURF: 'FIELD_TURF',
  ASTROTURF: 'ASTROTURF',
  GRASS: 'GRASS',
  SNOW: 'SNOW',
  ICE: 'ICE',
  HARDWOOD: 'HARDWOOD',
  CLAY: 'CLAY',
  PAVEMENT: 'PAVEMENT',
  GRANITE: 'GRANITE',
  GRAVEL: 'GRAVEL',
  DIRT: 'DIRT',
  MAT_EIGHT_INCH: 'MAT_EIGHT_INCH',
  MAT_FOUR_INCH: 'MAT_FOUR_INCH',
  STRING_MAT: 'STRING_MAT',
  PANEL_MAT: 'PANEL_MAT',
  RESIN: 'RESIN',
  WEDGES: 'WEDGES',
  OCTAGONS: 'OCTAGONS',
  PIT: 'PIT',
  MAT: 'MAT',
  WATER: 'WATER',
  PLATFORM: 'PLATFORM',
};
export const PLAYING_SURFACES_NAMES = {
  [PLAYING_SURFACES.MULTI_PURPOSE]: 'Multi purpose',
  [PLAYING_SURFACES.FIELD_TURF]: 'Field turf',
  [PLAYING_SURFACES.ASTROTURF]: 'Astroturf',
  [PLAYING_SURFACES.GRASS]: 'Grass',
  [PLAYING_SURFACES.SNOW]: 'Snow',
  [PLAYING_SURFACES.ICE]: 'Ice',
  [PLAYING_SURFACES.HARDWOOD]: 'Hardwood',
  [PLAYING_SURFACES.CLAY]: 'Clay',
  [PLAYING_SURFACES.PAVEMENT]: 'Pavement',
  [PLAYING_SURFACES.GRANITE]: 'Granite',
  [PLAYING_SURFACES.GRAVEL]: 'Gravel',
  [PLAYING_SURFACES.DIRT]: 'Dirt',
  [PLAYING_SURFACES.MAT_EIGHT_INCH]: 'Mat 8"',
  [PLAYING_SURFACES.MAT_FOUR_INCH]: 'Mat 4"',
  [PLAYING_SURFACES.STRING_MAT]: 'Sting mat',
  [PLAYING_SURFACES.PANEL_MAT]: 'Panel mat',
  [PLAYING_SURFACES.RESIN]: 'Resin',
  [PLAYING_SURFACES.WEDGES]: 'Wedges',
  [PLAYING_SURFACES.OCTAGONS]: 'Octagons',
  [PLAYING_SURFACES.PIT]: 'Pit',
  [PLAYING_SURFACES.MAT]: 'Mat',
  [PLAYING_SURFACES.WATER]: 'Water',
  [PLAYING_SURFACES.PLATFORM]: 'Platform',
};
export const CONDITIONS_OF_INJURED_TEETH = {
  NATURAL: 'WHOLE_SOUND_NATURAL',
  FILLED: 'FILLED',
  CAPPED: 'CAPPED',
  ARTIFICIAL: 'ARTIFICIAL',
};
export const CONDITIONS_OF_INJURED_TEETH_NAMES = {
  [CONDITIONS_OF_INJURED_TEETH.NATURAL]: 'Whole, Sound, and Natural',
  [CONDITIONS_OF_INJURED_TEETH.FILLED]: 'Filled',
  [CONDITIONS_OF_INJURED_TEETH.CAPPED]: 'Capped',
  [CONDITIONS_OF_INJURED_TEETH.ARTIFICIAL]: 'Artificial',
};
export const BODY_PARTS = {
  HEAD: 'HEAD',
  FACE: 'FACE',
  NECK_OT_SPINE: 'NECK_OT_SPINE',
  HIP_OR_PELVIS: 'HIP_OR_PELVIS',
  KNEE: 'KNEE',
  ANKLE: 'ANKLE',
  SHOULDER_OR_PROXIMAL_HUMERUS: 'SHOULDER_OR_PROXIMAL_HUMERUS',
  ELBOW: 'ELBOW',
  WRIST: 'WRIST',
  QUADRICEPS: 'QUADRICEPS',
  HAMSTRING_OR_THIGH: 'HAMSTRING_OR_THIGH',
  LOWER_LEG_OR_CALF: 'LOWER_LEG_OR_CALF',
  CHEST: 'CHEST',
  BACK: 'BACK',
  TRICEPS: 'TRICEPS',
  BICEPS: 'BICEPS',
  FOREARMS: 'FOREARMS',
  TRAPEZIUS: 'TRAPEZIUS',
  ABDOMINALS: 'ABDOMINALS',
  OTHER: 'OTHER',
  FOOT: 'FOOT',
  HAND: 'HAND',
  SHIN: 'SHIN',
  CERVICAL: 'CERVICAL',
};

export const BODY_PART_NAMES = {
  [BODY_PARTS.HEAD]: 'Head',
  [BODY_PARTS.FACE]: 'Face',
  [BODY_PARTS.NECK_OT_SPINE]: 'Neck / Spine',
  [BODY_PARTS.HIP_OR_PELVIS]: 'Hip / Pelvis',
  [BODY_PARTS.KNEE]: 'Knee',
  [BODY_PARTS.ANKLE]: 'Ankle',
  [BODY_PARTS.SHOULDER_OR_PROXIMAL_HUMERUS]: 'Shoulder / Proximal Humerus',
  [BODY_PARTS.ELBOW]: 'Elbow',
  [BODY_PARTS.WRIST]: 'Wrist',
  [BODY_PARTS.QUADRICEPS]: 'Quadriceps',
  [BODY_PARTS.HAMSTRING_OR_THIGH]: 'Hamstring / Thigh',
  [BODY_PARTS.LOWER_LEG_OR_CALF]: 'Lower leg / Calf',
  [BODY_PARTS.CHEST]: 'Chest',
  [BODY_PARTS.BACK]: 'Back',
  [BODY_PARTS.TRICEPS]: 'Triceps',
  [BODY_PARTS.BICEPS]: 'Biceps',
  [BODY_PARTS.FOREARMS]: 'Forearms',
  [BODY_PARTS.TRAPEZIUS]: 'Trapezius',
  [BODY_PARTS.ABDOMINALS]: 'Abdominals',
  [BODY_PARTS.OTHER]: 'Other',
  [BODY_PARTS.FOOT]: 'Foot',
  [BODY_PARTS.HAND]: 'Hand',
  [BODY_PARTS.SHIN]: 'Shin',
  [BODY_PARTS.CERVICAL]: 'Cervical',
};

export const BODY_SIDES = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  NOT_ON_BODY: 'NOT_ON_BODY',
  MIDLINE: 'MIDLINE',
  BILATERAL: 'BILATERAL',
};

export const BODY_SIDE_NAMES = {
  [BODY_SIDES.LEFT]: 'Left',
  [BODY_SIDES.RIGHT]: 'Right',
  [BODY_SIDES.NOT_ON_BODY]: 'Not on body',
  [BODY_SIDES.MIDLINE]: 'Midline',
  [BODY_SIDES.BILATERAL]: 'Bilateral',
};

export const CLAIM_STATUSES = {
  DECLINED: 'DECLINED',
  PENDING: 'PENDING',
  FULLY_PAID: 'FULLY_PAID',
  PARTIALLY_PAID: 'PARTIALLY_PAID',
  CLAIM_SENT_TO_TPA: 'CLAIM_SENT_TO_TPA',
};

export const CLAIM_STATUS_NAMES = {
  [CLAIM_STATUSES.DECLINED]: 'Declined',
  [CLAIM_STATUSES.PENDING]: 'Pending',
  [CLAIM_STATUSES.FULLY_PAID]: 'Paid',
  [CLAIM_STATUSES.PARTIALLY_PAID]: 'Partially paid',
  [CLAIM_STATUSES.CLAIM_SENT_TO_TPA]: 'Claim sent to TPA',
};

export const INJURY_CONTACT_TYPES = {
  TACKLE_DEFENDER: 'TACKLE_DEFENDER',
  TACKLE_ATTACKER: 'TACKLE_ATTACKER',
  RUCK: 'RUCK',
  SCRUM: 'SCRUM',
  MAUL: 'MAUL',
  OTHER: 'OTHER',
};

export const INJURY_CONTACT_TYPE_NAMES = {
  [INJURY_CONTACT_TYPES.TACKLE_DEFENDER]: 'Tackle (defender)',
  [INJURY_CONTACT_TYPES.TACKLE_ATTACKER]: 'Tackle (attacker)',
  [INJURY_CONTACT_TYPES.RUCK]: 'Ruck',
  [INJURY_CONTACT_TYPES.SCRUM]: 'Scrum',
  [INJURY_CONTACT_TYPES.MAUL]: 'Maul',
  [INJURY_CONTACT_TYPES.OTHER]: 'Other',
};

export const INJURY_TYPES = {
  SPRAIN: 'SPRAIN',
  STRAIN: 'STRAIN',
  FRACTURE: 'FRACTURE',
  CONTUSION: 'CONTUSION',
  LACERATION: 'LACERATION',
  CONCUSSION: 'CONCUSSION',
  OVERUSE: 'OVERUSE',
  OTHER: 'OTHER',
  DISLOCATION: 'DISLOCATION',
  SUBLUXATION: 'SUBLUXATION',
  CONDITIONS: 'CONDITIONS',
  ACL_TEAR: 'ACL_TEAR',
  ASTHMA: 'ASTHMA',
  ALLERGIC_REACTION_OR_INSECT_BITE: 'ALLERGIC_REACTION_OR_INSECT_BITE',
  BONE_INJURY: 'BONE_INJURY',
  BURSITIS: 'BURSITIS',
  CARTILAGE_INJURY: 'CARTILAGE_INJURY',
  COMPARTMENT_INJURY: 'COMPARTMENT_INJURY',
  CYST: 'CYST',
  FRACTURE2: 'FRACTURE2',
  HEADACHES: 'HEADACHES',
  HEAT_ILLNESS: 'HEAT_ILLNESS',
  ILLNESS: 'ILLNESS',
  INJURY_CLEARANCE: 'INJURY_CLEARANCE',
  INFECTION: 'INFECTION',
  INSTABILITY: 'INSTABILITY',
  LABRAL_TEAR: 'LABRAL_TEAR',
  MAINTENANCE: 'MAINTENANCE',
  NEUROLOGICAL_CONDITION: 'NEUROLOGICAL_CONDITION',
  OTHER_MEDICAL: 'OTHER_MEDICAL',
  PATELLOFEMORAL_SYNDROME: 'PATELLOFEMORAL_SYNDROME',
  PLANTAR_FASCIITIS: 'PLANTAR_FASCIITIS',
  SEIZURE: 'SEIZURE',
  SPRAIN2: 'SPRAIN2',
  TENDON_INJURY: 'TENDON_INJURY',
  WOUND_DERMAL: 'WOUND_DERMAL',
  DEFORMITY: 'DEFORMITY',
  INSTABILITY2: 'INSTABILITY2',
  TENDON_INJURY2: 'TENDON_INJURY2',
  DISORDER: 'DISORDER',
};

export const INJURY_SEVERITY_TYPES = {
  REPORT_ONLY: 'REPORT_ONLY',
  MINOR: 'MINOR',
  SERIOUS: 'SERIOUS',
  CRITICAL: 'CRITICAL',
  FATALITY: 'FATALITY',
};

export const INJURY_SEVERITY_TYPE_NAMES = {
  [INJURY_SEVERITY_TYPES.REPORT_ONLY]: 'Report only',
  [INJURY_SEVERITY_TYPES.MINOR]: 'Minor',
  [INJURY_SEVERITY_TYPES.SERIOUS]: 'Serious',
  [INJURY_SEVERITY_TYPES.CRITICAL]: 'Critical',
  [INJURY_SEVERITY_TYPES.FATALITY]: 'Fatality',
};

export const INJURY_TYPE_NAMES = {
  [INJURY_TYPES.SPRAIN]: 'Sprain',
  [INJURY_TYPES.STRAIN]: 'Strain',
  [INJURY_TYPES.FRACTURE]: 'Fracture',
  [INJURY_TYPES.CONTUSION]: 'Contusion',
  [INJURY_TYPES.LACERATION]: 'Laceration',
  [INJURY_TYPES.CONCUSSION]: 'Concussion',
  [INJURY_TYPES.OVERUSE]: 'Overuse',
  [INJURY_TYPES.OTHER]: 'Other',
  [INJURY_TYPES.DISLOCATION]: 'Dislocation',
  [INJURY_TYPES.SUBLUXATION]: 'Subluxation',
  [INJURY_TYPES.CONDITIONS]: 'Conditions',
  [INJURY_TYPES.ACL_TEAR]: 'ACL Tear',
  [INJURY_TYPES.ASTHMA]: 'Asthma - includes Airway Obstruction and any respiratory distress',
  [INJURY_TYPES.ALLERGIC_REACTION_OR_INSECT_BITE]: 'Allergic Reaction; Insect Bite',
  [INJURY_TYPES.BONE_INJURY]: 'Bone Injury - includes Stress Reaction and Osteochondral Injury',
  [INJURY_TYPES.BURSITIS]: 'Bursitis',
  [INJURY_TYPES.CARTILAGE_INJURY]: 'Cartilage Injury',
  [INJURY_TYPES.COMPARTMENT_INJURY]: 'Compartment Injury',
  [INJURY_TYPES.CYST]: 'Cyst',
  [INJURY_TYPES.FRACTURE2]:
    'Fracture - includes Growth Plate Fracture, Avulsion Fracture, and Stress Fracture',
  [INJURY_TYPES.HEADACHES]: 'Headaches',
  [INJURY_TYPES.HEAT_ILLNESS]: 'Heat Illness',
  [INJURY_TYPES.ILLNESS]: 'Illness',
  [INJURY_TYPES.INJURY_CLEARANCE]: 'Injury Clearance',
  [INJURY_TYPES.INFECTION]: 'Infection',
  [INJURY_TYPES.INSTABILITY]: 'Instability',
  [INJURY_TYPES.LABRAL_TEAR]: 'Labral Tear',
  [INJURY_TYPES.MAINTENANCE]: 'Maintenance',
  [INJURY_TYPES.NEUROLOGICAL_CONDITION]: 'Neurological Condition',
  [INJURY_TYPES.OTHER_MEDICAL]: 'Other Medical',
  [INJURY_TYPES.PATELLOFEMORAL_SYNDROME]: 'Patellofemoral Syndrome',
  [INJURY_TYPES.PLANTAR_FASCIITIS]: 'Plantar Fasciitis',
  [INJURY_TYPES.SEIZURE]: 'Seizure',
  [INJURY_TYPES.SPRAIN2]: 'Sprain - includes ligament injuries',
  [INJURY_TYPES.TENDON_INJURY]: 'Tendon Injury',
  [INJURY_TYPES.WOUND_DERMAL]:
    'Wound/Dermal - includes any Skin Injury, Lacerations, Burns, or Blisters',
  [INJURY_TYPES.DEFORMITY]: 'Deformity',
  [INJURY_TYPES.INSTABILITY2]: 'Instability - includes Subluxation and Chronic Instability',
  [INJURY_TYPES.TENDON_INJURY2]: 'Tendon Injury - includes Tendonitis',
  [INJURY_TYPES.DISORDER]: 'Disorder',
};

export const EVENT_TYPES = {
  MATCH_OR_TOURNAMENT: 'MATCH_OR_TOURNAMENT',
  ON_FIELD_PRACTICE: 'ON_FIELD_PRACTICE',
  OFF_FIELD_PRACTICE: 'OFF_FIELD_PRACTICE',
};

export const EVENT_TYPE_NAMES = {
  [EVENT_TYPES.MATCH_OR_TOURNAMENT]: 'Match/Tournament',
  [EVENT_TYPES.ON_FIELD_PRACTICE]: 'On-Field Practice',
  [EVENT_TYPES.OFF_FIELD_PRACTICE]: 'Off-Field Practice',
};

export const FNOL_STATUSES = {
  ACCEPTED: 'ACCEPTED',
  CONFIRMED: 'CONFIRMED',
  DECLINED: 'DECLINED',
  PENDING: 'PENDING',
};

export const FNOL_STATUS_NAMES = {
  [FNOL_STATUSES.CONFIRMED]: 'Confirmed',
  [FNOL_STATUSES.DECLINED]: 'Declined',
  [FNOL_STATUSES.PENDING]: 'Pending',
};

export const GENDERS = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
};

export const GENDER_NAMES = {
  [GENDERS.MALE]: 'Male',
  [GENDERS.FEMALE]: 'Female',
};

export const MEMBERSHIP_TYPES = {
  YOUTH: 'YOUTH',
  HS: 'HS',
  COLLEGES: 'COLLEGES',
  CLUB: 'CLUB',
  OTHER: 'OTHER',
};

export const MEMBERSHIP_TYPE_NAMES = {
  [MEMBERSHIP_TYPES.YOUTH]: 'Youth',
  [MEMBERSHIP_TYPES.HS]: 'HS',
  [MEMBERSHIP_TYPES.COLLEGES]: 'Colleges',
  [MEMBERSHIP_TYPES.CLUB]: 'Club',
  [MEMBERSHIP_TYPES.OTHER]: 'Other',
};

export const OLYMPIC_ORGANIZATION_ROLES = {
  ROOKIE: 'ROOKIE',
  YOUTH: 'YOUTH',
  HS: 'HS',
  COLLEGE: 'COLLEGE',
  SENIOR_CLUB: 'SENIOR_CLUB',
  TOUCH: 'TOUCH',
  REFEREE: 'REFEREE',
  COACH: 'COACH',
  ADMIN: 'ADMIN',
  ATHLETIC_TRAINER: 'ATHLETIC_TRAINER',
  COACH_MANAGER: 'COACH_MANAGER',
  DOCTOR: 'DOCTOR',
  ATHLETE: 'ATHLETE',
  OTHER: 'OTHER',
};

export const OLYMPIC_ORGANIZATION_ROLE_NAMES = {
  [OLYMPIC_ORGANIZATION_ROLES.ATHLETE]: 'Athlete',
  [OLYMPIC_ORGANIZATION_ROLES.COACH]: 'Coach',
  [OLYMPIC_ORGANIZATION_ROLES.OFFICIAL]: 'Official',
  [OLYMPIC_ORGANIZATION_ROLES.OTHER]: 'Other',
  [OLYMPIC_ORGANIZATION_ROLES.ATHLETIC_TRAINER]: 'Athletic trainer',
  [OLYMPIC_ORGANIZATION_ROLES.COACH_MANAGER]: 'Coach manager',
  [OLYMPIC_ORGANIZATION_ROLES.DOCTOR]: 'Doctor',
  [OLYMPIC_ORGANIZATION_ROLES.ROOKIE]: 'Rookie',
  [OLYMPIC_ORGANIZATION_ROLES.YOUTH]: 'Youth',
  [OLYMPIC_ORGANIZATION_ROLES.HS]: 'HS',
  [OLYMPIC_ORGANIZATION_ROLES.COLLEGE]: 'College',
  [OLYMPIC_ORGANIZATION_ROLES.SENIOR_CLUB]: 'Senior / Club',
  [OLYMPIC_ORGANIZATION_ROLES.TOUCH]: 'Touch',
  [OLYMPIC_ORGANIZATION_ROLES.REFEREE]: 'Referee',
  [OLYMPIC_ORGANIZATION_ROLES.ADMIN]: 'Admin',
};

export const ORGANIZATION_ROLES = {
  ROOKIE: 'ROOKIE',
  YOUTH: 'YOUTH',
  HS: 'HS',
  COLLEGE: 'COLLEGE',
  SENIOR_CLUB: 'SENIOR_CLUB',
  TOUCH: 'TOUCH',
  REFEREE: 'REFEREE',
  ADMIN: 'ADMIN',
  ATHLETIC_TRAINER: 'ATHLETIC_TRAINER',
  ATHLETE: 'ATHLETE',
  COACH: 'COACH',
  OFFICIAL: 'OFFICIAL',
  OTHER: 'OTHER',
  COACH_MANAGER: 'COACH_MANAGER',
  DOCTOR: 'DOCTOR',
};

export const ORGANIZATION_ROLE_NAMES = {
  [ORGANIZATION_ROLES.ATHLETE]: 'Athlete',
  [ORGANIZATION_ROLES.COACH]: 'Coach',
  [ORGANIZATION_ROLES.OFFICIAL]: 'Official',
  [ORGANIZATION_ROLES.OTHER]: 'Other',
  [ORGANIZATION_ROLES.ATHLETIC_TRAINER]: 'Athletic trainer',
  [ORGANIZATION_ROLES.COACH_MANAGER]: 'Coach manager',
  [ORGANIZATION_ROLES.DOCTOR]: 'Doctor',
  [ORGANIZATION_ROLES.ROOKIE]: 'Rookie',
  [ORGANIZATION_ROLES.YOUTH]: 'Youth',
  [ORGANIZATION_ROLES.HS]: 'HS',
  [ORGANIZATION_ROLES.COLLEGE]: 'College',
  [ORGANIZATION_ROLES.SENIOR_CLUB]: 'Senior / Club',
  [ORGANIZATION_ROLES.TOUCH]: 'Touch',
  [ORGANIZATION_ROLES.REFEREE]: 'Referee',
  [ORGANIZATION_ROLES.ADMIN]: 'Admin',
};

export const CALLER_TYPES = {
  INSURED: 'INSURED_CLIENT',
  AGENT: 'AGENT',
};

export const CALLER_TYPE_NAMES = {
  [CALLER_TYPES.INSURED]: 'Insured/Client',
  [CALLER_TYPES.AGENT]: 'Agent',
};

export const LOSS_TYPES = {
  INJURY: 'INJURY',
  // PROPERTY: 'PROPERTY',
  BOTH: 'INJURY_AND_PROPERTY',
};

export const LOSS_TYPE_NAMES = {
  [LOSS_TYPES.INJURY]: 'Injury',
  // [LOSS_TYPES.PROPERTY]: 'Property',
  [LOSS_TYPES.BOTH]: 'Injury and Property',
};

export const PROPERTY_OWNER_TYPES = {
  COMPANY: 'COMPANY',
  PERSON: 'PERSON',
};

export const PROPERTY_OWNER_TYPE_NAMES = {
  [PROPERTY_OWNER_TYPES.COMPANY]: 'Company',
  [PROPERTY_OWNER_TYPES.PERSON]: 'Person',
};

export const BSI_INJURY_CODES = {
  CUT_LACERATION: 'CUT/LACERATION',
  LACERATION_CUT: 'LACERATION/CUT',
  SCRAPE: 'SCRAPE',
  BROKEN_FRACTURE: 'BROKEN/FRACTURE',
  FRACTURE_BROKEN: 'FRACTURE/BROKEN',
  SPRAIN:
    'SPRAIN [TRAUMA/WRENCHING OF A JOINT OR LIGAMENT]. IF BOTH SPRAIN/STRAIN GIVEN, SELECT STRAIN',
  BRUISE_CONTUSION: 'BRUISE/CONTUSION',
  CONTUSION_BRUISE: 'CONTUSION/BRUISE',
  GAS_POISONING: 'GAS POISONING',
  DERMATITIS: 'DERMATITIS [INFLAMMATION OF THE SKIN (E.G. RASHES, ETC.)]',
  RASH: 'RASH',
  CONCUSSION: 'CONCUSSION [MILD BRAIN INJURY/MINOR HEAD TRAUMA]',
  PULLED_MUSCLE: 'PULLED MUSCLE',
  STRAIN: 'STRAIN [TRAUMA OR WRENCHING OF A MUSCLE]. IF BOTH STRAIN/SPRAIN GIVEN, SELECT STRAIN]',
  HERNIA: 'HERNIA [PROTRUSION OF A TISSUE]',
  FOREIGN_BODY: 'FOREIGN BODY',
  BURN_FIRST_DEGREE: 'BURN - 1ST DEGREE',
  CHEMUCAL_BURN_FIRST_DEGREE: 'CHEMICAL BURN - 1ST DEGREE',
  BURN_SECOND_DEGREE: 'BURN - 2ND DEGREE',
  CHEMICAL_BURN_SECOND_DEGREE: 'CHEMICAL BURN - 2ND DEGREE',
  BURN_THIRD_DEGREE: 'BURN - 3RD DEGREE',
  CHEMICAL_BURN_THIRD_DEGREE: 'CHEMICAL BURN - 3RD DEGREE',
  BURN_SLIGHT: 'BURN - SLIGHT [VERY MINOR]',
  CHEMICAL_BURN_SLIGHT: 'CHEMICAL BURN - SLIGHT [VERY MINOR]',
  CORONARY: 'CORONARY',
  HEART_ATTACK: 'HEART ATTACK',
  MYOCARDIAL_INFARCTION: 'MYOCARDIAL INFARCTION',
  SEIZURE: 'SEIZURE',
  AMPUTATION: 'AMPUTATION [LOSS OF LIMB]',
  SEVERANCE: 'SEVERANCE [SEPARATE/DIVIDE/TAKEN OFF ACCIDENTALLY]',
  NEEDLESTICK_PUNCTURE: 'NEEDLESTICK/PUNCTURE',
  PUNCTURE: 'PUNCTURE',
  SYSTEMIC_POISONING: 'SYSTEMIC POISONING',
  NAUSEA_VOMITING: 'NAUSEA/VOMITING',
  VOMITING_NAUSEA: 'VOMITING/NAUSEA',
  SPLINTER: 'SPLINTER',
  ANXIETY: 'ANXIETY',
  DEPRESSION: 'DEPRESSION',
  MENTAL_ANGUISH: 'MENTAL ANGUISH [PSYCHOLOGICAL SUFFERING]',
  HEARING_LOSS: 'HEARING LOSS',
  VISION_LOSS: 'VISION LOSS',
  DEATH: 'DEATH',
  OTHER_OCCUPATIONAL_DISEASE: 'OTHER OCCUPATIONAL DISEASE',
  CARPAL_TUNNEL_SYNDROME: 'CARPAL TUNNEL SYNDROME',
  CONTINUING_TRAUMA_OR_REPETITIVE_MOTION: 'CONTINUING TRAUMA/REPETITIVE MOTION',
  PINCHED_NERVE: 'PINCHED NERVE',
  REPETITIVE_MOTION_OR_CUMULATIVE_TRAUMA: 'REPETITIVE MOTION/CUMULATIVE TRAUMA',
  TRAUMA: 'TRAUMA',
  ASPHYXIATION: 'ASPHYXIATION',
  DROWNING: 'DROWNING',
  ELECTROCUTION: 'ELECTROCUTION',
  PARALYSIS: 'PARALYSIS [COMPLETE LOSS OF MUSCLE FUNCTION FOR 1 OR MORE MUSCLE GROUPS]',
  INTERNAL_INJURIES: 'INTERNAL INJURIES',
  NATURE_OF_INJURY_NOT_STATED: 'NATURE OF INJURY NOT STATED [USE ONLY WHEN INJURY DESC IS MISSING]',
  DISEASE_OF_BONES: 'DISEASE OF BONES [BONES IN A JOINT BECOME DISPLACED OR MISALIGNED]',
  DISEASE_OF_ORGANS_OF_MOVEMENT:
    'DISEASE OF ORGANS OF MOVEMENT [ANYTHING THAT PUMPS OR EXPANDS INSIDE THE BODY]',
  ABRASIONS_SCRAPES: 'ABRASION(S)/SCRAPE(S)',
  AVULSIONS: 'AVULSION(S) [TEARING LAYERS OF SKIN]',
  INSECT_STING_BITE: 'INSECT STING/BITE',
  CHIPPED_CRACKED: 'CHIPPED/CRACKED',
  ALLERGIC_REACTION: 'ALLERGIC REACTION',
  LOSS_OF_CONSCIOUSNESS: 'LOSS OF CONSCIOUSNESS',
  NUMBNESS_TINGLING: 'NUMBNESS/TINGLING',
  TORN_LIGAMENTS: 'TORN LIGAMENTS [SEVERE SPRAIN]',
  TENDONITIS: 'TENDONITIS',
  TENNIS_ELBOW: 'TENNIS ELBOW',
  ULCERS: 'ULCERS [SORES ON THE LINING OF DIGESTIVE TRACT]',
  INFLAMMATION: 'INFLAMMATION [SKIN, SWELLING, REDNESS]',
  SWELLING_INFLAMMATION: 'SWELLING/INFLAMMATION',
  INFECTION: 'INFECTION',
  DUST_DISEASE_NOC: 'DUST DISEASE NOC (ALL OTHER PNEUMOCONIOSIS)',
  ASBESTOSIS: 'ASBESTOSIS',
  BLACK_LUNG: 'BLACK LUNG [LUNG DISEASE, CAUSED BY INHALATION OF COAL DUST]',
  SILICOSIS: 'SILICOSIS [SCARRING/INFLAMMATION OF THE UPPER PART OF THE LUNGS]',
  RADIATION: 'RADIATION',
  CANCER: 'CANCER',
  HEPATITIS: 'HEPATITIS',
  BLOOD_BORNE_PATHOGEN:
    'BLOOD-BORNE PATHOGEN [INFECTIOUS MATERIALS IN THE BLOOD THAT CAUSE DISEASE]',
  DISLOCATION: 'DISLOCATION [BONES IN A JOINT BECOME DISPLACED OR MISALIGNED]',
  SPINAL_CORD_DAMAGE: 'SPINAL CORD DAMAGE',
  RESPIRATORY_DISTRESS_UNSPECIFIED: 'RESPIRATORY DISTRESS - UNSPECIFIED',
  HERNIATED_DISC: 'HERNIATED DISC [SPLITTING OR RUPTURING OF A VERTEBRAE]',
  RUPTURE: 'RUPTURE',
  DEGENERATIVE_DISK_DISEASE: 'DEGENERATIVE DISK DISEASE [BREAKING DOWN OF THE DISK]',
  MESOTHELIOMA: 'MESOTHELIOMA',
};

export const BSI_BODY_PARTS = {
  FACE: 'FACE (OTHER FACIAL SOFT TISSUE)',
  EAR: 'EAR',
  NOSE: 'NOSE',
  JAW: 'JAW',
  LIP: 'LIP',
  EYE_RIGHT: 'EYE - RIGHT',
  EYE_LEFT: 'EYE - LEFT',
  SCALP: 'SCALP',
  CHEST: 'CHEST [IF HEART RELATED, SELECT HEART]',
  ARM_LEFT: 'ARM - LEFT',
  FOREARM_LEFT: 'FOREARM - LEFT',
  ARM_RIGHT: 'ARM - RIGHT',
  FOREARM_RIGHT: 'FOREARM - RIGHT',
  CALF_LEFT: 'CALF - LEFT',
  FIBULA_LEFT: 'FIBULA - LEFT',
  LEG_LOWER_LEFT: 'LEG - LOWER LEFT',
  TIBIA_LEFT: 'TIBIA - LEFT',
  CALF_RIGHT: 'CALF - RIGHT',
  FIBULA_RIGHT: 'FIBULA - RIGHT',
  LEG_LOWER_RIGHT: 'LEG - LOWER RIGHT',
  TIBIA_RIGHT: 'TIBIA - RIGHT',
  FOOT_LEFT: 'FOOT - LEFT',
  FOOT_RIGHT: 'FOOT - RIGHT',
  ANKLE_LEFT: 'ANKLE - LEFT',
  ANKLE_RIGHT: 'ANKLE - RIGHT',
  KNEE_LEFT: 'KNEE - LEFT',
  KNEE_RIGHT: 'KNEE - RIGHT',
  SHIN_LEFT: 'SHIN - LEFT',
  SHIN_RIGHT: 'SHIN - RIGHT',
  BACK_LOWER: 'BACK - LOWER',
  BACK_UPPER: 'BACK - UPPER',
  WHOLE_BODY: 'WHOLE BODY',
  RIB: 'RIB',
  HAND_LEFT: 'HAND - LEFT',
  HAND_RIGHT: 'HAND - RIGHT',
  WRIST_LEFT: 'WRIST - LEFT',
  WRIST_RIGHT: 'WRIST - RIGHT',
  THUMB_LEFT_FIRST_JOINT: 'THUMB - LEFT 1ST JOINT',
  THUMB_LEFT_SECOND_JOINT: 'THUMB - LEFT 2ND JOINT',
  THUMB_RIGHT_FIRST_JOINT: 'THUMB - RIGHT 1ST JOINT',
  THUMB_RIGHT_SECOND_JOINT: 'THUMB - RIGHT 2ND JOINT',
  FINGER_INDEX_LEFT_FIRST_JOINT: 'FINGER - INDEX - LEFT 1ST JOINT',
  FINGER_INDEX_LEFT_SECOND_JOINT: 'FINGER - INDEX - LEFT 2ND JOINT',
  FINGER_INDEX_LEFT_THIRD_JOINT: 'FINGER - INDEX - LEFT 3RD JOINT',
  FINGER_INDEX_RIGHT_FIRST_JOINT: 'FINGER - INDEX - RIGHT 1ST JOINT',
  FINGER_INDEX_RIGHT_SECOND_JOINT: 'FINGER - INDEX - RIGHT 2ND JOINT',
  FINGER_INDEX_RIGHT_THIRD_JOINT: 'FINGER - INDEX - RIGHT 3RD JOINT',
  FINGER_MIDDLE_LEFT_FIRST_JOINT: 'FINGER - MIDDLE - LEFT 1ST JOINT',
  FINGER_MIDDLE_LEFT_SECOND_JOINT: 'FINGER - MIDDLE - LEFT 2ND JOINT',
  FINGER_MIDDLE_LEFT_THIRD_JOINT: 'FINGER - MIDDLE - LEFT 3RD JOINT',
  FINGER_MIDDLE_RIGHT_FIRST_JOINT: 'FINGER - MIDDLE - RIGHT 1ST JOINT',
  FINGER_MIDDLE_RIGHT_SECOND_JOINT: 'FINGER - MIDDLE - RIGHT 2ND JOINT',
  FINGER_MIDDLE_RIGHT_THIRD_JOINT: 'FINGER - MIDDLE - RIGHT 3RD JOINT',
  FINGER_RING_LEFT_FIRST_JOINT: 'FINGER - RING - LEFT 1ST JOINT',
  FINGER_RING_LEFT_SECOND_JOINT: 'FINGER - RING - LEFT 2ND JOINT',
  FINGER_RING_LEFT_THIRD_JOINT: 'FINGER - RING - LEFT 3RD JOINT',
  FINGER_RING_RIGHT_FIRST_JOINT: 'FINGER - RING - RIGHT 1ST JOINT',
  FINGER_RING_RIGHT_SECOND_JOINT: 'FINGER - RING - RIGHT 2ND JOINT',
  FINGER_RING_RIGHT_THIRD_JOINT: 'FINGER - RING - RIGHT 3RD JOINT',
  FINGER_LITTLE_LEFT_FIRST_JOINT: 'FINGER - LITTLE - LEFT 1ST JOINT',
  FINGER_LITTLE_LEFT_SECOND_JOINT: 'FINGER - LITTLE - LEFT 2ND JOINT',
  FINGER_LITTLE_LEFT_THIRD_JOINT: 'FINGER - LITTLE - LEFT 3RD JOINT',
  FINGER_LITTLE_RIGHT_FIRST_JOINT: 'FINGER - LITTLE - RIGHT 1ST JOINT',
  FINGER_LITTLE_RIGHT_SECOND_JOINT: 'FINGER - LITTLE - RIGHT 2ND JOINT',
  FINGER_LITTLE_RIGHT_THIRD_JOINT: 'FINGER - LITTLE - RIGHT 3RD JOINT',
  ABDOMEN_RIGHT_SIDE: 'ABDOMEN - RIGHT SIDE',
  ABDOMEN_LEFT_SIDE: 'ABDOMEN - LEFT SIDE',
  ABDOMEN_UPPER_SIDE: 'ABDOMEN - UPPER SIDE',
  ABDOMEN_LOWER_SIDE: 'ABDOMEN - LOWER SIDE',
  BUTTOCKS: 'BUTTOCKS',
  SPLEEN: 'SPLEEN',
  INTESTINE: 'INTESTINE',
  PANCREAS: 'PANCREAS',
  HIP_RIGHT: 'HIP - RIGHT',
  PELVIS_RIGHT: 'PELVIS - RIGHT',
  HIP_LEFT: 'HIP - LEFT',
  PELVIS_LEFT: 'PELVIS - LEFT',
  HEAD: 'HEAD',
  NECK: 'NECK',
  TOOTH_TEETH: 'TOOTH/TEETH',
  GROIN: 'GROIN',
  CARDIAC_HEARTH: 'CARDIAC/HEART',
  HEARTH_CARDIAC: 'HEART/CARDIAC',
  ELBOW: 'ELBOW',
  FEMUR: 'FEMUR',
  LEG_UPPER_LEG_THIGH: 'LEG - UPPER LEG/THIGH',
  THIGH_UPPER_LEG: 'THIGH/UPPER LEG',
  SHOULDER: 'SHOULDER',
  CHIN: 'CHIN',
  FORHEAD: 'FOREHEAD',
  TOENAILS: 'TOENAILS',
  STOMACH: 'STOMACH',
  HEEL: 'HEEL',
  MOUTH: 'MOUTH',
  ARTIFICIAL_APPLIANCE: 'ARTIFICIAL APPLIANCE',
  FINGERS_MULTIPLE: 'FINGERS - MULTIPLE',
  FINGERS_UNSPECIFIED: 'FINGERS - UNSPECIFIED',
  TOES_MULTIPLE: 'TOES - MULTIPLE',
  MULTIPLE_BODY_PARTS: 'MULTIPLE BODY PARTS',
  INTERNAL_ORGANS_OTHER: 'INTERNAL ORGANS, OTHER',
  BRAIN: 'BRAIN',
  HEELS_BILATERAL: 'HEELS - BILATERAL',
  CIRCULATORY_SYSTEM: 'CIRCULATORY SYSTEM',
  NERVOUS_SYSTEM: 'NERVOUS SYSTEM',
  LUNGS_PULMONARY: 'LUNGS/PULMONARY',
  KIDNEYS: 'KIDNEYS',
  UNCLASSIFIED_UNKNOWN: 'UNCLASSIFIED/UNKNOWN',
  SKULL: 'SKULL',
  SPINE: 'SPINE',
  DISC_CERVICAL_SEGMENT: 'DISC [CERVICAL SEGMENT]',
  LARYNX: 'LARYNX',
  SOFT_TISSUE: 'SOFT TISSUE',
  TRACHEA: 'TRACHEA',
  MULTIPLE_UPPER_EXT: 'MULTIPLE UPPER EXT',
  ARM_UNSPECIFIED: 'ARM - UNSPECIFIED',
  MULTIPLE_TRUNK: 'MULTIPLE TRUNK',
  DISC_SPINAL_COLUMN_CARTILAGE: 'DISC [SPINAL COLUMN CARTILAGE]',
  SACRUM: 'SACRUM',
  SPINAL_CORD: 'SPINAL CORD',
  MULTIPLE_LOWER_EXT: 'MULTIPLE LOWER EXT',
  HIP_UNSPECIFIED: 'HIP - UNSPECIFIED',
  WRISTS_HANDS: 'WRIST[S] AND HAND[S]',
  LUMBAR_AND_OR_SACRAL_VERTABRAE: 'LUMBAR AND/OR SACRAL VERTABRAE',
  GREAT_TOE: 'GREAT TOE',
  NO_PHYSICAL_INJURY: 'NO PHYSICAL INJURY',
  BODY_SYSTEMS_AND_MULTIPLE_BODY_SYSTEMS: 'BODY SYSTEMS AND MULTIPLE BODY SYSTEMS',
  FOOT_UNSPECIFIED: 'FOOT - UNSPECIFIED',
  ANKLE_UNSPECIFIED: 'ANKLE - UNSPECIFIED',
  KNEE_UNSPECIFIED: 'KNEE - UNSPECIFIED',
  BACK_UNSPECIFIED: 'BACK - UNSPECIFIED',
  HAND_UNSPECIFIED: 'HAND - UNSPECIFIED',
  WRIST_UNSPECIFIED: 'WRIST - UNSPECIFIED',
  PELVIS_UNSPECIFIED: 'PELVIS - UNSPECIFIED',
  TAILBONE: 'TAILBONE',
  FOREARM_UNSPECIFIED: 'FOREARM - UNSPECIFIED',
  CALF_UNSPECIFIED: 'CALF - UNSPECIFIED',
  FIBULA_UNSPECIFIED: 'FIBULA - UNSPECIFIED',
  LEG_LOWER_UNSPECIFIED: 'LEG - LOWER UNSPECIFIED',
  SHIN_UNSPECIFIED: 'SHIN - UNSPECIFIED',
  TIBIA_UNSPECIFIED: 'TIBIA - UNSPECIFIED',
  ANKLE_BILATERAL: 'ANKLE - BILATERAL',
  ARM_BILATERAL: 'ARM - BILATERAL',
  EYE_UNSPECIFIED: 'EYE - UNSPECIFIED',
  FEET_BILATERAL: 'FEET - BILATERAL',
  HANDS_BILATERAL: 'HANDS - BILATERAL',
  KNEE_BILATERAL: 'KNEE - BILATERAL',
  PELVIS_BILATERAL: 'PELVIS - BILATERAL',
  THUMB_UNSPECIFIED: 'THUMB - UNSPECIFIED',
  WRIST_BILATERAL: 'WRIST - BILATERAL',
  ABDOMEN_UNSPECIFIED: 'ABDOMEN - UNSPECIFIED',
};

export const GL_CAUSE_CODES = {
  '001': 'Property Damage To Third Party',
  '002': 'Operations or Premises',
  '003': 'Product and completed operations',
  '004': 'Medical Professional Liability',
};

export const GL_DESCRIPTION_CODES = {
  '001': {
    101: 'BLASTING ACTIVITIES',
    102: 'COLLAPSE',
    103: 'ELECTRICAL',
    104: 'EXPLOSION/IMPLOSION',
    105: 'FIRE/HEAT/SMOKE',
    106: 'FREEZE/THAW',
    107: 'FUMES/ODORS',
    108: 'LANDSLIDE/MUDSLIDE',
    109: 'POLLUTION/CONTAMINATION',
    110: 'UNDEFINED',
    111: 'VEHICLE RELATED DAMAGE',
    112: 'WATER DAMAGE',
  },
  '002': {
    201: 'ASSAULT BY PERSON',
    202: 'ATTACK BY ANIMAL',
    203: 'ELECTRIC SHOCK',
    204: 'FALL',
    205: 'HEAT/FIRE/BURN',
    206: 'INGESTION/INHALATION',
    207: 'OTHER CAUSE OR SOURCE',
    208: 'PHYSICAL/SEXUAL ABUSE',
    209: 'SLIP/TRIP',
    210: 'SPILLED ON',
    211: 'STRUCK BY FALLING OBJECT',
    212: 'STRUCK BY MOVING OBJECT',
  },
  '003': {
    301: 'CONTAMINATION',
    302: 'MISUSE',
    303: 'PRODUCT MALFUNCTION/RUPTURE',
    304: 'UNDEFINED',
  },
  '004': {
    401: 'FAILURE TO WARN/REFER',
    402: 'FOREIGN BODY LEFT IN PATIENT',
    403: 'IMPROPER TREATMENT',
    404: 'MISDIAGNOSIS',
    405: 'UNDEFINED',
  },
};

export const PROFILE_FORM_FIELD_API_ALIASES = {
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  PHOTO: 'photo',
};

export const PROFILE_FORM_PASSWORD_FIELD_API_ALIASES = {
  CURRENT_PASSWORD: 'currentPassword',
  NEW_PASSWORD: 'newPassword',
  CONFIRM_PASSWORD: 'confirmPassword',
};

export const CLAIM_FORM_FIELD_ALIASES = {
  CLAIMANT: {
    CLAIMANT_ADDRESS_CITY: 'claimantAddressCity',
    CLAIMANT_ADDRESS_COUNTRY: 'claimantAddressCountry',
    CLAIMANT_ADDRESS_STATE: 'claimantAddressState',
    CLAIMANT_ADDRESS_STREET: 'claimantAddressStreet',
    CLAIMANT_ADDRESS_ZIP: 'claimantAddressZip',
    CLAIMANT_DOB: 'claimantDateOfBirth',
    CLAIMANT_EMAIL: 'claimantEmail',
    CLAIMANT_MEMBER_ID: 'memberId',
    CLAIMANT_GENDER: 'claimantGender',
    CLAIMANT_LAST_NAME: 'claimantLastName',
    CLAIMANT_MIDDLE_INITIAL: 'claimantMiddleInitial',
    CLAIMANT_FIRST_NAME: 'claimantFirstName',
    CLAIMANT_FATHERS_FULL_NAME: 'claimantFathersFullName',
    CLAIMANT_MOTHERS_FULL_NAME: 'claimantMothersFullName',
    CLAIMANT_ROLE_IN_ORG: 'claimantRoleInOrganization',
  },
  INSURANCE: {
    INSURANCE_COMPANY_NAME: 'insuranceCompanyName',
    INSURANCE_POLICYHOLDER_NAME: 'insurancePolicyHolderName',
    INSURANCE_POLICY_NUMBER: 'insurancePolicyNumber',
    INSURANCE_GROUP_NUMBER: 'insuranceGroupNumber',
  },
  INCIDENT: {
    INCIDENT_AUTHORIZE_TPA_PROCESSING: 'incidentAuthorizeTPAProcessing',
    INCIDENT_BODY_PART_INJURED: 'incidentBodyPartInjured',
    INCIDENT_BODY_SIDE_INJURED: 'incidentBodySideInjured',
    INCIDENT_CONTACT: 'incidentContact',
    INCIDENT_CONTACT_TYPE: 'incidentContactType',
    INCIDENT_DATE: 'incidentDate',
    INCIDENT_DESCRIPTION: 'incidentDescription',
    INCIDENT_EVENT_NAME: 'incidentEventName',
    INCIDENT_EVENT_TYPE: 'incidentEventType',
    INCIDENT_INJURY_TYPE: 'incidentInjuryType',
    INCIDENT_LOCATION: 'incidentLocation',
    INCIDENT_MEMBERSHIP_TYPE: 'incidentMembershipType',
    INCIDENT_MEMBERSHIP_OTHER_TYPE: 'incidentMembershipOtherType',
    INCIDENT_ONSITE_CARE_RECEIVED: 'incidentOnsiteCareReceived',
    INCIDENT_OTHER_CONTACT_TYPE: 'incidentOtherContactType',
    INCIDENT_PLAYING_SURFACE: 'incidentPlayingSurface',
    INCIDENT_SEVERITY: 'incidentSeverity',
    INCIDENT_TAKEN_TO_HOSPITAL_BY_AMBULANCE: 'incidentTakenToHospitalByAmbulance',
    INCIDENT_TIME: 'incidentTime',
    INCIDENT_VICTIM_SIGNATURE: 'incidentVictimSignature',
    INCIDENT_VICTIM_FILES: 'incidentVictimSignatureFiles',
    INCIDENT_NOTE: 'note',
  },
  COACH: {
    COACH_INJURED_TREATED_BY_OFFICIALS: 'coachAthleteTreatedByOfficials',
    COACH_INJURED_CLUB_NAME: 'coachInjuredClubName',
    COACH_EMAIL: 'coachEmail',
    COACH_FIRST_NAME: 'coachFirstName',
    COACH_INCIDENT_TERRITORY: 'coachIncidentTerritory',
    COACH_LAST_NAME: 'coachLastName',
    COACH_PHONE: 'coachPhone',
    COACH_SIGNATURE: 'coachSignature',
  },
  DISCLAIMERS: {
    COACH_READ_AND_CONFIRMED_FNOL: 'coachReadAndConfirmedFnol',
    READ_AND_AGREE_TO_CLAIMS_INFO: 'readAndAgreeToClaimsInfo',
  },
};

export const USASA_CLAIM_FORM_FIELD_ALIASES = {
  CLAIMANT: {
    CLAIMANT_FIRST_NAME: 'claimantFirstName',
    CLAIMANT_LAST_NAME: 'claimantLastName',
    CLAIMANT_DOB: 'claimantDateOfBirth',
    CLAIMANT_GENDER: 'claimantGender',
    CLAIMANT_COACH_PLAYER_TYPE: 'claimantCoachPlayerType',
    // part two
    CLAIMANT_ADDRESS_STREET: 'claimantAddressStreet',
    CLAIMANT_ADDRESS_ADDITIONAL: 'claimantAddressAdditional',
    CLAIMANT_ADDRESS_CITY: 'claimantAddressCity',
    CLAIMANT_ADDRESS_STATE: 'claimantAddressState',
    CLAIMANT_ADDRESS_ZIP: 'claimantAddressZip',
    CLAIMANT_ADDRESS_COUNTRY: 'claimantAddressCountry',
    CLAIMANT_EMAIL: 'claimantEmail',
    CLAIMANT_HOME_PHONE: 'claimantHomePhone',
    CLAIMANT_WORK_PHONE: 'claimantWorkPhone',
    CLAIMANT_STATUS: 'claimantStatus',
    CLAIMANT_SEEN_PROVIDER: 'haveYouAlreadySeenAProvider',
    CLAIMANT_MEDICAID_BENEFICIARY: 'isInjuredPersonAMedicareMedicaidBeneficiary',
    CLAIMANT_SS_NOR_HID_NUMBER: 'SSNorHIDNumber',
    CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN: 'areInjuredPersonCurrentlyEnrolledAccidentPlan',
    CLAIMANT_PARENTS_PHONE: 'claimantParentsPhone',
  },
  INCIDENT: {
    INCIDENT_DATE: 'incidentDate',
    INCIDENT_TIME: 'incidentTime',
    INCIDENT_LOCATION: 'incidentLocation',
    INCIDENT_BODY_PART_INJURED: 'incidentBodyPartInjured',
    INCIDENT_BODY_SIDE_INJURED: 'incidentBodySideInjured',
    INCIDENT_INJURY_TYPE: 'incidentInjuryType',
    INCIDENT_INJURY_OCCURED_ON: 'incidentInjuryOccuredOn',
    INCIDENT_INJURY_OCCURED_AT: 'incidentInjuryOccuredAt',
    INCIDENT_INJURY_OCCURED_AT_OTHER: 'injuryOccurredAtOther',
    INCIDENT_EVENT_NAME_AND_TYPE: 'incidentEventNameAndType',
    INCIDENT_INJURY_DESCRIPTION: 'incidentInjuryDescription',
    INCEDENT_NAME_OF_TEAM: 'nameOfTeam',
    INCEDENT_NAME_OF_LEAGUE: 'nameOfLeague',
    INCEDENT_STATE_ASSICOATION_AFFILATE: 'stateAssociationNationwideAffiliate',
    INCEDENT_REGION: 'region',
    INCEDENT_CLAIMANT_CHECKBOX: 'usasaClaimantCheckbox1',
    INCEDENT_CLAIMANT_SIGNATURE: 'claimantSignature',
    INCIDENT_NOTE: 'note',
  },
  COACH: {
    STATE_ADMIN: 'stateAdmin',
    COACH_EMAIL: 'coachEmail',
    COACH_FULL_NAME: 'coachFullName',
    COACH_FIRST_NAME: 'coachFirstName',
    COACH_LAST_NAME: 'coachLastName',
  },
  HEALTH: {
    HEALTH_INSURANCE_COMPANY_NAME: 'healthInsuranceCompanyName',
    HEALTH_INSURANCE_GROUP_NAME: 'healthInsuranceGroupName',
    HEALTH_INSURANCE_POLICY_HOLDERS_NAME: 'healthInsurancePolicyHoldersName',
    HEALTH_INSURANCE_MEMBER_ID: 'healthInsuranceMemberId',
    HEALTH_INSURANCE_POLICY_NUMBER: 'healthInsurancePolicyNumber',
    HEALTH_INSURANCE_BURSAR_EMAIL_ADDRESS: 'employerOrSpousesEmployerOrBursarsOfficeEmailAddress',
  },
  PROVIDER: {
    NAME: 'providerName',
    ADDRESS: 'providerAddress',
    EMAIL: 'providerEmail',
    PHONE_NUMBER: 'providerPhoneNumber',
  },
  DISCLAIMERS: {
    COACH_READ_AND_CONFIRMED_FNOL: 'coachReadAndConfirmedFnol',
    READ_AND_AGREE_TO_CLAIMS_INFO: 'readAndAgreeToClaimsInfo',
  },
};

export const USASA_CLAIM_FORM_FIELD_API_ALIASES = {
  // CLAIMANT
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_FIRST_NAME]: 'claimantFirstName',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_LAST_NAME]: 'claimantLastName',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_DOB]: 'claimantDOB',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_GENDER]: 'claimantGender',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_COACH_PLAYER_TYPE]: 'claimantCoachPlayerType',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_PARENTS_PHONE]: 'olympic_claimantParentsPhone',

  // CLAIMANT part two
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_STREET]:
    'claimantAddress.streetAddress',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_ADDITIONAL]:
    'claimantAddress.additionalAddress',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_CITY]: 'claimantAddress.city',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_STATE]: 'claimantAddress.state',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_ZIP]: 'claimantAddress.zip',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_COUNTRY]: 'claimantAddress.country',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_EMAIL]: 'claimantEmail',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_HOME_PHONE]: 'claimantHomePhone',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_WORK_PHONE]: 'claimantWorkPhone',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_STATUS]: 'claimantStatus',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_SEEN_PROVIDER]: 'haveYouAlreadySeenAProvider',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_MEDICAID_BENEFICIARY]:
    'isInjuredPersonAMedicareMedicaidBeneficiary',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_SS_NOR_HID_NUMBER]: 'SSNorHIDNumber',
  [USASA_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN]:
    'areInjuredPersonCurrentlyEnrolledAccidentPlan',
  // INCEDENT
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_DATE]: 'incidentDate',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_TIME]: 'incidentTime',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_LOCATION]: 'incidentLocation',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_BODY_PART_INJURED]: 'injuredBodyPart',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_BODY_SIDE_INJURED]: 'injuredBodySide',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_TYPE]: 'injuryType',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_OCCURED_ON]: 'injuryOccurredOn',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_OCCURED_AT]: 'injuryOccurredAt',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_OCCURED_AT_OTHER]:
    'injuryOccurredAtOther',
  // INCIDENT_INJURY_OCCURED_AT: 'incidentInjuryOccuredAt',injuryOccurredAtOther
  // INCIDENT_INJURY_OCCURED_AT_OTHER: 'injuryOccurredAtOther',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_EVENT_NAME_AND_TYPE]: 'nameAndTypeOfEvent',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_DESCRIPTION]: 'injuryDescription',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCEDENT_NAME_OF_TEAM]: 'nameOfTeam',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCEDENT_NAME_OF_LEAGUE]: 'nameOfLeague',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCEDENT_STATE_ASSICOATION_AFFILATE]:
    'stateAssociationNationwideAffiliate',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCEDENT_REGION]: 'region',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCEDENT_CLAIMANT_CHECKBOX]: 'usasa_claimantCheckbox1',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCEDENT_CLAIMANT_SIGNATURE]: 'claimantSignature',
  [USASA_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_NOTE]: 'note',
  // COACH
  [USASA_CLAIM_FORM_FIELD_ALIASES.COACH.STATE_ADMIN]: 'stateAdmin',
  [USASA_CLAIM_FORM_FIELD_ALIASES.COACH.COACH_EMAIL]: 'coachEmail',
  // [USASA_CLAIM_FORM_FIELD_ALIASES.COACH.COACH_FULL_NAME]: 'coachFullName',
  [USASA_CLAIM_FORM_FIELD_ALIASES.COACH.COACH_FIRST_NAME]: 'coachFirstName',
  [USASA_CLAIM_FORM_FIELD_ALIASES.COACH.COACH_LAST_NAME]: 'coachLastName',
  // HEALTH PLAN
  [USASA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_INSURANCE_COMPANY_NAME]: 'companyName',
  [USASA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_INSURANCE_GROUP_NAME]: 'groupName',
  [USASA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_INSURANCE_POLICY_HOLDERS_NAME]: 'policyHoldersName',
  [USASA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_INSURANCE_MEMBER_ID]: 'memberId',
  [USASA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_INSURANCE_POLICY_NUMBER]: 'policyNumber',
  [USASA_CLAIM_FORM_FIELD_ALIASES.HEALTH.HEALTH_INSURANCE_BURSAR_EMAIL_ADDRESS]:
    'employerOrSpousesEmployerOrBursarsOfficeEmailAddress',

  // PROVIDER INFORMATION
  [USASA_CLAIM_FORM_FIELD_ALIASES.PROVIDER.NAME]: 'providerName',
  [USASA_CLAIM_FORM_FIELD_ALIASES.PROVIDER.ADDRESS]: 'providerAddress',
  [USASA_CLAIM_FORM_FIELD_ALIASES.PROVIDER.EMAIL]: 'providerEmail',
  [USASA_CLAIM_FORM_FIELD_ALIASES.PROVIDER.PHONE_NUMBER]: 'providerPhoneNumber',
};

export const CLAIM_FORM_FIELD_API_ALIASES = {
  // CLAIMANT
  [CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_CITY]: 'claimantAddress.city',
  [CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_COUNTRY]: 'claimantAddress.country',
  [CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_STATE]: 'claimantAddress.state',
  [CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_STREET]: 'claimantAddress.streetAddress',
  [CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_ZIP]: 'claimantAddress.zip',
  [CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_DOB]: 'claimantDOB',
  [CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_MIDDLE_INITIAL]: 'claimantMiddleName',
  [CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ROLE_IN_ORG]: 'claimantRoleType',
  // INSURANCE
  [CLAIM_FORM_FIELD_ALIASES.INSURANCE.INSURANCE_COMPANY_NAME]: 'insuranceCompany',
  [CLAIM_FORM_FIELD_ALIASES.INSURANCE.INSURANCE_POLICYHOLDER_NAME]: 'policyHoldersName',
  [CLAIM_FORM_FIELD_ALIASES.INSURANCE.INSURANCE_POLICY_NUMBER]: 'policyNumber',
  [CLAIM_FORM_FIELD_ALIASES.INSURANCE.INSURANCE_GROUP_NUMBER]: 'groupNumber',
  // INCIDENT
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_BODY_PART_INJURED]: 'injuredBodyPart',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_BODY_SIDE_INJURED]: 'injuredBodySide',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_CONTACT]: 'isContact',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_CONTACT_TYPE]: 'contactType',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_OTHER_CONTACT_TYPE]: 'contactOtherType',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_DESCRIPTION]: 'injuryDescription',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_EVENT_NAME]: 'nameOfEventOrTeamsCompetingAgainst',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_EVENT_TYPE]: 'eventType',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_TYPE]: 'injuryType',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_MEMBERSHIP_TYPE]: 'membershipType',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_MEMBERSHIP_OTHER_TYPE]: 'membershipOtherType',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_ONSITE_CARE_RECEIVED]: 'isReceivedOnsite',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_PLAYING_SURFACE]: 'plainSurface',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_SEVERITY]: 'severity',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_TAKEN_TO_HOSPITAL_BY_AMBULANCE]:
    'isTakenByAmbulanceToHospital',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_VICTIM_SIGNATURE]: 'claimantSignature',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_VICTIM_FILES]: 'files',
  [CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_NOTE]: 'note',
  // COACH
  [CLAIM_FORM_FIELD_ALIASES.COACH.COACH_INJURED_CLUB_NAME]: 'clubNameOfInjured',
  [CLAIM_FORM_FIELD_ALIASES.COACH.COACH_INCIDENT_TERRITORY]: 'territoryWhereIncidentOccurred',
  [CLAIM_FORM_FIELD_ALIASES.COACH.COACH_INJURED_TREATED_BY_OFFICIALS]: 'isAthleteWasTreatedByCoach',
};

export const OLYMPIC_CLAIM_FORM_FIELD_ALIASES = {
  CLAIMANT: {
    CLAIMANT_DOB: 'claimantDateOfBirth',
    CLAIMANT_ADDRESS_CITY: 'claimantAddressCity',
    // CLAIMANT_ADDRESS_COUNTRY: 'claimantAddressCountry',
    CLAIMANT_ADDRESS_STATE: 'claimantAddressState',
    CLAIMANT_ADDRESS_STREET: 'claimantAddressStreet',
    CLAIMANT_ADDRESS_ZIP: 'claimantAddressZip',
    CLAIMANT_SSN: 'olympic_socialSecurityNumber',
    CLAIMANT_EMAIL: 'claimantEmail',
    CLAIMANT_GENDER: 'claimantGender',
    CLAIMANT_LAST_NAME: 'claimantLastName',
    CLAIMANT_MIDDLE_INITIAL: 'claimantMiddleInitial',
    CLAIMANT_FIRST_NAME: 'claimantFirstName',
    CLAIMANT_PARENTS_FULL_NAME: 'claimantParentsFullName',
    CLAIMANT_PARENTS_ADDRESS: 'claimantParentsAddress',
    CLAIMANT_PARENTS_PHONE: 'claimantParentsPhone',
    CLAIMANT_ROLE_IN_ORG: 'claimantRoleInOrganization',
  },
  INSURANCE: {
    INSURANCE_COMPANY_NAME: 'insuranceCompanyName',
    INSURANCE_POLICYHOLDER_NAME: 'insurancePolicyHolderName',
    INSURANCE_POLICY_NUMBER: 'insurancePolicyNumber',
    INSURANCE_GROUP_NUMBER: 'insuranceGroupNumber',
    CLAIMANT_HAS_HEALTHCARE: 'olympic_haveMedicalCare',
  },
  INCIDENT: {
    INCIDENT_AUTHORIZE_MEDICAL_PAYMENTS: 'olympic_checkbox1',
    INCIDENT_AUTHORIZE_HERIBAL_ANY_INSURANCE: 'olympic_checkbox2',
    INCIDENT_AUTHORIZE_HERIBAL_ANY_COMMUNICATIONS: 'olympic_checkbox3',
    INCIDENT_BODY_PART_INJURED: 'incidentBodyPartInjured',
    INCIDENT_BODY_SIDE_INJURED: 'incidentBodySideInjured',
    INCIDENT_CONTACT: 'incidentContact',
    INCIDENT_CONTACT_TYPE: 'incidentContactType',
    INCIDENT_DATE: 'incidentDate',
    INCIDENT_DESCRIPTION: 'incidentDescription',
    INDICATION_TEETH_INVOLVED: 'olympic_dentalInvolvedTeeth',
    DESCRIBTION_TEETH_INVOLVED: 'olympic_dentalConditionTeeth',
    PLAYING_SURFACE: 'plainSurface',
    NAME_OF_TEAM: 'olympic_nameOfTeam',
    // SUPERVISOR_FIRST_NAME: 'olympic_superVisorFirstName',
    // SUPERVISOR_LAST_NAME: 'olympic_superVisorLastName',
    // SUPERVISOR_TITLE: 'olympic_superVisorTitle',
    INCIDENT_EVENT_NAME: 'incidentEventName',
    INCIDENT_EVENT_TYPE: 'incidentEventType',
    INCIDENT_INJURY_TYPE: 'incidentInjuryType',
    INCIDENT_LOCATION: 'incidentLocation',
    INCIDENT_MEMBERSHIP_TYPE: 'incidentMembershipType',
    INCIDENT_MEMBERSHIP_OTHER_TYPE: 'incidentMembershipOtherType',
    // INCIDENT_ONSITE_CARE_RECEIVED: 'incidentOnsiteCareReceived',
    INCIDENT_RESULT_IN_DEATH: 'olympic_didInjuryResultInDeath',
    INCIDENT_OTHER_CONTACT_TYPE: 'incidentOtherContactType',
    // INCIDENT_SEVERITY: 'incidentSeverity',
    INCIDENT_TAKEN_TO_HOSPITAL_BY_AMBULANCE: 'incidentTakenToHospitalByAmbulance',
    INCIDENT_TIME: 'incidentTime',
    INCIDENT_VICTIM_SIGNATURE: 'incidentVictimSignature',
    INCIDENT_NOTE: 'note',
  },
  COACH: {
    COACH_INJURED_TREATED_BY_OFFICIALS: 'coachAthleteTreatedByOfficials',
  },
  DISCLAIMERS: {
    COACH_READ_AND_CONFIRMED_FNOL: 'coachReadAndConfirmedFnol',
    READ_AND_AGREE_TO_CLAIMS_INFO: 'readAndAgreeToClaimsInfo',
  },
};

export const OLYMPIC_CLAIM_FORM_FIELD_API_ALIASES = {
  // CLAIMANT
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_CITY]: 'olympic_city',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_STATE]: 'olympic_state',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_STREET]: 'olympic_street',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ADDRESS_ZIP]: 'olympic_zip',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_DOB]: 'claimantDOB',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_FIRST_NAME]: 'claimantFirstName',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_LAST_NAME]: 'claimantLastName',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_SSN]: 'olympic_socialSecurityNumber',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_ROLE_IN_ORG]: 'olympic_claimantRoleType',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_PARENTS_FULL_NAME]:
    'olympic_claimantParentsFullName',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_PARENTS_ADDRESS]:
    'olympic_claimantParentsAddress',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.CLAIMANT.CLAIMANT_PARENTS_PHONE]:
    'olympic_claimantParentsPhone',
  // INSURANCE
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INSURANCE.INSURANCE_COMPANY_NAME]: 'insuranceCompany',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INSURANCE.INSURANCE_POLICYHOLDER_NAME]: 'policyHoldersName',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INSURANCE.INSURANCE_POLICY_NUMBER]: 'policyNumber',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INSURANCE.INSURANCE_GROUP_NUMBER]: 'groupNumber',
  // INCIDENT
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_BODY_PART_INJURED]: 'injuredBodyPart',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_BODY_SIDE_INJURED]: 'injuredBodySide',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_CONTACT]: 'isContact',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_CONTACT_TYPE]: 'contactType',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_OTHER_CONTACT_TYPE]: 'contactOtherType',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_DESCRIPTION]: 'injuryDescription',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_EVENT_NAME]:
    'nameOfEventOrTeamsCompetingAgainst',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_EVENT_TYPE]: 'eventType',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_INJURY_TYPE]: 'injuryType',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_MEMBERSHIP_TYPE]: 'membershipType',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_MEMBERSHIP_OTHER_TYPE]: 'membershipOtherType',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_RESULT_IN_DEATH]:
    'olympic_didInjuryResultInDeath',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_RESULT_IN_DEATH]:
    'olympic_didInjuryResultInDeath',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_TAKEN_TO_HOSPITAL_BY_AMBULANCE]:
    'isTakenByAmbulanceToHospital',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_VICTIM_SIGNATURE]: 'claimantSignature',
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_NOTE]: 'note',
  // COACH
  [OLYMPIC_CLAIM_FORM_FIELD_ALIASES.COACH.COACH_INJURED_TREATED_BY_OFFICIALS]:
    'isAthleteWasTreatedByCoach',
};

export const USER_ROLES = {
  BROKER_ADMIN: 'BROKER_ADMIN',
  GUEST: 'GUEST',
  SUPER_ADMIN: 'SUPER_ADMIN',
};

export const CRAWFORD_CLAIM_FORM_FIELD_ALIASES = {
  LOSS_AND_POLICY: {
    POLICY_NUMBER: 'playersHealthPolicyNumber',
    LOSS_TYPE: 'lossType',
    POLICY_STATE: 'policyState',
    LOSS_DATE: 'incidentDate',
    LOSS_TIME: 'incidentTime',
    LOSS_DESCRIPTION: 'injuryDescription',
    LOSS_LOCATION_NAME: 'lossLocationName',
    LOSS_LOCATION_EVENT_TYPE: 'eventType',
    LOSS_LOCATION_EVENT_NAME_AND_TYPE: 'nameAndTypeOfEvent',
    LOSS_LOCATION_ADDRESS_LINE1: 'lossLocationAddressStreet',
    LOSS_LOCATION_ADDRESS_LINE2: 'lossLocationAdditionalAddress',
    LOSS_LOCATION_ADDRESS_CITY: 'lossLocationAddressCity',
    LOSS_LOCATION_ADDRESS_STATE: 'lossLocationAddressState',
    LOSS_LOCATION_ADDRESS_ZIP: 'lossLocationAddressZip',
    LOSS_LOCATION_ADDRESS_COUNTRY: 'lossLocationAddressCountry',
    LOSS_LOCATION_IS_ON_PREMISSES: 'lossLocationIsOnPremises',
    // GL_CAUSE_CODE: 'glCauseCode',
    // GL_DESCRIPTION_CODE: 'glDescriptionCode',
  },
  HEALTH: {
    HEALTH_INSURANCE_COMPANY_NAME: 'healthInsuranceCompanyName',
    HEALTH_INSURANCE_GROUP_NAME: 'healthInsuranceGroupName',
    HEALTH_INSURANCE_POLICY_HOLDERS_NAME: 'healthInsurancePolicyHoldersName',
    HEALTH_INSURANCE_MEMBER_ID: 'healthInsuranceMemberId',
    HEALTH_INSURANCE_POLICY_NUMBER: 'healthInsurancePolicyNumber',
  },
  RISK_LOCATION: {
    RISK_LOCATION_NAME: 'riskLocationName',
    RISK_LOCATION_ADDRESS_LINE1: 'riskLocationAddressStreet',
    RISK_LOCATION_ADDRESS_LINE2: 'riskLocationAdditionalAddress',
    RISK_LOCATION_ADDRESS_CITY: 'riskLocationAddressCity',
    RISK_LOCATION_ADDRESS_STATE: 'riskLocationAddressState',
    RISK_LOCATION_ADDRESS_ZIP: 'riskLocationAddressZip',
    RISK_LOCATION_ADDRESS_COUNTRY: 'riskLocationAddressCountry',
    RISK_LOCATION_WORK_PHONE: 'riskLocationWorkPhone',
  },
  PROPERTY: {
    PROPERTY_DESCRIPTION: 'propertyDescription',
    PROPERTY_DAMAGE_DESCRIPTION: 'propertyDamageDescription',
    PROPERTY_OWNER_TYPE: 'propertyOwnerType',
    PROPERTY_OWNER_COMPANY_NAME: 'propertyOwnerCompanyName',
    PROPERTY_OWNER_PERSON_FIRST_NAME: 'propertyOwnerPersonFirstName',
    PROPERTY_OWNER_PERSON_LAST_NAME: 'propertyOwnerPersonLastName',
    PROPERTY_OWNER_ADDRESS_LINE1: 'propertyOwnerAddressStreet',
    PROPERTY_OWNER_ADDRESS_LINE2: 'propertyOwnerAdditionalAddress',
    PROPERTY_OWNER_ADDRESS_CITY: 'propertyOwnerAddressCity',
    PROPERTY_OWNER_ADDRESS_STATE: 'propertyOwnerAddressState',
    PROPERTY_OWNER_ADDRESS_ZIP: 'propertyOwnerAddressZip',
    PROPERTY_OWNER_ADDRESS_COUNTRY: 'propertyOwnerAddressCountry',
    PROPERTY_OWNER_WORK_PHONE: 'propertyOwnerWorkPhone',
    PROPERTY_OWNER_HOME_PHONE: 'propertyOwnerHomePhone',
  },
  INJURED: {
    INJURED_FIRST_NAME: 'claimantFirstName',
    INJURED_LAST_NAME: 'claimantLastName',
    INJURED_ADDRESS_LINE1: 'claimantAddressStreet',
    INJURED_ADDRESS_LINE2: 'claimantAdditionalAddress',
    INJURED_ADDRESS_CITY: 'claimantAddressCity',
    INJURED_ADDRESS_STATE: 'claimantAddressState',
    INJURED_ADDRESS_ZIP: 'claimantAddressZip',
    INJURED_ADDRESS_COUNTRY: 'claimantAddressCountry',
    INJURED_DOB: 'claimantDateOfBirth',
    INJURED_GENDER: 'claimantGender',
    INJURED_BODY_PART_CODE: 'claimantInjuredBodyPart',
    INJURED_INJURY_CODE: 'claimantInjuryCode',
    INJURED_INJURY_DESCRIPTION: 'claimantInjuryDescription',
    INJURED_INJURED_DOING: 'claimantInjuredDoing',
    INJURED_TAKEN_TO_HOSPITAL_BY_AMBULANCE: 'isTakenByAmbulanceToHospital',
    INJURED_INJURY_RESULT_IN_DEATH: 'olympic_didInjuryResultInDeath',
    INJURED_MEMBER_ID: 'memberId',
    INJURED_NAME_OF_TEAM: 'nameOfTeam',
    INJURED_STATE_ASSOCIATION: 'stateAssociationNationwideAffiliate',
    INJURED_NAME_OF_LEAGUE: 'nameOfLeague',
    CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN: 'areInjuredPersonCurrentlyEnrolledAccidentPlan',
  },
  CALLER: {
    CALLER_FIRST_NAME: 'callerFirstName',
    CALLER_LAST_NAME: 'callerLastName',
    CALLER_EMAIL: 'claimantEmail',
    CALLER_WORK_PHONE: 'callerWorkPhone',
    CALLER_TYPE: 'callerType',
  },
  DISCLAIMERS: {
    INCIDENT_VICTIM_SIGNATURE: 'claimantSignature',
  },
};

const CCFFA = CRAWFORD_CLAIM_FORM_FIELD_ALIASES;

export const CRAWFORD_CLAIM_FORM_FIELD_API_ALIASES = {
  // LOSS_AND_POLICY
  [CCFFA.LOSS_AND_POLICY.POLICY_NUMBER]: 'playersHealthPolicyNumber',
  [CCFFA.LOSS_AND_POLICY.LOSS_TYPE]: 'lossType',
  [CCFFA.LOSS_AND_POLICY.POLICY_STATE]: 'policyState',
  [CCFFA.LOSS_AND_POLICY.LOSS_DATE]: 'incidentDate',
  [CCFFA.LOSS_AND_POLICY.LOSS_TIME]: 'incidentTime',
  [CCFFA.LOSS_AND_POLICY.LOSS_DESCRIPTION]: 'injuryDescription',
  [CCFFA.LOSS_AND_POLICY.LOSS_LOCATION_NAME]: 'lossLocation.name',
  [CCFFA.LOSS_AND_POLICY.LOSS_LOCATION_EVENT_TYPE]: 'eventType',
  [CCFFA.LOSS_AND_POLICY.LOSS_LOCATION_EVENT_NAME_AND_TYPE]: 'nameAndTypeOfEvent',
  [CCFFA.LOSS_AND_POLICY.LOSS_LOCATION_ADDRESS_LINE1]: 'lossLocation.address.streetAddress',
  [CCFFA.LOSS_AND_POLICY.LOSS_LOCATION_ADDRESS_LINE2]: 'lossLocation.address.additionalAddress',
  [CCFFA.LOSS_AND_POLICY.LOSS_LOCATION_ADDRESS_CITY]: 'lossLocation.address.city',
  [CCFFA.LOSS_AND_POLICY.LOSS_LOCATION_ADDRESS_STATE]: 'lossLocation.address.state',
  [CCFFA.LOSS_AND_POLICY.LOSS_LOCATION_ADDRESS_ZIP]: 'lossLocation.address.zip',
  [CCFFA.LOSS_AND_POLICY.LOSS_LOCATION_ADDRESS_COUNTRY]: 'lossLocation.address.country',
  [CCFFA.LOSS_AND_POLICY.LOSS_LOCATION_IS_ON_PREMISSES]: 'lossLocation.isOnPremises',
  // [CCFFA.LOSS_AND_POLICY.GL_CAUSE_CODE]: 'glCauseCode',
  // description missing ?
  // RISK_LOCATION
  [CCFFA.RISK_LOCATION.RISK_LOCATION_NAME]: 'riskLocation.name',
  [CCFFA.RISK_LOCATION.RISK_LOCATION_ADDRESS_LINE1]: 'riskLocation.address.streetAddress',
  [CCFFA.RISK_LOCATION.RISK_LOCATION_ADDRESS_LINE2]: 'riskLocation.address.additionalAddress',
  [CCFFA.RISK_LOCATION.RISK_LOCATION_ADDRESS_CITY]: 'riskLocation.address.city',
  [CCFFA.RISK_LOCATION.RISK_LOCATION_ADDRESS_STATE]: 'riskLocation.address.state',
  [CCFFA.RISK_LOCATION.RISK_LOCATION_ADDRESS_ZIP]: 'riskLocation.address.zip',
  [CCFFA.RISK_LOCATION.RISK_LOCATION_ADDRESS_COUNTRY]: 'riskLocation.address.country',
  [CCFFA.RISK_LOCATION.RISK_LOCATION_WORK_PHONE]: 'riskLocation.workPhone',
  // PROPERTY
  [CCFFA.PROPERTY.PROPERTY_DESCRIPTION]: 'property.description',
  [CCFFA.PROPERTY.PROPERTY_DAMAGE_DESCRIPTION]: 'property.damageDescription',
  [CCFFA.PROPERTY.PROPERTY_OWNER_TYPE]: 'property.owner.type',
  [CCFFA.PROPERTY.PROPERTY_OWNER_COMPANY_NAME]: 'property.owner.name',
  [CCFFA.PROPERTY.PROPERTY_OWNER_PERSON_FIRST_NAME]: 'property.owner.firstName',
  [CCFFA.PROPERTY.PROPERTY_OWNER_PERSON_LAST_NAME]: 'property.owner.lastName',
  [CCFFA.PROPERTY.PROPERTY_OWNER_ADDRESS_LINE1]: 'property.owner.address.streetAddress',
  [CCFFA.PROPERTY.PROPERTY_OWNER_ADDRESS_LINE2]: 'property.owner.address.additionalAddress',
  [CCFFA.PROPERTY.PROPERTY_OWNER_ADDRESS_CITY]: 'property.owner.address.city',
  [CCFFA.PROPERTY.PROPERTY_OWNER_ADDRESS_STATE]: 'property.owner.address.state',
  [CCFFA.PROPERTY.PROPERTY_OWNER_ADDRESS_ZIP]: 'property.owner.address.zip',
  [CCFFA.PROPERTY.PROPERTY_OWNER_ADDRESS_COUNTRY]: 'property.owner.address.country',
  [CCFFA.PROPERTY.PROPERTY_OWNER_WORK_PHONE]: 'property.owner.workPhone',
  [CCFFA.PROPERTY.PROPERTY_OWNER_HOME_PHONE]: 'property.owner.homePhone',
  // INJURED
  [CCFFA.INJURED.INJURED_FIRST_NAME]: 'claimantFirstName',
  [CCFFA.INJURED.INJURED_LAST_NAME]: 'claimantLastName',
  [CCFFA.INJURED.INJURED_ADDRESS_LINE1]: 'claimantAddress.streetAddress',
  [CCFFA.INJURED.INJURED_ADDRESS_LINE2]: 'claimantAddress.additionalAddress',
  [CCFFA.INJURED.INJURED_ADDRESS_CITY]: 'claimantAddress.city',
  [CCFFA.INJURED.INJURED_ADDRESS_STATE]: 'claimantAddress.state',
  [CCFFA.INJURED.INJURED_ADDRESS_ZIP]: 'claimantAddress.zip',
  [CCFFA.INJURED.INJURED_ADDRESS_COUNTRY]: 'claimantAddress.country',
  [CCFFA.INJURED.INJURED_DOB]: 'claimantDOB',
  [CCFFA.INJURED.INJURED_GENDER]: 'claimantGender',
  [CCFFA.INJURED.INJURED_BODY_PART_CODE]: 'claimantInjuredBodyPartCode',
  [CCFFA.INJURED.INJURED_INJURY_CODE]: 'claimantInjuryCode',
  [CCFFA.INJURED.INJURED_INJURY_DESCRIPTION]: 'claimantInjuryDescription',
  [CCFFA.INJURED.INJURED_INJURED_DOING]: 'claimantInjuredDoing',
  [CCFFA.INJURED.INJURED_TAKEN_TO_HOSPITAL_BY_AMBULANCE]: 'isTakenByAmbulanceToHospital',
  [CCFFA.INJURED.INJURED_INJURY_RESULT_IN_DEATH]: 'olympic_didInjuryResultInDeath',
  [CCFFA.INJURED.INJURED_MEMBER_ID]: 'memberId',
  [CCFFA.INJURED.INJURED_NAME_OF_TEAM]: 'nameOfTeam',
  [CCFFA.INJURED.INJURED_STATE_ASSOCIATION]: 'stateAssociationNationwideAffiliate',
  [CCFFA.INJURED.INJURED_NAME_OF_LEAGUE]: 'nameOfLeague',
  [CCFFA.INJURED.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN]:
    'areInjuredPersonCurrentlyEnrolledAccidentPlan',
  // CALLER
  [CCFFA.CALLER.CALLER_FIRST_NAME]: 'caller.firstName',
  [CCFFA.CALLER.CALLER_LAST_NAME]: 'caller.lastName',
  [CCFFA.CALLER.CALLER_EMAIL]: 'claimantEmail',
  [CCFFA.CALLER.CALLER_WORK_PHONE]: 'caller.workPhone',
  [CCFFA.CALLER.CALLER_TYPE]: 'caller.type',
  // DISCLAIMERS
  [CCFFA.DISCLAIMERS.INCIDENT_VICTIM_SIGNATURE]: 'claimantSignature',
};

export const CRAWFORD_CLAIM_FORM_HEALTH_FIELD_API_ALIASES = {
  [CCFFA.HEALTH.HEALTH_INSURANCE_COMPANY_NAME]: 'companyName',
  [CCFFA.HEALTH.HEALTH_INSURANCE_GROUP_NAME]: 'groupName',
  [CCFFA.HEALTH.HEALTH_INSURANCE_POLICY_HOLDERS_NAME]: 'policyHoldersName',
  [CCFFA.HEALTH.HEALTH_INSURANCE_MEMBER_ID]: 'memberId',
  [CCFFA.HEALTH.HEALTH_INSURANCE_POLICY_NUMBER]: 'policyNumber',
};
