import ClaimsProviderConstructor from './ClaimsProvider';
import FileProviderConstructor from './FileProvider';
import OrganizationsProviderConstructor from './OrganizationsProvider';
import UserProviderConstructor from './UserProvider';
import AnalyticsProviderConstructor from './AnalyticsProvider';
import CaptchaProviderConstructor from './CaptchaProvider';
import AppProviderConstructor from './AppProvider';

const ClaimsProvider = new ClaimsProviderConstructor();
const FileProvider = new FileProviderConstructor();
const OrganizationsProvider = new OrganizationsProviderConstructor();
const UserProvider = new UserProviderConstructor();
const AnalyticsProvider = new AnalyticsProviderConstructor();
const CaptchaProvider = new CaptchaProviderConstructor();
const AppProvider = new AppProviderConstructor();

export {
  ClaimsProvider,
  FileProvider,
  OrganizationsProvider,
  UserProvider,
  AnalyticsProvider,
  CaptchaProvider,
  AppProvider,
};
