export default () => ({
  SUPER_ADMIN: {
    items: [
      {
        text: 'Sport Organizations',
        to: {
          name: 'organizations',
        },
      },
      {
        text: 'Broker Organizations',
        to: {
          name: 'brokerOrganizations',
        },
      },
      {
        text: 'FNOL`s',
        to: {
          name: 'fnols',
        },
      },
      {
        text: 'Claims',
        to: {
          name: 'claims',
        },
      },
    ],
  },
  BROKER_ADMIN: {
    items: [
      {
        text: 'Sport Organizations',
        to: {
          name: 'organizations',
        },
      },
      {
        text: 'Broker Organizations',
        to: {
          name: 'brokerOrganizations',
        },
      },
      {
        text: 'FNOL`s',
        to: {
          name: 'fnols',
        },
      },
      {
        text: 'Claims',
        to: {
          name: 'claims',
        },
      },
    ],
  },
  GUEST: {
    items: [{}],
  },
});
