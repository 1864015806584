export default {
  data() {
    return {
      fileUpload: {
        error: null,
        hasError: false,
        loading: false,
        uploads: [],
      },
    };
  },
  computed: {
    uploadIds() {
      return this.fileUpload.uploads.reduce((acc, upload) => {
        if (upload instanceof Object && upload.id) {
          acc.push(upload.id);
        }
        return acc;
      }, []);
    },
  },
  methods: {
    refreshUploads(files) {
      if (files instanceof FileList) {
        const filesMapByName = {};
        files.forEach(file => {
          if (file.name) {
            filesMapByName[file.name] = file;
          }
        });
        this.fileUpload.uploads.filter(
          upload => upload && filesMapByName[upload.fileName] instanceof File
        );
      }
    },
    resetUploadError() {
      this.fileUpload.error = null;
      this.fileUpload.hasError = false;
    },
    setUploadError(error) {
      this.fileUpload.error = error;
      this.fileUpload.hasError = true;
    },
    setUploadLoading(loading) {
      this.fileUpload.loading = !!loading;
    },
    async uploadSingleFile(file) {
      try {
        this.setUploadLoading(true);
        const upload = await this.$store.dispatch('files/uploadFile', file);
        if (upload instanceof Object && upload.id) {
          this.fileUpload.uploads = [upload];
        }
        this.setUploadLoading(false);
        this.resetUploadError();
        return !!this.fileUpload.uploads.length;
      } catch (err) {
        this.setUploadLoading(false);
        this.setUploadError(err);
        return Promise.reject(err);
      }
    },
    async uploadMultipleFiles(payload) {
      try {
        if (payload instanceof Array) {
          if (payload.length) {
            const files = payload.slice().filter(el => el instanceof File);
            const uploadedFiles = payload.slice().filter(el => !!el.path);
            if (files.length) {
              this.setUploadLoading(true);
              const { data } = await this.$store.dispatch('files/uploadFilesArray', files);
              const uploads = (data instanceof Object && data.uploadFiles) || null;
              if (uploads instanceof Array && uploads.length) {
                this.fileUpload.uploads = [...uploadedFiles, ...uploads];
              }
              this.resetUploadError();
              this.setUploadLoading(false);
            } else {
              this.fileUpload.uploads = payload;
            }
          } else {
            this.fileUpload.uploads = [];
          }
        }
        return !!this.fileUpload.uploads.length;
      } catch (err) {
        this.setUploadLoading(false);
        this.setUploadError(err);
        return Promise.reject(err);
      }
    },
  },
};
