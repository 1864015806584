export const SUPER_ADMIN = {
  createSportsUnion: {
    can: true,
  },
  readSportsUnion: {
    can: true,
  },
  updateSportsUnion: {
    can: true,
  },
  deleteSportsUnion: {
    can: true,
  },
  createBrokerOrganization: {
    can: true,
  },
  readBrokerOrganization: {
    can: true,
  },
  updateBrokerOrganization: {
    can: true,
  },
  deleteBrokerOrganization: {
    can: true,
  },
};
