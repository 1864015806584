<template>
  <PhDialog
    v-model="showDialog"
    :contentTextAlign="params.contentTextAlign"
    :fullscreen="params.fullscreen"
    :heading="params.title"
    customClass="ph-global-dialog"
    verticalCenterContent
  >
    <template v-slot:content>
      <component
        v-if="contentIsComponent"
        v-bind="params.description.props"
        :is="params.description.component"
      />
      <div v-else>
        <div v-html="params.description"></div>
        <div v-if="params.status" v-html="params.status" class="ph-dialog__content__status"></div>
      </div>
    </template>
    <template v-slot:footer>
      <v-btn v-if="isConfirm" @click="closeDialog" outlined>
        Cancel
      </v-btn>
      <v-btn @click="resolveDialog(true)">
        {{ params.btnText }}
      </v-btn>
    </template>
  </PhDialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import PhDialog from '@/components/modals/PhDialog';

export default {
  name: 'PhGlobalDialog',
  components: {
    PhDialog,
  },
  props: {
    mobile: Boolean,
  },
  computed: {
    ...mapState('globalDialog', ['shown']),
    ...mapGetters('globalDialog', ['params', 'isConfirm', 'isInfo']),
    contentIsComponent() {
      const { params } = this;
      return params.description instanceof Object && params.description.component instanceof Object;
    },
    showDialog: {
      get() {
        return this.shown;
      },
      set(value) {
        if (value) {
          this.setDialogShown(value);
        } else {
          this.closeDialog();
        }
      },
    },
  },
  methods: {
    ...mapActions('globalDialog', ['resolveDialog', 'setDialogShown', 'closeDialog']),
    handleEscClick(e) {
      if (e.keyCode === 27) {
        this.closeDialog();
      }
    },
    setCloseOnEscListener() {
      document.addEventListener('keydown', this.handleEscClick);
    },
    removeCloseOnEscListener() {
      document.removeEventListener('keydown', this.handleEscClick);
    },
  },
  mounted() {
    this.setCloseOnEscListener();
  },
  beforeDestroy() {
    this.removeCloseOnEscListener();
  },
};
</script>

<style lang="scss">
.ph-global-dialog {
  .ph-dialog__content {
    font-size: 1.86rem;
    .ph-dialog__content__status {
      font-size: 1.6rem;
      margin-top: 0.5rem;
    }
  }
}
</style>
