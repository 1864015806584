import { CRAWFORD_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const HEALTH_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.HEALTH;

export default () => ({
  // aliases for usage in form templates
  HEALTH_FIELD_ALIASES: {
    type: Object,
    default: () => HEALTH_FIELD_ALIASES,
  },
  healthInsuranceForms: {
    type: Object,
    default: () => {},
  },
});
