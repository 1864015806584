<template>
  <button class="dot-menu" @click="handleClick">
    <SvgIcon class="dot-menu__icon" icon="sidebar-menu" />
  </button>
</template>

<script>
export default {
  name: 'DotMenu',
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss">
.dot-menu {
  padding: 20px 20px 15px;
  outline: none;
  box-shadow: none;
}
.dot-menu__icon {
  font-size: 20px;
}
</style>
