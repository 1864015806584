import { CRAWFORD_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const CONTACT_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.CONTACT;

export default () => ({
  // aliases for usage in form templates
  CONTACT_FIELD_ALIASES: {
    type: Object,
    default: () => CONTACT_FIELD_ALIASES,
  },
  [CONTACT_FIELD_ALIASES.CONTACT_FIRST_NAME]: String,
  [CONTACT_FIELD_ALIASES.CONTACT_LAST_NAME]: String,
  [CONTACT_FIELD_ALIASES.CONTACT_EMAIL]: String,
  [CONTACT_FIELD_ALIASES.CONTACT_WORK_PHONE]: String,
});
