import { CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const CLAIMANT_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.CLAIMANT;
const COACH_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.COACH;
const INCIDENT_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.INCIDENT;
const DISCLAIMERS_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.DISCLAIMERS;

export default () => ({
  // aliases for usage in form templates
  CLAIMANT_REVIEW_FIELD_ALIASES: {
    type: Object,
    default: () => ({
      CLAIMANT_FIRST_NAME: CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME,
      CLAIMANT_LAST_NAME: CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME,
      CLAIMANT_FATHERS_FULL_NAME: CLAIMANT_FIELD_ALIASES.CLAIMANT_FATHERS_FULL_NAME,
      CLAIMANT_MOTHERS_FULL_NAME: CLAIMANT_FIELD_ALIASES.CLAIMANT_MOTHERS_FULL_NAME,
      COACH_SIGNATURE: COACH_FIELD_ALIASES.COACH_SIGNATURE,
      COACH_READ_AND_CONFIRMED_FNOL: DISCLAIMERS_FIELD_ALIASES.COACH_READ_AND_CONFIRMED_FNOL,
      INCIDENT_BODY_PART_INJURED: INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED,
      INCIDENT_INJURY_TYPE: INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE,
      INCIDENT_DATE: INCIDENT_FIELD_ALIASES.INCIDENT_DATE,
      INCIDENT_TIME: INCIDENT_FIELD_ALIASES.INCIDENT_TIME,
      INCIDENT_LOCATION: INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION,
    }),
  },
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_FATHERS_FULL_NAME]: String,
  [CLAIMANT_FIELD_ALIASES.CLAIMANT_MOTHERS_FULL_NAME]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_DATE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_TIME]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]: String,
  [COACH_FIELD_ALIASES.COACH_SIGNATURE]: String,
  [DISCLAIMERS_FIELD_ALIASES.COACH_READ_AND_CONFIRMED_FNOL]: Boolean,
});
