import { USASA_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const PROVIDER_FIELD_ALIASES = USASA_CLAIM_FORM_FIELD_ALIASES.PROVIDER;

export default () => ({
  // aliases for usage in form templates
  PROVIDER_FIELD_ALIASES: {
    type: Object,
    default: () => PROVIDER_FIELD_ALIASES,
  },
  providerInformation: Object,
});
