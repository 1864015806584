export default {
  props: {
    items: Array,
    headers: Array,
    height: [String, Number],
    loading: Boolean,
    serverItemsLength: Number,
    sortBy: String,
    sortDesc: [Boolean, String],
    value: Array,
  },
  computed: {
    selectedRows: {
      get() {
        return this.value;
      },
      set(selectedRows) {
        this.$emit('input', selectedRows);
      },
    },
    sortByField: {
      get() {
        return this.sortBy;
      },
      set(val) {
        this.$emit('update:sort-by', val || '');
      },
    },
    sortInDescendingOrder: {
      get() {
        return this.sortDesc === 'DESC';
      },
      set(val) {
        const value = val;
        this.$emit('update:sort-desc', value);
      },
    },
  },
};
