import isValidString from '@/utils/helpers/string/isValidString';

export default value => {
  if (!isValidString(value)) {
    return value;
  }

  const digits = value.replace(/\D/g, '');
  const [areaCode, first, second] = [digits.slice(0, 3), digits.slice(3, 6), digits.slice(6, 10)];
  return `+1 (${areaCode}) ${first}-${second}`;
};
