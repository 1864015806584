import { mapActions, mapGetters } from 'vuex';

export default {
  methods: {
    ...mapActions('app', ['fetchCountries']),
  },
  computed: {
    ...mapGetters('app', ['countriesOptions', 'statesOptions']),
  },
  async created() {
    await this.fetchCountries();
  },
};
