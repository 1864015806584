const DialogResponseInfo = () => import('@/components/modals/partials/DialogResponseInfo');

export default {
  methods: {
    showSuccessResponseDialog(content, btnText = 'Ok') {
      return this.$dialog.info({
        description: {
          component: DialogResponseInfo,
          props: {
            ...(typeof content === 'string' ? { content } : { ...content }),
          },
        },
        btnText,
      });
    },
  },
};
