export default (values, names) => {
  const options = [];
  if (values instanceof Object && names instanceof Object) {
    Object.values(values).forEach(val => {
      if (val && names[val]) {
        options.push({
          text: names[val],
          value: val,
        });
      }
    });
  }
  return options;
};
