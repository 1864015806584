import gql from 'graphql-tag';

const me = gql(`query me {
                        me {
                          id,
                          name,
                          email,
                          phone,
                          photo {
                            id,
                            path,
                            filename,
                            mimetype,
                            encoding,
                          },
                          role,
                          broker {
                            id,
                            name,
                            address {
                              country,
                              city,
                              state,
                              zip,
                              streetAddress,
                              additionalAddress,
                            },
                            createdAt,
                            logo {
                              id,
                              path,
                            },
                          },
                        }
                      }`);

const brokerOrganization = gql(`query brokerOrganization($id: ID) {
                            brokerOrganization(id: $id) {
                              id,
                              name,
                              address {
                                country,
                                city,
                                state,
                                zip,
                                streetAddress,
                                additionalAddress,
                              },
                              users {
                                id,
                                active,
                                name,
                                email,
                              },
                              logo {
                                id,
                                path,
                              },
                              contact {
                                firstName
                                lastName
                                email
                                workPhone
                              }
                            }
                          }`);

const policys = gql(`query policys($brokerId: ID!) {
                            policys(brokerId: $brokerId) {
                              id,
                              brokerId,
                              policyNumber,
                              tpaName,
                              email,
                              effectiveDate,
                              expiredDate,
                              carrier,
                              deductible,
                              coinsurance,
                              benefitPeriod,
                              perInjuryLimit,
                              physicalTherapySublimit
                            }
                          }`);

const sportsUnion = gql(`query sportsUnion($id: ID!) {
                            sportsUnion(id: $id) {
                              id,
                              name,
                              type,
                              broker {
                                id,
                                name,
                              },
                              landingPageSettings {
                                logo {
                                  id
                                  path

                                }
                                plainTextLogoBg {
                                  id
                                  path

                                }
                                bgImage {
                                  id
                                  path

                                }
                                primaryColor
                                secondaryColor
                                stepsBgColor
                                bgColor
                                heroTextColor
                                videoSrc
                                social {
                                  icon
                                  src
                                }
                              }
                              address {
                                country,
                                city,
                                state,
                                zip,
                                streetAddress,
                                additionalAddress,
                              },
                              url,
                              private,
                              olympic,
                              logo {
                                id,
                                path,
                              },
                              form,
                              formIsInjuryOnly
                              stateAdmins {
                                id,
                                affiliate,
                                contact,
                                email,
                              }
                            }
                          }`);

const brokerOrganizations = gql(`query brokerOrganizations {
                        brokerOrganizations {
                          id,
                          name,
                          address {
                            country,
                            city,
                            state,
                            zip,
                            streetAddress,
                            additionalAddress,
                          },
                          createdAt,
                          logo {
                            id,
                            path,
                          },
                          contact {
                            firstName
                            lastName
                            email
                            workPhone
                          }
                        }
                      }`);

const sportsUnions = gql(`query sportsUnions {
                        sportsUnions {
                          id,
                          name,
                          landingPageSettings {
                            logo {
                              id
                              path
                            }
                          }
                          address {
                            country,
                            city,
                            state,
                            zip,
                            streetAddress,
                            additionalAddress,
                          },
                          private,
                          createdAt,
                          logo {
                            id,
                            path,
                          },
                        }
                      }`);

const sportsUnionByUrl = gql(`query sportsUnionByUrl($url: String!) {
                                sportsUnionByUrl(url: $url) {
                                  id
                                  name
                                  olympic
                                  broker {
                                    id
                                    name
                                    contact {
                                      firstName
                                      lastName
                                      email
                                      workPhone
                                    }
                                  }
                                  landingPageSettings {
                                    logo {
                                      id
                                      path
                                    }
                                    plainTextLogoBg {
                                      id
                                      path
                                    }
                                    bgImage {
                                      id
                                      path
                                    }
                                    primaryColor
                                    secondaryColor
                                    stepsBgColor
                                    heroTextColor
                                    bgColor
                                    videoSrc
                                    social {
                                      icon
                                      src
                                    }
                                  }
                                  parentSportsUnion {
                                    id
                                    name
                                  }
                                  type
                                  url
                                  private
                                  logo {
                                    path
                                    filename
                                  },
                                  form
                                  formIsInjuryOnly
                                  address {
                                    country,
                                    city,
                                    state,
                                    zip,
                                    streetAddress,
                                    additionalAddress,
                                  },
                                  stateAdmins {
                                    id,
                                    affiliate,
                                    contact,
                                    email,
                                  }
                                }
                              }`);

const claim = gql(`query claim($id: ID!) {
                    claim(id: $id) {
                      id
                      olympic
                      usasa
                      note
                      fnolNumber
                      claimNumber
                      nameOfLeague
                      injuredPersonType,
                      injuryAccidentOccur,
                      haveMedicalOrHCOrEnrolledIndividualOrEmployeeOrDepMem,
                      nagha_claimantCheckbox1,
                      nagha_claimantCheckbox2,
                      sportsUnion {
                       id,
                       name,
                       form,
                       logo {
                        id,
                        path,
                       }
                       stateAdmins {
                          id,
                          affiliate,
                          contact,
                          email,
                        }
                      }
                      fnolStatus
                      status
                      createdAt
                      signedAt
                      signedBy
                      processedBy
                      processedAt
                      completedBy
                      completedAt
                      coachDeclineReason
                      fnolDeclineReason
                      claimDeclineReason
                      claimantLastName
                      claimantFirstName
                      claimantMiddleName
                      claimantDOB
                      claimantAge
                      claimantGender
                      claimantEmail
                      claimantCoachPlayerType
                      claimantAddress {
                        country,
                        city,
                        state,
                        zip,
                        streetAddress,
                        additionalAddress,
                      },
                      claimantFathersFullName
                      claimantMothersFullName
                      claimantRoleType
                      memberId
                      haveYouAlreadySeenAProvider
                      providerInformation {
                        providerName
                        providerAddress
                        providerEmail
                        providerPhoneNumber
                      }
                      insuranceCompany
                      policyHoldersName
                      policyNumber
                      groupNumber
                      incidentDate
                      incidentTime
                      incidentLocation
                      membershipType
                      membershipOtherType
                      eventType
                      isContact
                      contactType
                      contactOtherType
                      injuredBodyPart
                      injuredBodySide
                      injuryType
                      severity
                      isReceivedOnsite
                      isTakenByAmbulanceToHospital
                      nameOfEventOrTeamsCompetingAgainst
                      injuryDescription
                      clubNameOfInjured
                      territoryWhereIncidentOccurred
                      isAthleteWasTreatedByCoach
                      stateAdmin
                      claimantSignature
                      coachSignature
                      coachEmail
                      coachFirstName
                      coachLastName
                      coachPhone
                      files {
                        path,
                        filename,
                        mimetype,
                        id
                      }
                      plainSurface
                      olympic_socialSecurityNumber
                      olympic_street
                      olympic_city
                      olympic_state
                      olympic_zip
                      olympic_claimantParentsFullName
                      olympic_claimantParentsAddress
                      olympic_claimantParentsPhone
                      olympic_haveMedicalCare
                      olympic_dentalInvolvedTeeth
                      olympic_dentalConditionTeeth
                      olympic_didInjuryResultInDeath
                      olympic_nameOfTeam
                      olympic_checkbox1
                      olympic_checkbox2
                      olympic_checkbox3
                      olympic_claimantRoleType
                      claimantHomePhone
                      claimantWorkPhone
                      claimantStatus
                      isInjuredPersonAMedicareMedicaidBeneficiary
                      SSNorHIDNumber
                      areInjuredPersonCurrentlyEnrolledAccidentPlan
                      healthInsurancePlan {
                        companyName,
                        groupName
                        policyHoldersName
                        memberId
                        policyNumber
                      }
                      employerOrSpousesEmployerOrBursarsOfficeEmailAddress
                      injuryOccurredAt
                      injuryOccurredAtOther
                      nameAndTypeOfEvent
                      injuryOccurredOn
                      nameOfTeam
                      stateAssociationNationwideAffiliate
                      region
                      usasa_claimantCheckbox1
                      playersHealthPolicyNumber
                      policyState
                      lossType
                      glCauseCode
                      glDescriptionCode
                      lossLocation {
                        name
                        isOnPremises
                        address {
                          country
                          state
                          city
                          zip
                          streetAddress
                          additionalAddress
                        }
                      }
                      riskLocation {
                        name
                        workPhone
                        address {
                          country
                          state
                          city
                          zip
                          streetAddress
                          additionalAddress
                        }
                      }
                      properties {
                        description
                        damageDescription
                        owner {
                          type
                          name
                          firstName
                          lastName
                          workPhone
                          homePhone
                          address {
                            country
                            state
                            city
                            zip
                            streetAddress
                            additionalAddress
                          }
                        }
                      }
                      claimantInjuredBodyPartCode
                      claimantInjuryCode
                      claimantInjuryDescription
                      claimantInjuredDoing
                      caller {
                        firstName
                        lastName
                        workPhone
                        type
                      }
                      exported
                    }
                  }`);

const claims = gql(`query claims ($claimWhereFilter: ClaimWhereFilter, $sortInput: SortInput) {
                      claims (claimWhereFilter: $claimWhereFilter, sortInput: $sortInput) {
                        id,
                        claimNumber,
                        status,
                        note,
                        insuranceCompany,
                        claimantFirstName,
                        claimantMiddleName,
                        claimantLastName,
                        claimantEmail,
                        claimantGender,
                        claimantAddress {
                          country,
                          state,
                          city,
                          zip,
                          streetAddress,
                        },
                        healthInsurancePlan {
                          companyName,
                          groupName
                          policyHoldersName
                          memberId
                          policyNumber
                        },
                        createdAt,
                        memberId,
                        olympic,
                        olympic_street,
                        olympic_city,
                        olympic_state,
                        olympic_zip,
                        stateAdmin
                      }
                    }`);
const claimByToken = gql(`
            query claimByCoachToken($token: String!) {
                claimByCoachToken(token: $token) {
                    id,
                    fnolNumber,
                    claimNumber,
                    stateAdmin,
                    note,
                    sportsUnion {
                      id,
                      name,
                      type,
                      stateAdmins {
                        id,
                        affiliate,
                        contact,
                        email,
                      }
                      broker {
                        id,
                        name,
                      },
                      address {
                        country,
                        city,
                        state,
                        zip,
                        streetAddress,
                        additionalAddress,
                      },
                      logo {
                        id,
                        path,
                        filename
                      },
                    },
                    fnolStatus,
                    createdAt,
                    claimantLastName,
                    claimantFirstName,
                    claimantMiddleName,
                    claimantDOB,
                    claimantAge,
                    claimantGender,
                    claimantEmail,
                    claimantAddress {
                      country,
                      city,
                      state,
                      zip,
                      streetAddress,
                    },
                    claimantFathersFullName,
                    claimantMothersFullName,
                    claimantRoleType,
                    insuranceCompany,
                    policyHoldersName,
                    policyNumber,
                    groupNumber,
                    incidentDate,
                    incidentTime,
                    incidentLocation,
                    membershipType,
                    membershipOtherType,
                    eventType,
                    contactType,
                    contactOtherType,
                    injuredBodyPart,
                    injuredBodySide,
                    injuryType,
                    severity,
                    isReceivedOnsite,
                    injuryDescription,
                    claimantSignature,
                    coachSignature,
                    coachEmail,
                    coachFirstName,
                    coachLastName,
                    coachPhone,
                    olympic_claimantRoleType
                }
  }`);

const fnols = gql(`query fnols ($fnolWhereFilter: FnolWhereFilter, $sortInput: SortInput) {
                      fnols (fnolWhereFilter: $fnolWhereFilter, sortInput: $sortInput) {
                        id,
                        fnolNumber,
                        fnolStatus,
                        status,
                        insuranceCompany,
                        claimantFirstName,
                        claimantMiddleName,
                        claimantLastName,
                        claimantEmail,
                        claimantGender,
                        claimantAddress {
                          country,
                          state,
                          city,
                          zip,
                          streetAddress,
                        },
                        healthInsurancePlan {
                          companyName,
                          groupName
                          policyHoldersName
                          memberId
                          policyNumber
                        },
                        createdAt,
                        memberId,
                        olympic,
                        olympic_street,
                        olympic_city,
                        olympic_state,
                        olympic_zip,
                      }
                    }`);

const countries = gql(`query countries {
                      countries {
                        code,
                        name,
                        states {
                          name
                          code
                        }
                      }
                    }`);

const generateCrawfordXML = gql(`query generateClaimCrawfordXml($id: ID!) {
                                  generateClaimCrawfordXml(id: $id)
                                }`);

const getClaimPdfUrl = gql(`query getClaimPdfUrl($id: ID!) {
                              getClaimPdfUrl(id: $id)
                            }`);

export {
  me,
  brokerOrganization,
  brokerOrganizations,
  policys,
  sportsUnions,
  sportsUnion,
  sportsUnionByUrl,
  claims,
  claim,
  fnols,
  claimByToken,
  countries,
  generateCrawfordXML,
  getClaimPdfUrl,
};
