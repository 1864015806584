import formatPhoneNumber from '@/utils/helpers/string/formatPhoneNumber';

export const properBrokerOrgInfo = org => {
  let structuredOrg = {};
  if (org instanceof Object && Object.values(org).length) {
    const {
      name,
      address: { country, city, state, zip, streetAddress },
      users,
    } = org;

    let admins = [];

    if (users && users.length) {
      admins = users.reduce((acc, user, i) => {
        if (user instanceof Object && user.active && user.name && user.email) {
          acc[`${i + 1}. Admin Full Name`] = `${user.name} (${user.email})`;
        }
        return acc;
      }, {});
    }

    const contact = org.contact || {};

    structuredOrg = {
      name,
      country,
      state,
      city,
      zip,
      street: streetAddress,
      'Contact First Name': contact.firstName,
      'Contact Last Name': contact.lastName,
      'Contact Email': contact.email,
      'Contact Phone Number': formatPhoneNumber(contact.workPhone),
      ...admins,
    };
    return structuredOrg;
  }
  return structuredOrg;
};
