const CREATE_CLAIM_INPUT = {
  sportsUnionId: 'ID!',
  playersHealthPolicyNumber: 'String',
  policyState: 'String!',
  lossType: 'LossType!',
  // glCauseCode: 'String!',
  // glDescriptionCode: 'String!',
  incidentDate: 'String!',
  incidentTime: 'String!',
  injuryDescription: 'String!',
  lossLocation: 'LossLocationInput!',
  eventType: 'EventType',
  nameAndTypeOfEvent: 'String',
  riskLocation: 'RiskLocationInput!',
  properties: 'PropertyListInput',
  claimantFirstName: 'String!',
  claimantLastName: 'String!',
  claimantAddress: 'AddressInput!',
  claimantDOB: 'String!',
  claimantGender: 'GenderType!',
  claimantEmail: 'String!',
  claimantInjuredBodyPartCode: 'BSIBodyPartCode!',
  claimantInjuryCode: 'BSIInjuryCode!',
  claimantInjuryDescription: 'String!',
  claimantInjuredDoing: 'String!',
  isTakenByAmbulanceToHospital: 'Boolean!',
  olympic_didInjuryResultInDeath: 'Boolean!',
  memberId: 'String',
  nameOfLeague: 'String',
  nameOfTeam: 'String',
  stateAssociationNationwideAffiliate: 'String',
  caller: 'CallerInput!',
  claimantSignature: 'String!',
  areInjuredPersonCurrentlyEnrolledAccidentPlan: 'Boolean!',
  healthInsurancePlan: '[HealthInsurancePlanInput!]',
};

export default form => {
  if (!(form instanceof Object)) {
    throw new Error('The provided form argument is not a valid Object');
  }
  return Object.entries(form).reduce((acc, [field, value]) => {
    if (CREATE_CLAIM_INPUT[field]) {
      acc[field] = value;
    }
    return acc;
  }, {});
};
