import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import store from './store';

// const appLocale = store.getters['app/appLocale'];
// I18n
// @see http://kazupon.github.io/vue-i18n
Vue.use(VueI18n);

const messages = {
  ENG: {
    ui: {
      providerInfo: 'Provider info',
      injuredPersonInfo: 'Injured person information',
      primaryHealthInsurance: 'Family / Primary Health Insurance',
      claimantAddress: 'Claimant Address',
      healthInsurancePlan: 'Health insurance plan',
      timePlaceDetailsIncident: 'TIME, PLACE AND DETAILS OF INCIDENT',
      certifiedCoachOrOfficial: 'Certified Coach or Official',
      isInjuredPersonMedicaidBeneficiary: 'Is the injured person a Medicare/Medicaid beneficiary?*',
      yes: 'Yes',
      no: 'No',
      ssnOrHIDNumber: 'Social Security number or Health I.D. number',
      currentDate: 'Current date:',
      submit: 'Submit',
      injuredPersonCurrentlyEnrolledAccidentPlan:
        'Is the injured person currently enrolled in any health insurance and/or other soccer accident plan?',
      delete: 'Delete',
      addAnotherPlan: 'Add Another Plan',
      employerBursarEmailAddress: 'Employer, spouse’s employer, email address',
      ifYouAreNotEnrolledInsurancePlan:
        'If you are not enrolled in any health insurance plan, we require written verification from your employer and your spouse’s employer.',
      parentOrGuardianSignatureIfClaimantUnder18:
        'Parent or Guardian signature if claimant is under 18 years old.',
    },
    fields: {
      didAccidentOccur: 'Did Accident Occur',
      participant: 'Participant',
      staffMember: 'Staff member',
      other: 'Other',
      providerName: "Provider's Name",
      providerAddress: "Provider's Address",
      providerEmail: "Provider's Email",
      providerPhoneNumber: "Provider's Phone number",
      haveAlreadySeenAProvider: 'Have you already seen a provider?',
      coach: 'Coach',
      player: 'Player',
      addressFirst: 'Address 1',
      addressSecond: 'Address 2',
      city: 'City',
      country: 'Country',
      state: 'State',
      zip: 'Zip',
      emailAddress: 'Email address',
      homePhoneNumber: 'Home phone number',
      workPhoneNumber: 'Work phone number',
      injuredPersonFirstName: 'Injured Person First Name',
      injuredPersonLastName: 'Injured Person Last Name',
      injuredPersondDOB: 'Injured Person Date of Birth',
      injuredPersondGender: 'Injured Person Gender',
      status: 'Status*',
      single: 'Single',
      married: 'Married',
      fullTimeStudent: 'Full-time student',
      dateOfIncident: 'Date of incident',
      timeOfIncident: 'Time of incident',
      locationOfIncident: 'Location of incident',
      bodyPartInjured: 'Body part Injured',
      bodySideInjured: 'Body side Injured',
      injuryType: 'Injury type',
      injuryOccuredOn: 'Injury occured on',
      injuryOccuredAt: 'Injury occured at',
      nameAndTypeOfEvent: 'Name and type of event',
      describeWhatHappend: 'Describe what happend',
      nameOfTeam: 'Name of team',
      nameOfLeague: 'Name of league',
      stateAssociationNationwideAffiliate: 'State Association/Nationwide affiliate',
      stateAssociationAffiliate: 'State Association/Affiliate',
      stateAssociation: 'State Association',
      region: 'Region',
      leagueName: 'League Name',
      claimantSignature: 'Claimant signature',
      coachOrSomeoneEmail: 'Email address of coach or manager email',
      coachEmail: 'Coach or Official Email',
      coachFullName: 'Coach or Official Name',
      coachFirstName: 'Coach or Official First Name',
      coachLastName: 'Coach or Official Last Name',
      companyName: 'Company Name',
      groupName: 'Group Name',
      policyHoldersName: 'Policyholders Name',
      policyNumber: 'Policy Number',
      memberId: 'Member ID',
    },
    messages: {
      deactivateUserConfirmation:
        'Are you sure you want to revoke access to the application for this user?',
      activateUserConfirmation:
        'Are you sure you want to grant access to the application for this user?',
      usasaTitle: '- SPECIAL CLAIM FORM FOR RISK ACCIDENT',
      usasaSubtitle:
        '<p>Another FNOL/claim form should be integrated for a new sports union org. </p><p>Below you can find the list of fields. </p><p>This form is filed by an adult athlete, which means that the injured person is the same as Claimant.</p>',
      usasaCheckboxMsg:
        'I hereby certify the foregoing statements made by me on this form to be true to the best of my knowledge. I am aware that if any of the foregoing statements on this form made by me are willfully false, I may be subject to penalties, which may include prosecution.',
      fysaCheckboxMsg:
        'I hereby certify the foregoing statements made by me on this form to be true to the best of my knowledge. I am aware that if any of the foregoing statements on this form made by me are willfully false, I may be subject to penalties, which may include prosecution.',
      naghaCheckboxMsg1:
        'I authorize medical payments to physician or supplier for services described on any attached statements enclosed. If not signed, please provide proof of payment.',
      naghaCheckboxMsg2: `
          <p>
            I authorize any physician, medical professional, hospital, covered entity as defined under HIPAA, insurer or other organization or person having any records, dates or information concerning the claimant to disclose when requested to do so, all information with respect to any injury, policy coverage, medical history, consultation, prescription or treatment, and copies of all hospital or medical records or all such records in their entirety to NAHGA Claim Services. A photo static copy of this authorization shall be considered as effective and valid as the original.
          </p>
          <p>
            I agree that should it be determined at a later date there is other insurance (or similar), to reimburse AXIS Insurance Company to the extent of any amount collectible.
          </p>
          <p>
            I understand that any person who knowingly and with the intent to defraud or deceive any insurance company; files a claim containing any material by false, incomplete or misleading information may be subject to prosecution for insurance fraud.*
          </p>
        `,
      coloradomtbCheckboxMsg:
        'I hereby certify the foregoing statements made by me on this form to be true to the best of my knowledge. I am aware that if any of the foregoing statements on this form made by me are willfully false, I may be subject to penalties, which may include prosecution.',
    },
  },
  ESP: {
    ui: {
      providerInfo: 'Provider info',
      injuredPersonInfo: 'Información de la persona lesionada',
      primaryHealthInsurance: 'Seguro de salud familiar / primario',
      claimantAddress: 'Claimant Address',
      healthInsurancePlan: 'Seguro de Salud Familiar/Primaro',
      timePlaceDetailsIncident: 'Hora, lugar y detalles del incidente',
      certifiedCoachOrOfficial: 'Entrenador u oficial certificado',
      currentDate: 'Fecha y hora actual:',
      isInjuredPersonMedicaidBeneficiary:
        'La persona lesionada es beneficiaria de Medicare / Medicaid?*',
      yes: 'Si',
      no: 'No',
      submit: 'ENVIAR',
      ssnOrHIDNumber: 'Número de seguro social o identificación de salud. número',
      injuredPersonCurrentlyEnrolledAccidentPlan:
        'La persona lesionada está actualmente inscrita en algún seguro de salud u otro plan de accidentes de fútbol?',
      delete: 'Delete',
      addAnotherPlan: 'Agregar otro plan',
      employerBursarEmailAddress:
        'Empleador, empleador del cónyuge o dirección de correo electrónico de la oficina del ecónomo',
      ifYouAreNotEnrolledInsurancePlan:
        'Si no está inscrito en ningún plan de seguro de salud, requerimos una verificación por escrito de su empleador y del empleador de su cónyuge (si corresponde), o de la oficina del Tesorero si es un estudiante universitario de tiempo completo.',
      parentOrGuardianSignatureIfClaimantUnder18:
        'Parent or Guardian signature if claimant is under 18 years old.',
    },
    fields: {
      didAccidentOccur: 'Did Accident Occur',
      participant: 'Participant',
      staffMember: 'Staff member',
      other: 'Other',
      providerName: 'Provider name',
      providerAddress: 'Provider address',
      providerEmail: 'Provider email',
      providerPhoneNumber: 'Provider phone number',
      haveAlreadySeenAProvider: 'Have you already seen a provider?',
      coach: 'Coach',
      player: 'Player',
      addressFirst: 'Habla a 1',
      addressSecond: 'Habla a 2',
      city: 'Ciudad',
      country: 'Country',
      state: 'Estado',
      zip: 'CÓDIGO POSTAL',
      emailAddress: 'Dirección de correo electrónico',
      homePhoneNumber: 'Número de teléfono de casa',
      workPhoneNumber: 'Número de teléfono del trabajo',
      injuredPersonFirstName: 'Nombre de la Persona Lesionada',
      injuredPersonLastName: 'Apellido de la persona lesionada',
      injuredPersondDOB: 'Persona lesionada Fecha de nacimient',
      injuredPersondGender: 'Persona Lesionada Género',
      status: 'Estado*',
      single: 'Soltero (a)',
      married: 'Casado (a)',
      fullTimeStudent: 'Estudiante a tiempo completo',
      dateOfIncident: 'Fecha del incidente',
      timeOfIncident: 'Hora del incidente',
      locationOfIncident: 'Lugar del incidente',
      bodyPartInjured: 'Parte del cuerpo lesionada',
      bodySideInjured: 'Lado del cuerpo lesionado',
      injuryType: 'Tipo de lésion',
      injuryOccuredOn: 'La lesión ocurrió en',
      injuryOccuredAt: 'Injury Occured At',
      nameAndTypeOfEvent: 'Nombre y tipo de evento.',
      describeWhatHappend: 'Describa lo que pasó',
      nameOfTeam: 'Nombre del equipo',
      nameOfLeague: 'Nombre de liga',
      stateAssociationNationwideAffiliate: 'Asociación estatal / afiliada nacional',
      stateAssociationAffiliate: 'State Association/Affiliate',
      stateAssociation: 'State Association',
      region: 'Región',
      claimantSignature: 'Firma del reclamante',
      coachOrSomeoneEmail:
        'Dirección de correo electrónico del entrenador, gerente o árbitro presente en el momento del accidente',
      coachEmail: 'Coach or Official Email',
      coachFullName: 'Coach or Official Name',
      coachFirstName: 'Coach or Official First Name',
      coachLastName: 'Coach or Official Last Name',
      companyName: 'Nombre de empresa',
      groupName: 'Nombre del grupo',
      policyHoldersName: 'Nombre del titular de la póliza',
      policyNumber: 'Número de póliza',
      memberId: 'Identificación de miembro',
    },
    messages: {
      usasaTitle: '– FORMULARIO DE RECLAMACIÓN ESPECIAL POR ACCIDENTE DE RIESGO',
      usasaSubtitle:
        '<p>Se debe integrar otro formulario FNOL / reclamo para una nueva organización de unión deportiva.</p> <p>A continuación puede encontrar la lista de campos.</p> <p>Este formulario lo presenta un atleta adulto, lo que significa que la persona lesionada es la misma que el Reclamante.</p>',
      usasaCheckboxMsg:
        'Por la presente certifico que las declaraciones anteriores hechas por mí en este formulario son verdaderas a lo mejor de mi conocimiento. Soy consciente de que si alguno de los las declaraciones anteriores en este formulario hechas por mí son deliberadamente falsas, puedo estar sujeto a sanciones, que pueden incluir el enjuiciamiento.',
      fysaCheckboxMsg:
        'Por la presente certifico que las declaraciones anteriores hechas por mí en este formulario son verdaderas a lo mejor de mi conocimiento. Soy consciente de que si alguno de los las declaraciones anteriores en este formulario hechas por mí son deliberadamente falsas, puedo estar sujeto a sanciones, que pueden incluir el enjuiciamiento.',
      naghaCheckboxMsg1:
        'I authorize medical payments to physician or supplier for services described on any attached statements enclosed. If not signed, please provide proof of payment.',
      naghaCheckboxMsg2: `
          <p>
            I authorize any physician, medical professional, hospital, covered entity as defined under HIPAA, insurer or other organization or person having any records, dates or information concerning the claimant to disclose when requested to do so, all information with respect to any injury, policy coverage, medical history, consultation, prescription or treatment, and copies of all hospital or medical records or all such records in their entirety to NAHGA Claim Services. A photo static copy of this authorization shall be considered as effective and valid as the original.
          </p>
          <p>
            I agree that should it be determined at a later date there is other insurance (or similar), to reimburse AXIS Insurance Company to the extent of any amount collectible.
          </p>
          <p>
            I understand that any person who knowingly and with the intent to defraud or deceive any insurance company; files a claim containing any material by false, incomplete or misleading information may be subject to prosecution for insurance fraud.*
          </p>
        `,
      coloradomtbCheckboxMsg:
        'Por la presente certifico que las declaraciones anteriores hechas por mí en este formulario son verdaderas a lo mejor de mi conocimiento. Soy consciente de que si alguno de los las declaraciones anteriores en este formulario hechas por mí son deliberadamente falsas, puedo estar sujeto a sanciones, que pueden incluir el enjuiciamiento.',
    },
  },
};

const i18n = new VueI18n({
  locale: 'ENG',
  messages,
});

export default i18n;
