import Vue from 'vue';
import title from 'title';
import formatAddress from '@/utils/helpers/string/formatAddress';
import store from '@/store';

Vue.prototype.$capitalize = title;
Vue.prototype.$capitalizeStart = string => {
  if (typeof string === 'string') {
    if (string.length) {
      return `${string[0].toUpperCase()}${string.slice(1, string.length)}`;
    }
    return string;
  }
  throw new Error('The argument provided to $capitalizeStart must be a string');
};
Vue.prototype.$dialog = {
  confirm: params => store.dispatch('globalDialog/showConfirmDialog', params),
  close: () => store.dispatch('globalDialog/closeDialog'),
  error: params => store.dispatch('globalDialog/showErrorDialog', params),
  info: params => store.dispatch('globalDialog/showInfoDialog', params),
};
Vue.prototype.$formatAddress = formatAddress;
Vue.prototype.$userIs = roles => {
  return roles.includes(store.getters['user/userRole']);
};
