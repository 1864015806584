import isValidString from '@/utils/helpers/string/isValidString';

export default (filters = {}) => {
  const output = {};
  const outputSort = {};
  try {
    const {
      organizations,
      searchQuery,
      dateFrom,
      dateTo,
      status,
      sortBy,
      sortDesc,
      claimantName,
    } = filters;

    if (isValidString(searchQuery)) {
      output.claimNumber = searchQuery;
    }
    if (isValidString(dateFrom)) {
      output.dateFrom = dateFrom;
    }
    if (isValidString(dateTo)) {
      output.dateTo = dateTo;
    }
    if (isValidString(status) && status !== 'all') {
      output.status = status;
    }
    if (isValidString(claimantName)) {
      output.claimantName = claimantName;
    }
    if (organizations.length) {
      output.sportsUnionIds = organizations;
    }
    outputSort.field = sortBy;
    outputSort.direction = sortDesc;
  } catch (error) {
    console.error(error);
  }
  return {
    output,
    outputSort,
  };
};
