const env = {
  API_URL: process.env.VUE_APP_API_URL || 'https://api-dev.playershealthclaims.com/',
  APP_URL: process.env.VUE_APP_URL || 'https://app-dev.playershealthclaims.com/',
  RECAPTCHA_SITEKEY:
    process.env.VUE_APP_RECAPTCHA_SITEKEY || '6LeuYs0UAAAAAKyfzeSGWwC0diAauhQlqzL9d7lg',
  DEVELOPMENT: process.env.NODE_ENV === 'development',
};

const themes = {
  light: {
    accent: '#1DE08D',
    accentGradientFrom: '#17DF90',
    accentGradientTo: '#8BF153',
    backgroundLight: '#ffffff',
    error: '#ff426b',
    // info: '',
    primary: '#000000',
    secondary: '#878EA4',
    success: '#1DE08D',
    content: '#F5F6FA',
    alternative: '#8E8E8E',
    // warning: '',
  },
};

export { env, themes };
