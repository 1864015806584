import BaseProvider from '@/graphql/providers/BaseProvider';
import { UserProvider } from '@/graphql/providers';
import { PROFILE_FORM_FIELD_API_ALIASES } from '@/utils/constants';
import isValidString from '@/utils/helpers/string/isValidString';

export default {
  async updateUser({ commit }, { id: userId, form }) {
    try {
      const {
        data: {
          updateUser: { id, email, name, phone, role, photo },
        },
      } = await UserProvider.updateUser(userId, {
        [PROFILE_FORM_FIELD_API_ALIASES.NAME]: form[PROFILE_FORM_FIELD_API_ALIASES.NAME],
        [PROFILE_FORM_FIELD_API_ALIASES.EMAIL]: form[PROFILE_FORM_FIELD_API_ALIASES.EMAIL],
        photoId: form[PROFILE_FORM_FIELD_API_ALIASES.PHOTO].id,
      });
      commit('SET_PROFILE', {
        id,
        email,
        name,
        phone,
        role,
        photo,
      });
    } catch (err) {
      return Promise.reject(err);
    }
    return true;
  },
  async changePassword(context, { currentPassword, newPassword, confirmPassword }) {
    try {
      await UserProvider.changePassword({
        currentPassword,
        newPassword,
        confirmPassword,
      });
    } catch (err) {
      return Promise.reject(err);
    }
    return true;
  },
  async changePasswordComplete(context, token) {
    try {
      await UserProvider.changePasswordComplete({
        token,
      });
    } catch (err) {
      return Promise.reject(err);
    }
    return true;
  },
  async authUser({ commit, dispatch, getters }, { email, password }) {
    if (
      typeof email === 'string' &&
      email.length &&
      typeof password === 'string' &&
      password.length
    ) {
      try {
        const data = await UserProvider.authorizeUser(email, password);
        if (data && data.data instanceof Object && typeof isValidString(data.data.login)) {
          commit('SET_AUTH_TOKEN', data.data.login);
        }
        if (getters.authed) {
          const { me } = await dispatch('fetchUser');
          return me instanceof Object;
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return new Error("The required arguments weren't provided");
  },
  async fetchUser({ commit, dispatch, state }) {
    try {
      if (!state.token) {
        commit('SET_AUTH_TOKEN', localStorage.getItem('token'));
      }

      const currentToken = state.token;
      if (currentToken) {
        const { data } = await UserProvider.getUser();
        if (data instanceof Object && data.me instanceof Object) {
          dispatch('initUnauthorizedLogoutListener');
          const { id, email, name, phone, role, photo, broker } = data.me;
          commit('SET_PROFILE', {
            id,
            email,
            name,
            phone,
            role,
            photo,
            broker,
          });
        }
        return data;
      }
      throw new Error("Token wasn't present");
    } catch (err) {
      return Promise.reject(err);
    }
  },
  fetchUserByToken({ commit, dispatch }, token) {
    if (isValidString(token)) {
      commit('SET_AUTH_TOKEN', token);
      return dispatch('fetchUser');
    }
    return Promise.reject(new Error("The required parameter 'token' is not valid"));
  },
  logout({ commit }, force = false) {
    commit('SET_AUTH_TOKEN');
    commit('RESET_STATE');
    BaseProvider.resetCache();
    if (
      force &&
      window instanceof Object &&
      window.location instanceof Object &&
      typeof window.location.reload === 'function'
    ) {
      window.location.reload();
    }
    return Promise.resolve(true);
  },
  async restorePasswordByEmail(context, email) {
    try {
      if (!isValidString(email)) {
        throw new Error("The required 'email' argument wasn't provided or was invalid");
      }
      const { data } = await UserProvider.restoreUserPasswordByEmail(email);
      if (!data.restore) {
        throw new Error(
          "Couldn't restore password for the provided email. Please make sure the email is correct."
        );
      }
      return data.restore;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
  initUnauthorizedLogoutListener({ dispatch }) {
    // set an event listener for unauthorizedError
    BaseProvider.on('unauthorizedError', () => {
      dispatch('logout', true);
    });
  },
  async updatePasswordByToken({ dispatch }, { token, password, confirmPassword }) {
    try {
      if (!isValidString(token)) {
        throw new Error("The required 'token' argument wasn't provided or was invalid");
      }
      if (!isValidString(password)) {
        throw new Error("The required 'password' argument wasn't provided or was invalid");
      }
      if (!isValidString(confirmPassword)) {
        throw new Error("The required 'password' argument wasn't provided or was invalid");
      }
      const response = await UserProvider.updateUserPasswordByToken(
        token,
        password,
        confirmPassword
      );
      const validTokenResponse = !!(
        response &&
        response.data instanceof Object &&
        isValidString(response.data.newPassword)
      );
      if (validTokenResponse) {
        await dispatch('fetchUserByToken', response.data.newPassword);
      }
      return validTokenResponse;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  },
};
