import { OrganizationsProvider } from '@/graphql/providers';
import isValidString from '@/utils/helpers/string/isValidString';
import { SPORTS_UNION_TYPES } from '@/utils/constants';

export default {
  fetchSportsUnionByUrl(context, url) {
    if (!isValidString(url)) {
      return Promise.reject(new Error("The provided 'url' argument was invalid or absent"));
    }
    return OrganizationsProvider.getSportsUnionByUrl(url);
  },
  fetchSportsUnions({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true);
      OrganizationsProvider.fetchSportsUnions()
        .then(response => {
          if (
            response.data &&
            response.data.sportsUnions &&
            response.data.sportsUnions instanceof Array
          ) {
            commit('UPDATE_ORGANIZATIONS', response.data.sportsUnions);
          }
          commit('SET_LOADED', true);
          resolve(response);
        })
        .catch(error => {
          commit('SET_LOADED', false);
          reject(error);
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    });
  },
  async createSportsUnion({ dispatch }, input) {
    try {
      await OrganizationsProvider.createSportsUnion({
        ...input,
        olympic: input.form === SPORTS_UNION_TYPES.OLYMPIC,
      });
      const response = await dispatch('fetchSportsUnions');
      return !!response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async updateSportsUnion({ dispatch }, object) {
    try {
      await OrganizationsProvider.updateSportsUnion({
        id: object.id,
        updateSportsUnionInput: object.form,
      });
      await dispatch(
        'app/populateOrganizationSidebarInfo',
        {
          id: object.id,
        },
        { root: true }
      );
      const response = await dispatch('fetchSportsUnions');
      return !!response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async deleteSportsUnion(context) {
    const { id } = context.rootGetters['app/infoSidebarData'];
    try {
      await OrganizationsProvider.deleteSportsUnion(id);
      const response = await context.dispatch('fetchSportsUnions');
      return !!response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async addStateAdmin({ dispatch }, object) {
    try {
      await OrganizationsProvider.addSportsUnionStateAdmin({
        id: object.id,
        stateAdminInput: object.form,
      });
      await dispatch(
        'app/populateOrganizationSidebarInfo',
        {
          id: object.id,
        },
        { root: true }
      );
      const response = await dispatch('fetchSportsUnions');
      return !!response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  async removeStateAdmin({ dispatch }, object) {
    try {
      await OrganizationsProvider.removeSportsUnionStateAdmin({
        id: object.id,
        stateAdminId: object.stateAdminId,
      });
      await dispatch(
        'app/populateOrganizationSidebarInfo',
        {
          id: object.id,
        },
        { root: true }
      );
      const response = await dispatch('fetchSportsUnions');
      return !!response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
};
// stateAdminId
