import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { themes } from '@/utils/config';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    iconfont: 'mdi',
    options: {
      customProperties: true,
    },
    themes: {
      light: themes.light,
    },
  },
});

export default vuetify;
