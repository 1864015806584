import gql from 'graphql-tag';

const createClaim = gql(`mutation createClaim($createClaimInput: CreateClaimInput!) {
                          createClaim(createClaimInput: $createClaimInput) {
                            id
                            fnolNumber
                            claimNumber
                          }
                        }`);

const createUSASAClaim = gql(`mutation createUSASAClaim($createUSASAClaimInput: CreateUSASAClaimInput!) {
                          createUSASAClaim(createUSASAClaimInput: $createUSASAClaimInput) {
                            id
                            fnolNumber
                            claimNumber
                          }
                        }`);

const createFYSAClaim = gql(`mutation createFYSAClaim($createFYSAClaimInput: CreateFYSAClaimInput!) {
                          createFYSAClaim(createFYSAClaimInput: $createFYSAClaimInput) {
                            id
                            fnolNumber
                            claimNumber
                          }
                        }`);

const createNAGHAClaim = gql(`mutation createNAGHAClaim($createNAGHAClaimInput: CreateNAGHAClaimInput!) {
                          createNAGHAClaim(createNAGHAClaimInput: $createNAGHAClaimInput) {
                            id
                            fnolNumber
                            claimNumber
                          }
                        }`);

const createColoradoClaim = gql(`mutation createColoradoClaim($createColoradoClaimInput: CreateColoradoClaimInput!) {
                          createColoradoClaim(createColoradoClaimInput: $createColoradoClaimInput) {
                            id
                            fnolNumber
                            claimNumber
                          }
                        }`);

const createOlympicClaim = gql(`mutation createOlympicClaim($createOlympicClaimInput: CreateOlympicClaimInput!) {
                        createOlympicClaim(createOlympicClaimInput: $createOlympicClaimInput) {
                            id
                            fnolNumber
                            claimNumber
                          }
                        }`);

const createCrawfordClaim = gql(`mutation createCrawfordClaim($createCrawfordClaimInput: CreateCrawfordClaimInput!) {
                        createCrawfordClaim(createCrawfordClaimInput: $createCrawfordClaimInput) {
                            id
                            fnolNumber
                            claimNumber
                          }
                        }`);

const createBrokerOrganization = gql(`mutation createBrokerOrganization($createBrokerOrganizationInput: CreateUpdateBrokerOrganizationInput!) {
                                        createBrokerOrganization(createBrokerOrganizationInput: $createBrokerOrganizationInput) {
                                          name,
                                          address {
                                            country,
                                            city,
                                            state,
                                            zip,
                                            streetAddress,
                                            additionalAddress,
                                          },
                                        }
                                      }`);

const updateBrokerOrganization = gql(`mutation updateBrokerOrganization($id: ID!, $updateBrokerOrganizationInput: CreateUpdateBrokerOrganizationInput!) {
                                        updateBrokerOrganization(id: $id, updateBrokerOrganizationInput: $updateBrokerOrganizationInput) {
                                          name,
                                        }
                                      }`);

const updateSportsUnion = gql(`mutation updateSportsUnion($id: ID!, $updateSportsUnionInput: CreateUpdateSportsUnionInput!) {
                                        updateSportsUnion(id: $id, updateSportsUnionInput: $updateSportsUnionInput) {
                                          name,
                                        }
                                      }`);

const createSportsUnion = gql(`mutation createSportsUnion($createSportsUnionInput: CreateUpdateSportsUnionInput!) {
                                        createSportsUnion(createSportsUnionInput: $createSportsUnionInput) {
                                          name,
                                          address {
                                            country,
                                            city,
                                            state,
                                            zip,
                                            streetAddress,
                                            additionalAddress,
                                          },
                                          private,
                                          url,
                                          form,
                                        }
                                      }`);

const addStateAdmin = gql(`mutation addSportsUnionStateAdmin($id: ID!, $stateAdminInput: StateAdminInput!) {
                                        addSportsUnionStateAdmin(id: $id, stateAdminInput: $stateAdminInput) {
                                          id,
                                          affiliate,
                                          contact,
                                          email,
                                        }
                                      }`);

const removeStateAdmin = gql(`mutation removeSportsUnionStateAdmin($id: ID!, $stateAdminId: ID!) {
                                        removeSportsUnionStateAdmin(id: $id, stateAdminId: $stateAdminId)
                                      }`);

const deleteBrokerOrganization = gql(`mutation deleteBrokerOrganization($id: ID!) {
                                        deleteBrokerOrganization(id: $id)
                                      }`);

const generateClaimPdf = gql(`mutation generateClaimPdf($id: ID!) {
                                        generateClaimPdf(id: $id)
                                      }`);

const deleteSportsUnion = gql(`mutation deleteSportsUnion($id: ID!) {
                                        deleteSportsUnion(id: $id)
                                      }`);

const invite = gql(`mutation invite($inviteInput: InviteInput!) {
                      invite(inviteInput: $inviteInput)
                    }`);

const createPolicy = gql(`mutation createPolicy($policyInput: PolicyInput!) {
                      createPolicy(policyInput: $policyInput) {
                        id,
                        policyNumber,
                        tpaName,
                        email,
                        effectiveDate,
                        expiredDate,
                        carrier,
                        deductible,
                        coinsurance,
                        benefitPeriod,
                        perInjuryLimit,
                        physicalTherapySublimit
                      }
}`);

const updatePolicy = gql(`mutation updatePolicy($id: ID!, $policyInput: UpdatePolicyInput!) {
                      updatePolicy(id: $id, updatePolicyInput: $policyInput) {
                        id,
                        policyNumber,
                        tpaName,
                        email,
                        effectiveDate,
                        expiredDate,
                        carrier,
                        deductible,
                        coinsurance,
                        benefitPeriod,
                        perInjuryLimit,
                        physicalTherapySublimit
                      }
}`);

const resendInvite = gql(`mutation resendInvite($resendInviteInput: ResendInviteInput) {
                            resendInvite(resendInviteInput: $resendInviteInput)
                          }`);

const login = gql(`mutation login($loginInput: LoginInput) {
                    login(loginInput: $loginInput)
                  }`);

const newPassword = gql(`mutation newPassword($newPasswordInput: NewPasswordInput) {
                          newPassword(newPasswordInput: $newPasswordInput)
                        }`);

const restore = gql(`mutation restore($restorePasswordInput: RestorePasswordInput!) {
                      restore(restorePasswordInput: $restorePasswordInput)
                    }`);

const setClaimFullyPaid = gql(`mutation setClaimFullyPaid($id: ID!) {
                              setClaimFullyPaid(id: $id) {
                                id,
                                status,
                              }
                            }`);

const setClaimPartiallyPaid = gql(`mutation setClaimPartiallyPaid($id: ID!) {
                              setClaimPartiallyPaid(id: $id) {
                                id,
                                status,
                              }
                            }`);

const setClaimDeclined = gql(`mutation setClaimDeclined($declineClaimInput: DeclineClaimInput!) {
                              setClaimDeclined(declineClaimInput: $declineClaimInput) {
                                id,
                                status,
                              }
                            }`);

const acceptFnol = gql(`mutation acceptFnol($acceptFnolInput: AcceptFnolInput!) {
                              acceptFnol(acceptFnolInput: $acceptFnolInput) {
                                id,
                                status
                              }
                            }`);

const declineFnol = gql(`mutation declineFnol($declineFnolInput: DeclineFnolInput!) {
                              declineFnol(declineFnolInput: $declineFnolInput) {
                                id,
                              }
                            }`);

const coachDecline = gql(`mutation coachDecline($coachDeclineInput: CoachDeclineInput!) {
      coachDecline(coachDeclineInput: $coachDeclineInput)
}`);

const confirmFnol = gql(`mutation coachConfirm($coachConfirmInput: CoachConfirmInput!) {
      coachConfirm(coachConfirmInput: $coachConfirmInput)
}`);

const updateClaim = gql(`mutation updateClaim($id: ID!, $updateClaimInput: UpdateClaimInput!) {
                          updateClaim(id: $id, updateClaimInput: $updateClaimInput) {
                             id,
                             files {
                               path,
                               filename,
                               mimetype,
                             },
                             fnolDeclineReason,
                             fnolNumber,
                             claimNumber,
                             fnolStatus,
                             status,
                             createdAt,
                             signedAt,
                             signedBy,
                             processedBy,
                             processedAt,
                             coachDeclineReason,
                             claimDeclineReason,
                             claimantLastName,
                             claimantFirstName,
                             claimantMiddleName,
                             claimantDOB,
                             claimantAge,
                             claimantGender,
                             claimantEmail,
                             claimantAddress {
                               country,
                               city,
                               state,
                               zip,
                               streetAddress,
                             },
                             claimantFathersFullName,
                             claimantMothersFullName,
                             claimantRoleType,
                             insuranceCompany,
                             policyHoldersName,
                             policyNumber,
                             groupNumber,
                             incidentDate,
                             incidentTime,
                             incidentLocation,
                             memberId,
                             membershipType,
                             membershipOtherType,
                             eventType,
                             isContact,
                             contactType,
                             contactOtherType,
                             injuredBodyPart,
                             injuredBodySide,
                             injuryDescription,
                             injuryType,
                             severity,
                             isReceivedOnsite,
                             isTakenByAmbulanceToHospital,
                             nameOfEventOrTeamsCompetingAgainst,
                             injuryDescription,
                             claimantSignature,
                             clubNameOfInjured,
                             territoryWhereIncidentOccurred,
                             isAthleteWasTreatedByCoach,
                             coachSignature,
                             coachEmail,
                             coachFirstName,
                             coachLastName,
                             coachPhone,
                             sportsUnion {
                              id,
                              name,
                              logo {
                                id,
                                path,
                              }
                             },
                             olympic,
                             olympic_socialSecurityNumber,
                             olympic_street,
                             olympic_city,
                             olympic_state,
                             olympic_zip,
                             olympic_haveMedicalCare,
                             olympic_dentalInvolvedTeeth,
                             olympic_dentalConditionTeeth,
                             olympic_didInjuryResultInDeath,
                             olympic_nameOfTeam,
                             olympic_checkbox1,
                             olympic_checkbox2,
                             olympic_checkbox3,
                             olympic_claimantRoleType,
                             olympic_claimantParentsFullName,
                             olympic_claimantParentsAddress,
                             olympic_claimantParentsPhone,
                             plainSurface
                             stateAdmin
                          }
                         }`);

const uploadFile = gql(`mutation uploadFile($file: Upload!) {
                          uploadFile(file: $file) {
                            id
                            path
                            filename
                            mimetype
                            encoding
                          }
                        }`);

const updateUser = gql(`mutation updateUser($id: ID!, $updateUserInput: UpdateUserInput!) {
                        updateUser(id: $id, updateUserInput: $updateUserInput) {
                          id,
                          name,
                          email,
                          phone,
                          role,
                          photo {
                            id,
                            path,
                            filename,
                            mimetype,
                            encoding,
                          },
                        }
                      }`);

const activateUser = gql(`mutation activateUser($id: ID!) {
                          activateUser(
                            id: $id
                          )
                        }`);

const deactivateUser = gql(`mutation deactivateUser($id: ID!) {
                          deactivateUser(
                            id: $id
                          )
                        }`);

const changePassword = gql(`mutation changePassword($changePasswordInput: ChangePasswordInput!) {
                        changePassword(changePasswordInput: $changePasswordInput)
                      }`);

const changePasswordComplete = gql(`mutation changePasswordComplete($changePasswordCompleteInput: ChangePasswordCompleteInput!) {
                        changePasswordComplete(changePasswordCompleteInput: $changePasswordCompleteInput)
                      }`);

const logEvent = gql(`mutation logEvent($event: LogEventType!, $data: String) {
                      logEvent(event: $event, data: $data)
                    }`);

const verifyRecaptcha = gql(`mutation verifyRecaptcha($token: String!) {
                                verifyRecaptcha (token: $token)
                              }`);

const exportClaim = gql(`mutation exportClaim($id: ID!) {
                          exportClaim(id: $id) {
                            id
                            exported
                          }
                        }`);

export {
  createClaim,
  createUSASAClaim,
  createFYSAClaim,
  createNAGHAClaim,
  createColoradoClaim,
  createOlympicClaim,
  createCrawfordClaim,
  setClaimFullyPaid,
  createSportsUnion,
  addStateAdmin,
  removeStateAdmin,
  setClaimPartiallyPaid,
  setClaimDeclined,
  createBrokerOrganization,
  deleteBrokerOrganization,
  coachDecline,
  confirmFnol,
  login,
  newPassword,
  restore,
  invite,
  createPolicy,
  updatePolicy,
  resendInvite,
  uploadFile,
  acceptFnol,
  declineFnol,
  updateBrokerOrganization,
  updateClaim,
  updateUser,
  activateUser,
  deactivateUser,
  updateSportsUnion,
  logEvent,
  verifyRecaptcha,
  changePassword,
  deleteSportsUnion,
  changePasswordComplete,
  generateClaimPdf,
  exportClaim,
};
