<template>
  <v-dialog
    @input="updateDialogValue"
    :value="value"
    :fullscreen="fullscreen"
    :max-width="dialogMaxWidth"
    :content-class="bodyClasses"
    :transition="transition"
  >
    <v-container
      :fluid="fluid"
      fill-height
      flex-column
      flex-nowrap
      pa-4
      pa-md-10
      class="ph-dialog__container"
    >
      <div v-if="dismissible" class="ph-dialog__close-button-wrapper">
        <CloseBtn @click="updateDialogValue(false)" />
      </div>
      <div class="ph-dialog__header pa-4 pt-md-4 px-md-4 pb-md-8">
        <div v-if="heading || $slots.header" class="ph-dialog__header-content">
          <slot name="header">
            <h2 class="ph-dialog__heading">{{ heading }}</h2>
          </slot>
        </div>
      </div>
      <div class="ph-dialog__content-wrapper">
        <div
          :class="{
            'ph-dialog__content-scroll--shadow': hasScrollShadow,
            'ph-dialog__content-scroll--shadow-bottom': scrollShadow.bottom,
            'ph-dialog__content-scroll--shadow-top': scrollShadow.top,
          }"
          @scroll="handleContentScroll"
          ref="contentScroll"
          class="ph-dialog__content-scroll"
        >
          <div
            class="ph-dialog__content"
            :class="{
              'ph-dialog__content--center': contentTextAlign === 'center',
            }"
          >
            <slot name="content"></slot>
          </div>
        </div>
      </div>
      <div class="ph-dialog__footer pt-4 pt-md-10" v-if="!hideFooter">
        <slot name="footer">
          <v-btn @click.stop="updateDialogValue(false)">
            {{ closeBtnText }}
          </v-btn>
        </slot>
      </div>
    </v-container>
  </v-dialog>
</template>

<script>
import CloseBtn from '@/components/buttons/CloseBtn';

const NAME = 'PhDialog';

export default {
  name: NAME,
  components: {
    CloseBtn,
  },
  props: {
    value: Boolean,
    hideFooter: Boolean,
    heading: String,
    contentTextAlign: {
      type: String,
      default: 'center',
    },
    closeBtnText: {
      type: String,
      default: 'Got it',
    },
    customClass: String,
    dismissible: {
      type: Boolean,
      default: true,
    },
    fluid: Boolean,
    fullscreen: Boolean,
    maxWidth: {
      type: Number,
      default: 500,
    },
    mobile: Boolean,
    narrowContent: Boolean,
    shadowContentScroll: Boolean,
    growContent: {
      type: Boolean,
      default: true,
    },
    verticalCenterContent: Boolean,
    transition: {
      type: String,
      default: 'dialog-transition',
    },
  },
  data() {
    return {
      contentScrollState: {
        scrollTop: 0,
        offsetHeight: 0,
        scrollHeight: 0,
      },
    };
  },
  computed: {
    bodyClasses() {
      let classList = 'ph-dialog__body';
      if (this.narrowContent) {
        classList += ' ph-dialog__body--narrow';
      }
      if (this.growContent) {
        classList += ' ph-dialog__body--grow-content';
      }
      if (this.verticalCenterContent) {
        classList += ' ph-dialog__body--vertical-center-content';
      }
      if (typeof this.customClass === 'string' && this.customClass.length) {
        classList += ` ${this.customClass}`;
      }
      return classList;
    },
    dialogMaxWidth() {
      return `${this.maxWidth}px`;
    },
    hasScrollShadow() {
      return this.fullscreen && this.shadowContentScroll;
    },
    scrollShadow() {
      const SCROLL_THRESHOLD = 50;
      return {
        bottom:
          this.hasScrollShadow &&
          this.contentScrollState.scrollHeight > this.contentScrollState.offsetHeight &&
          this.contentScrollState.scrollTop <
            this.contentScrollState.scrollHeight - this.contentScrollState.offsetHeight,
        top: this.hasScrollShadow && this.contentScrollState.scrollTop >= SCROLL_THRESHOLD,
      };
    },
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(this.initContentScrollShadows);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleContentScroll(e) {
      this.contentScrollState.scrollTop = e.target.scrollTop;
    },
    initContentScrollShadows() {
      if (this.shadowContentScroll && this.$refs.contentScroll) {
        this.contentScrollState = {
          scrollTop: this.$refs.contentScroll.scrollTop,
          offsetHeight: this.$refs.contentScroll.offsetHeight,
          scrollHeight: this.$refs.contentScroll.scrollHeight,
        };
      }
    },
    updateDialogValue(newVal) {
      if (newVal) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
      this.$emit('input', newVal);
    },
    handleEscClick(e) {
      if (e.keyCode === 27) {
        this.updateDialogValue(false);
      }
    },
    setCloseOnEscListener() {
      document.addEventListener('keydown', this.handleEscClick);
    },
    removeCloseOnEscListener() {
      document.removeEventListener('keydown', this.handleEscClick);
    },
  },
  mounted() {
    this.setCloseOnEscListener();
  },
  beforeDestroy() {
    this.removeCloseOnEscListener();
  },
};
</script>

<style lang="scss">
@import '~@/scss/base/index';
.ph-dialog__container {
  position: relative;
}
.ph-dialog__body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $background-lightest;
  &:not(.v-dialog--fullscreen) {
    border-radius: 8px;
  }
  &.v-dialog--fullscreen {
    height: 100%;
  }
  &--narrow {
    .ph-dialog__content {
      max-width: 880px;
      margin: 0 auto;
    }
  }
  &--grow-content {
    .ph-dialog__content {
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
  }
  &--vertical-center-content {
    .ph-dialog__container {
      justify-content: center;
    }
    .ph-dialog__content-wrapper {
      flex: 0 1 auto;
      .ph-dialog__content {
        padding-bottom: 10px;
      }
    }
  }
}
.ph-dialog__header {
  text-align: center;
  width: 100%;
  .ph-dialog__heading {
    margin-bottom: 0;
    font-weight: 700;
    color: $text-dark;
  }
}
.ph-dialog__content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}
.ph-dialog__content-scroll {
  flex: 1;
  overflow-y: auto;
  &--shadow {
    position: relative;
    pointer-events: none;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 115px;
      position: fixed;
      top: 0px;
      left: 0px;
      background: linear-gradient(0deg, $transparent, $background-lightest);
      opacity: 0;
      z-index: 1;
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 115px;
      position: fixed;
      bottom: 0px;
      left: 0px;
      background: linear-gradient(180deg, $transparent, $background-lightest);
      opacity: 0;
      z-index: 1;
    }
  }
  &--shadow-top {
    &::before {
      opacity: 1;
    }
  }
  &--shadow-bottom {
    position: relative;
    &::after {
      opacity: 1;
    }
  }
}
.ph-dialog__content {
  background: $background-lightest;
  font-size: 1rem;
  color: $text-semi-darkest;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  pointer-events: all;
  &--center {
    text-align: center;
  }
}
.ph-dialog__footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include bp-up(md) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    & > *:nth-child(1n + 2) {
      margin-left: 20px;
    }
  }
}
.ph-dialog__close-button-wrapper {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 2;
  display: flex;
  align-items: center;
}
</style>
