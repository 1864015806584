/* eslint max-len: 0 */
import {
  getClaimantInfoFormProps,
  getCoachInfoFormProps,
  getDisclaimersFormProps,
  getIncidentDetailsFormProps,
  getInsuranceInfoFormProps,
} from '@/utils/helpers/app/claimsOlympic';

const claimantInfoFormProps = getClaimantInfoFormProps();
const coachInfoFormProps = getCoachInfoFormProps();
const disclaimersFormProps = getDisclaimersFormProps();
const incidentDetailsFormProps = getIncidentDetailsFormProps();
const insuranceInfoFormProps = getInsuranceInfoFormProps();

function groupFormPropsByAliases(propAliases) {
  return Object.values(propAliases).reduce((acc, propKey) => {
    if (typeof propKey === 'string' && !acc[propKey] && this[propKey] !== undefined) {
      acc[propKey] = this[propKey];
    }
    return acc;
  }, {});
}

export default {
  props: {
    ...claimantInfoFormProps,
    ...coachInfoFormProps,
    ...disclaimersFormProps,
    ...incidentDetailsFormProps,
    ...insuranceInfoFormProps,
    loading: {
      type: Boolean,
      default: () => false,
    },
    formParams: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    claimantInfoFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.CLAIMANT_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
    claimantInfoReviewFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.CLAIMANT_REVIEW_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
    coachInfoFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.COACH_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
    disclaimersFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.DISCLAIMERS_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
    incidentDetailsFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.INCIDENT_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
    insuranceInfoFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.INSURANCE_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
  },
  methods: {
    handleClaimsInfoDisclaimerClick() {
      this.$emit('claimsInfoDisclaimerClick');
    },
  },
};
