import { CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const INSURANCE_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.INSURANCE;

export default () => ({
  // aliases for usage in form templates
  INSURANCE_FIELD_ALIASES: {
    type: Object,
    default: () => INSURANCE_FIELD_ALIASES,
  },
  [INSURANCE_FIELD_ALIASES.INSURANCE_COMPANY_NAME]: String,
  [INSURANCE_FIELD_ALIASES.INSURANCE_GROUP_NUMBER]: [String, Number],
  [INSURANCE_FIELD_ALIASES.INSURANCE_POLICY_NUMBER]: [String, Number],
  [INSURANCE_FIELD_ALIASES.INSURANCE_POLICYHOLDER_NAME]: String,
});
