import { API_DATE_FORMAT } from '@/utils/constants';
import Time from '@/utils/classes/Time';

export default (setDefaultDates = true) => {
  const DATE_FORMAT = `${API_DATE_FORMAT} HH:mm:ss`;
  const defaultFilters = {
    organizations: [],
    dateFrom: null,
    dateTo: null,
    searchQuery: '',
    claimantName: null,
    sortBy: 'createdAt',
    sortDesc: 'DESC',
    status: '',
  };
  if (setDefaultDates) {
    const monthStart = Time.getRawDate()
      .startOf('month')
      .format(DATE_FORMAT);
    const todayNight = Time.getRawDate()
      .endOf('day')
      .format(DATE_FORMAT);
    defaultFilters.dateFrom = monthStart;
    defaultFilters.dateTo = todayNight;
  }
  return defaultFilters;
};
