import {
  CRAWFORD_CLAIM_FORM_FIELD_ALIASES,
  LOSS_TYPES,
  PROPERTY_OWNER_TYPES,
} from '@/utils/constants';

const lossAndPolicy = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.LOSS_AND_POLICY;
const health = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.HEALTH;
const riskLocation = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.RISK_LOCATION;
const property = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.PROPERTY;
const injured = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.INJURED;
const caller = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.CALLER;
const disclaimers = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.DISCLAIMERS;

const mapFormFields = (map, form) =>
  Object.entries(map).reduce((acc, [target, source]) => {
    /* eslint-disable no-use-before-define */
    const value = getFieldValue(form, source);

    if (value === undefined || value === null || value === '') {
      return acc;
    }

    return Object.assign(acc, { [target]: value });
  }, {});

const getFieldValue = (form, source) => {
  if (typeof source === 'object') {
    return mapFormFields(source, form);
  }

  return form[source];
};

const hasProperty = form =>
  [LOSS_TYPES.PROPERTY, LOSS_TYPES.BOTH].includes(form[lossAndPolicy.LOSS_TYPE]);

const hasHealthPlans = form =>
  form[injured.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN] &&
  form.healthInsuranceForms instanceof Object &&
  !!Object.keys(form.healthInsuranceForms).length;

const getOwnerType = form => form[property.PROPERTY_OWNER_TYPE];
const isCompanyOwned = ownerType => ownerType === PROPERTY_OWNER_TYPES.COMPANY;
const isPersonOwned = ownerType => ownerType === PROPERTY_OWNER_TYPES.PERSON;

const API_PAYLOAD_MAP = {
  sportsUnionId: 'sportsUnionId',
  // policy
  playersHealthPolicyNumber: lossAndPolicy.POLICY_NUMBER,
  policyState: lossAndPolicy.POLICY_STATE,
  // loss
  lossType: lossAndPolicy.LOSS_TYPE,
  // glCauseCode: lossAndPolicy.GL_CAUSE_CODE,
  // glDescriptionCode: lossAndPolicy.GL_DESCRIPTION_CODE,
  incidentDate: lossAndPolicy.LOSS_DATE,
  incidentTime: lossAndPolicy.LOSS_TIME,
  eventType: lossAndPolicy.LOSS_LOCATION_EVENT_TYPE,
  nameAndTypeOfEvent: lossAndPolicy.LOSS_LOCATION_EVENT_NAME_AND_TYPE,
  injuryDescription: lossAndPolicy.LOSS_DESCRIPTION,
  // loss location
  lossLocation: {
    name: lossAndPolicy.LOSS_LOCATION_NAME,
    isOnPremises: lossAndPolicy.LOSS_LOCATION_IS_ON_PREMISSES,
    address: {
      country: lossAndPolicy.LOSS_LOCATION_ADDRESS_COUNTRY,
      state: lossAndPolicy.LOSS_LOCATION_ADDRESS_STATE,
      city: lossAndPolicy.LOSS_LOCATION_ADDRESS_CITY,
      zip: lossAndPolicy.LOSS_LOCATION_ADDRESS_ZIP,
      streetAddress: lossAndPolicy.LOSS_LOCATION_ADDRESS_LINE1,
      additionalAddress: lossAndPolicy.LOSS_LOCATION_ADDRESS_LINE2,
    },
  },
  // risk location
  riskLocation: {
    name: riskLocation.RISK_LOCATION_NAME,
    workPhone: riskLocation.RISK_LOCATION_WORK_PHONE,
    address: {
      country: riskLocation.RISK_LOCATION_ADDRESS_COUNTRY,
      state: riskLocation.RISK_LOCATION_ADDRESS_STATE,
      city: riskLocation.RISK_LOCATION_ADDRESS_CITY,
      zip: riskLocation.RISK_LOCATION_ADDRESS_ZIP,
      streetAddress: riskLocation.RISK_LOCATION_ADDRESS_LINE1,
      additionalAddress: riskLocation.RISK_LOCATION_ADDRESS_LINE2,
    },
  },
  // injured
  claimantFirstName: injured.INJURED_FIRST_NAME,
  claimantLastName: injured.INJURED_LAST_NAME,
  claimantAddress: {
    country: injured.INJURED_ADDRESS_COUNTRY,
    state: injured.INJURED_ADDRESS_STATE,
    city: injured.INJURED_ADDRESS_CITY,
    zip: injured.INJURED_ADDRESS_ZIP,
    streetAddress: injured.INJURED_ADDRESS_LINE1,
    additionalAddress: injured.INJURED_ADDRESS_LINE2,
  },
  claimantEmail: caller.CALLER_EMAIL,
  claimantDOB: injured.INJURED_DOB,
  claimantGender: injured.INJURED_GENDER,
  claimantInjuredBodyPartCode: injured.INJURED_BODY_PART_CODE,
  claimantInjuryCode: injured.INJURED_INJURY_CODE,
  claimantInjuryDescription: injured.INJURED_INJURY_DESCRIPTION,
  claimantInjuredDoing: injured.INJURED_INJURED_DOING,
  isTakenByAmbulanceToHospital: injured.INJURED_TAKEN_TO_HOSPITAL_BY_AMBULANCE,
  olympic_didInjuryResultInDeath: injured.INJURED_INJURY_RESULT_IN_DEATH,
  memberId: injured.INJURED_MEMBER_ID,
  nameOfTeam: injured.INJURED_NAME_OF_TEAM,
  stateAssociationNationwideAffiliate: injured.INJURED_STATE_ASSOCIATION,
  nameOfLeague: injured.INJURED_NAME_OF_LEAGUE,
  areInjuredPersonCurrentlyEnrolledAccidentPlan: injured.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN,
  // caller
  caller: {
    firstName: caller.CALLER_FIRST_NAME,
    lastName: caller.CALLER_LAST_NAME,
    workPhone: caller.CALLER_WORK_PHONE,
    type: caller.CALLER_TYPE,
  },
  // signature
  claimantSignature: disclaimers.INCIDENT_VICTIM_SIGNATURE,
};

const HEALH_PLAN_MAP = {
  companyName: health.HEALTH_INSURANCE_COMPANY_NAME,
  groupName: health.HEALTH_INSURANCE_GROUP_NAME,
  policyHoldersName: health.HEALTH_INSURANCE_POLICY_HOLDERS_NAME,
  memberId: health.HEALTH_INSURANCE_MEMBER_ID,
  policyNumber: health.HEALTH_INSURANCE_POLICY_NUMBER,
};

// property
const getPropertyMapping = ownerType => ({
  description: property.PROPERTY_DESCRIPTION,
  damageDescription: property.PROPERTY_DAMAGE_DESCRIPTION,
  owner: {
    type: property.PROPERTY_OWNER_TYPE,
    ...(isCompanyOwned(ownerType) && { name: property.PROPERTY_OWNER_COMPANY_NAME }),
    ...(isPersonOwned(ownerType) && {
      firstName: property.PROPERTY_OWNER_PERSON_FIRST_NAME,
      lastName: property.PROPERTY_OWNER_PERSON_LAST_NAME,
    }),
    address: {
      country: property.PROPERTY_OWNER_ADDRESS_COUNTRY,
      state: property.PROPERTY_OWNER_ADDRESS_STATE,
      city: property.PROPERTY_OWNER_ADDRESS_CITY,
      zip: property.PROPERTY_OWNER_ADDRESS_ZIP,
      streetAddress: property.PROPERTY_OWNER_ADDRESS_LINE1,
      additionalAddress: property.PROPERTY_OWNER_ADDRESS_LINE2,
    },
    workPhone: property.PROPERTY_OWNER_WORK_PHONE,
    homePhone: property.PROPERTY_OWNER_HOME_PHONE,
  },
});

export default form => {
  if (!(form instanceof Object)) {
    throw new Error('The provided "form" argument is not a valid Object');
  }

  const payload = mapFormFields(API_PAYLOAD_MAP, form);

  if (hasProperty(form)) {
    const ownerType = getOwnerType(form);
    payload.properties = [mapFormFields(getPropertyMapping(ownerType), form)];
  }

  if (hasHealthPlans(form)) {
    payload.healthInsurancePlan = Object.values(form.healthInsuranceForms).map(hp =>
      mapFormFields(HEALH_PLAN_MAP, hp)
    );
  }

  return payload;
};
