import mapRoutesWithMeta from '@/router/helpers/mapRoutesWithMeta';
import packRoutes from '@/router/helpers/packRoutes';
import claims from './claims';
import fnolEdit from './fnolEdit';
import fnols from './fnols';
import logout from './logout';
import brokerOrganizations from './brokerOrganizations';
import organizations from './organizations';
import profile from './profile';

const routes = packRoutes(
  claims,
  fnolEdit,
  fnols,
  profile,
  logout,
  organizations,
  brokerOrganizations
);

export default mapRoutesWithMeta(routes, {
  requiresAuth: true,
});
