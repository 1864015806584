const cityReg = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/;
const emailReg = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const fullNameReg = /^([a-zA-z']{2,}([\s][a-zA-Z\'\-]{2,})+|[a-zA-z']{2,})$/;
const streetReg = /^[\w(\s)?]{3,30}/;
const zipReg = /^[\w\d\s-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]{3,}$/;
const phoneReg = /^(?=.*[0-9+])[ +0-9\-]+$/;
const passwordReg = /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#\$%\^&\*\(\)_\+`\-=\{\}\|\[\]\\:";'<>\?,\.\/])(?=.{8,})/;
const realisticNumberReg = /^[0-9]{1,10}$/;
const nameReg = /^([a-zA-Z]{2,20})$/;
const lettersReg = /^[a-zA-Z]+$/;
const moneyReg = /^[,0-9]+(\.[0-9]{1,2})?$/;
const creditCardReg = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
const creditExpDateReg = /\b(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})\b/;
const inputDateReg = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/; // YYYY-MM-DD
const creditCvvReg = /^[0-9]{3,4}$/;
const letterReg = /^[a-zA-Z]*$/;
const siteUrlReg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
const claimUrlReg = /^[a-zA-Z0-9._-]+$/;
const whiteSpaceReg = /\s+/;
const memberIdReg = /^[a-zA-Z0-9._-]+$/;
const uidReg = /^([A-Za-z0-9\-\_])+$/;

const uid = [
  v => (v ? uidReg.test(v) || 'Only latin, digits, underscores and dashes allowed' : true),
];
const city = [v => (v ? cityReg.test(v) || 'City be a valid' : true)];

const SSN = [v => (v ? v.length === 11 || `This field requires ${9} symbol(s)` : true)];

const email = [v => (v ? emailReg.test(v) || 'Must be a valid email' : true)];

const password = [
  v => (v ? !whiteSpaceReg.test(v) || 'The password must not contain spaces' : true),
  v =>
    v
      ? passwordReg.test(v) ||
        'The password must be no less than 8 characters long, contain a capital letter, a special symbol and a number'
      : true,
];

const phone = [
  v =>
    v
      ? (phoneReg.test(v) &&
          v
            .split(' ')
            .join('')
            .split('+')
            .join('').length <= 13) ||
        "Phone must adhere to the '+X XXX XXX XX XX' format"
      : true,
];

const firstName = [
  v => (v ? v.length > 2 || 'Name must be at least 3 characters' : true),
  v => (v ? v.length <= 20 || 'Name must be less than 20 characters' : true),
  v => (v ? nameReg.test(v) || 'Name should only contain letters' : true),
];

const fullName = [
  v => (v ? v.length > 1 || 'Full name must be at least 2 characters' : true),
  v =>
    v
      ? fullNameReg.test(v) ||
        'Full name must consist of letters, which may be separated by a whitespace'
      : true,
];

const lastName = [
  v => (v ? v.length > 1 || 'Name must be at least 2 characters' : true),
  v => (v ? v.length <= 20 || 'Name must be less than 20 characters' : true),
  v => (v ? nameReg.test(v) || 'Name should only contain letters' : true),
];

const providerName = [
  v => (v ? (!!v && v.length > 1) || 'Provider name must be at least 2 characters' : true),
  v => (v ? (!!v && v.length <= 20) || 'Provider name must be less than 20 characters' : true),
  v => (v ? nameReg.test(v) || 'Provider name should only contain letters' : true),
];

const role = [
  v => (v ? v.length > 2 || 'Role must be at least 3 characters' : true),
  v => (v ? v.length <= 40 || 'Role must be less than 40 characters' : true),
];

const street = [v => (v ? streetReg.test(v) || 'Street must be 3 characters or longer' : true)];

const zip = [v => (v ? zipReg.test(v) || 'Zip must be 3 characters or longer and valid' : true)];

const claimCreateSlug = [v => (v ? claimUrlReg.test(v) || 'URL is not valid' : true)];

const required = [v => !!v || 'This field is required'];

const requiredArray = [v => (v && !!v.length) || 'This field is required'];

const requiredBoolean = [v => typeof v === 'boolean' || 'This field is required'];

const realisticNumber = [
  v => (v ? realisticNumberReg.test(v) || 'The field must be a realistic number' : true),
];
const moneyValue = [
  v => {
    let p = v;
    if (v != null) {
      p = v.toString();
      if (p.includes('.') && p.includes(',')) {
        return 'The field must have money format';
      }
      if (p) p = p.replace(/,/g, '.');
    }
    return p
      ? (moneyReg.test(p) && p.split('.').length < 3 && p.split(',').length < 3) ||
          'The field must have money format'
      : true;
  },
];

const moneyValueLocale = [
  v => {
    let p = v;
    if (v != null) {
      p = v.toString();
    }
    return p
      ? (moneyReg.test(p) && p.split('.').length < 3) || 'The field must have money format'
      : true;
  },
];

const requiredNumeric = [v => !Number.isNaN(Number.parseInt(v, 10)) || 'This field is required'];

const creditCard = [v => (v ? creditCardReg.test(v) || 'Credit card number is not valid' : true)];

const creditExpDate = [
  v => (v ? creditExpDateReg.test(v) || 'Expiration date is not valid' : true),
];

const effectiveDate = [v => (v ? inputDateReg.test(v) || 'Effective date is not valid' : true)];

const expiredDate = [v => (v ? inputDateReg.test(v) || 'Expiration date is not valid' : true)];

const creditCvv = [v => (v ? creditCvvReg.test(v) || 'CVV code is not valid' : true)];

const memberIdRule = [v => (v ? memberIdReg.test(v) || 'This field is not valid' : true)];

const letters = [v => (v ? letterReg.test(v) || 'This field is not valid' : true)];

const siteUrl = [v => (v ? siteUrlReg.test(v) || 'This field is not valid' : true)];

const symbolsNeeded = symbols => [
  v => (v ? v.length === symbols || `This field requies ${symbols} symbol(s)` : true),
];

const crawfordLossDescription = [
  v => (v ? v.length <= 4000 || 'Loss Description must be 4000 characters or fewer' : true),
];

const crawfordLocationName = [
  v => (v ? v.length <= 50 || 'Location Name must be 50 characters or fewer' : true),
];

const crawfordFirstName = [
  v => (v ? v.length > 2 || 'First Name must be at least 3 characters' : true),
  v => (v ? v.length <= 15 || 'First Name must be 15 characters or fewer' : true),
  v => (v ? lettersReg.test(v) || 'First Name should only contain letters' : true),
];

const crawfordLastName = [
  v => (v ? v.length > 1 || 'Last Name must be at least 2 characters' : true),
  v => (v ? v.length <= 30 || 'Last Name must be 30 characters or fewer' : true),
  v => (v ? lettersReg.test(v) || 'Last Name should only contain letters' : true),
];

const crawfordEmail = [
  ...email,
  v => (v ? v.length <= 50 || 'Email must be 50 characters or fewer' : true),
];

const crawfordCity = [
  ...city,
  v => (v ? v.length <= 20 || 'City must be 20 characters or fewer' : true),
];

const crawfordZip = [
  v => (v ? v.length >= 5 || 'Zip Code must be at least 5 characters' : true),
  v => (v ? v.length <= 6 || 'Zip Code must be 6 characters or fewer' : true),
];

const crawfordAddressLine1 = [
  v => (v ? v.length <= 30 || 'Address Line 1 must be 30 characters or fewer' : true),
];

const crawfordAddressLine2 = [
  v => (v ? v.length <= 30 || 'Address Line 2 must be 30 characters or fewer' : true),
];

const crawfordInjuryDescription = [
  v => (v ? v.length <= 60 || 'Injury Description must be 60 characters or fewer' : true),
];

const crawfordInjuredDoing = [
  v => (v ? v.length <= 60 || 'Injured Doing must be 60 characters or fewer' : true),
];

const maxlen250 = [v => (v ? v.length <= 250 || 'Field must be 250 characters of fewer' : true)];

const maxlen15 = [v => (v ? v.length <= 15 || 'Field must be 15 characters of fewer' : true)];

const crawfordHomePhone = [
  v =>
    v ? v.replace(/\D/g, '').length === 10 || 'Home Phone Number must be 10 digits long' : true,
];

const crawfordWorkPhone = [
  v =>
    v ? v.replace(/\D/g, '').length === 10 || 'Work Phone Number must be 10 digits long' : true,
];

export default {
  zip,
  role,
  city,
  email,
  phone,
  street,
  siteUrl,
  letters,
  password,
  lastName,
  required,
  requiredNumeric,
  requiredBoolean,
  creditCvv,
  firstName,
  fullName,
  creditCard,
  providerName,
  creditExpDate,
  SSN,
  symbolsNeeded,
  requiredArray,
  realisticNumber,
  nameReg,
  moneyValue,
  claimCreateSlug,
  memberIdRule,
  moneyValueLocale,
  uid,
  crawfordLossDescription,
  crawfordLocationName,
  crawfordFirstName,
  crawfordLastName,
  crawfordEmail,
  crawfordCity,
  crawfordZip,
  crawfordAddressLine1,
  crawfordAddressLine2,
  crawfordInjuryDescription,
  crawfordInjuredDoing,
  maxlen250,
  maxlen15,
  crawfordHomePhone,
  crawfordWorkPhone,
  effectiveDate,
  expiredDate,
};
