import { CRAWFORD_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const INJURED_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.INJURED;

export default () => ({
  // aliases for usage in form templates
  INJURED_FIELD_ALIASES: {
    type: Object,
    default: () => INJURED_FIELD_ALIASES,
  },
  [INJURED_FIELD_ALIASES.INJURED_FIRST_NAME]: String,
  [INJURED_FIELD_ALIASES.INJURED_LAST_NAME]: String,
  [INJURED_FIELD_ALIASES.INJURED_ADDRESS_LINE1]: String,
  [INJURED_FIELD_ALIASES.INJURED_ADDRESS_LINE2]: String,
  [INJURED_FIELD_ALIASES.INJURED_ADDRESS_CITY]: String,
  [INJURED_FIELD_ALIASES.INJURED_ADDRESS_STATE]: String,
  [INJURED_FIELD_ALIASES.INJURED_ADDRESS_ZIP]: String,
  [INJURED_FIELD_ALIASES.INJURED_ADDRESS_COUNTRY]: String,
  [INJURED_FIELD_ALIASES.INJURED_DOB]: [String, Number],
  [INJURED_FIELD_ALIASES.INJURED_GENDER]: String,
  [INJURED_FIELD_ALIASES.INJURED_BODY_PART_CODE]: String,
  [INJURED_FIELD_ALIASES.INJURED_INJURY_CODE]: String,
  [INJURED_FIELD_ALIASES.INJURED_INJURY_DESCRIPTION]: String,
  [INJURED_FIELD_ALIASES.INJURED_INJURED_DOING]: String,
  [INJURED_FIELD_ALIASES.INJURED_TAKEN_TO_HOSPITAL_BY_AMBULANCE]: Boolean,
  [INJURED_FIELD_ALIASES.INJURED_INJURY_RESULT_IN_DEATH]: Boolean,
  [INJURED_FIELD_ALIASES.INJURED_MEMBER_ID]: String,
  [INJURED_FIELD_ALIASES.INJURED_NAME_OF_TEAM]: String,
  [INJURED_FIELD_ALIASES.INJURED_STATE_ASSOCIATION]: String,
  [INJURED_FIELD_ALIASES.INJURED_NAME_OF_LEAGUE]: String,
  [INJURED_FIELD_ALIASES.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN]: Boolean,
});
