import populateInputRuleFromProp from '@/utils/helpers/vuetify/populateInputRuleFromProp';

export default context => ({
  label:
    context.props.label &&
    context.props.rules instanceof Array &&
    context.props.rules.includes('required')
      ? `${context.props.label}*`
      : context.props.label,
  placeholder:
    context.props.placeholder &&
    context.props.rules instanceof Array &&
    context.props.rules.includes('required')
      ? `${context.props.placeholder}*`
      : context.props.placeholder,
  rules:
    context.props.rules instanceof Array
      ? populateInputRuleFromProp(context.props.rules)
      : undefined,
});
