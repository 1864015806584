export default {
  props: {
    hiddenFields: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    fieldIsHidden(field) {
      return (
        this.hiddenFields instanceof Array &&
        this.hiddenFields.length &&
        this.hiddenFields.includes(field)
      );
    },
  },
};
