<template>
  <v-toolbar-items class="app-menu-links">
    <template v-for="item in items">
      <router-link class="app-menu-link" :key="'item_' + item.text" :to="item.to">
        {{ item.text }}
      </router-link>
    </template>
  </v-toolbar-items>
</template>

<script>
export default {
  name: 'MenuLinks',
  props: {
    items: Array,
  },
};
</script>
