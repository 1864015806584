/* eslint max-len: 0 */
import {
  getLossAndPolicyInfoFormProps,
  getHealthInsuranceInfoFormProps,
  getRiskLocationInfoFormProps,
  getPropertyInfoFormProps,
  getInjuredInfoFormProps,
  getCallerInfoFormProps,
  getDisclaimersFormProps,
} from '@/utils/helpers/app/claimsCrawford';

const lossAndPolicyInfoFormProps = getLossAndPolicyInfoFormProps();
const healthInsuranceInfoFormProps = getHealthInsuranceInfoFormProps();
const riskLocationInfoFormProps = getRiskLocationInfoFormProps();
const propertyInfoFormProps = getPropertyInfoFormProps();
const injuredInfoFormProps = getInjuredInfoFormProps();
const callerInfoFormProps = getCallerInfoFormProps();
const disclaimersFormProps = getDisclaimersFormProps();

function groupFormPropsByAliases(propAliases) {
  return Object.values(propAliases).reduce((acc, propKey) => {
    if (typeof propKey === 'string' && !acc[propKey] && this[propKey] !== undefined) {
      acc[propKey] = this[propKey];
    }
    return acc;
  }, {});
}

function groupObjectFieldsByAliases(obj = {}, propAliases) {
  return Object.values(propAliases).reduce((acc, propKey) => {
    if (typeof propKey === 'string' && !acc[propKey] && obj[propKey] !== undefined) {
      acc[propKey] = obj[propKey];
    }
    return acc;
  }, {});
}

export default {
  props: {
    ...lossAndPolicyInfoFormProps,
    ...healthInsuranceInfoFormProps,
    ...riskLocationInfoFormProps,
    ...propertyInfoFormProps,
    ...injuredInfoFormProps,
    ...callerInfoFormProps,
    ...disclaimersFormProps,
    formParams: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    lossAndPolicyInfoFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.LOSS_AND_POLICY_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
    healthInsuranceInfoFormProps() {
      try {
        return {
          ...groupFormPropsByAliases.call(this, this.HEALTH_FIELD_ALIASES),
          shown:
            this[this.INJURED_FIELD_ALIASES.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN] &&
            this.healthInsuranceForms instanceof Object &&
            !!Object.keys(this.healthInsuranceForms).length,
          healthInsuranceForms:
            this.healthInsuranceForms instanceof Object
              ? Object.entries(this.healthInsuranceForms).reduce((acc, [key, value]) => {
                  acc[key] = groupObjectFieldsByAliases(value, this.HEALTH_FIELD_ALIASES);
                  return acc;
                }, {})
              : {},
        };
      } catch (err) {
        console.error(err);
      }
      return {};
    },
    riskLocationInfoFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.RISK_LOCATION_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
    propertyInfoFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.PROPERTY_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
    injuredInfoFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.INJURED_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
    callerInfoFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.CALLER_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
    disclaimersFormProps() {
      try {
        return groupFormPropsByAliases.call(this, this.DISCLAIMERS_FIELD_ALIASES);
      } catch (err) {
        console.error(err);
      }
      return {};
    },
  },
  methods: {
    handleClaimsInfoDisclaimerClick() {
      this.$emit('claimsInfoDisclaimerClick');
    },
  },
};
