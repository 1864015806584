import mapRoutesWithMeta from '@/router/helpers/mapRoutesWithMeta';
import packRoutes from '@/router/helpers/packRoutes';
import forgotPassword from './forgotPassword';
import login from './login';
import registerByInvite from './registerByInvite';
import resetPassword from './resetPassword';

const routes = packRoutes(forgotPassword, login, registerByInvite, resetPassword);

export default mapRoutesWithMeta(routes, {
  requiresAuth: false,
});
