const CREATE_CLAIM_INPUT = {
  sportsUnionId: 'ID!',
  claimantLastName: 'String!',
  claimantFirstName: 'String!',
  claimantMiddleName: 'String',
  claimantDOB: 'String!',
  claimantGender: 'GenderType!',
  claimantEmail: 'String!',
  claimantAddress: 'ClaimantAddressInput!',
  claimantFathersFullName: 'String',
  claimantMothersFullName: 'String',
  claimantRoleType: 'ClaimantRoleType!',
  insuranceCompany: 'String!',
  policyHoldersName: 'String!',
  policyNumber: 'Int!',
  groupNumber: 'Int',
  incidentDate: 'String!',
  incidentTime: 'String!',
  incidentLocation: 'String!',
  memberId: 'String',
  membershipType: 'MembershipType!',
  membershipOtherType: 'String',
  eventType: 'EventType!',
  isContact: 'Boolean!',
  contactType: 'ContactType',
  contactOtherType: 'String',
  injuredBodyPart: 'InjuredBodyPartType!',
  injuredBodySide: 'InjuredBodySideType!',
  injuryType: 'InjuryType!',
  severity: 'SeverityType!',
  isReceivedOnsite: 'Boolean!',
  isTakenByAmbulanceToHospital: 'Boolean!',
  nameOfEventOrTeamsCompetingAgainst: 'String!',
  injuryDescription: 'String',
  claimantSignature: 'String!',
  coachEmail: 'String!',
  coachFirstName: 'String',
  coachLastName: 'String',
  coachPhone: 'String',
  filesIds: '[ID]',
  clubNameOfInjured: 'String',
  territoryWhereIncidentOccurred: 'String',
  isAthleteWasTreatedByCoach: 'Boolean',
  plainSurface: 'PlainSurface',
  note: 'String',
};

export default form => {
  if (!(form instanceof Object)) {
    throw new Error('The provided form argument is not a valid Object');
  }
  return Object.entries(form).reduce((acc, [field, value]) => {
    if (CREATE_CLAIM_INPUT[field]) {
      acc[field] = value;
    }
    return acc;
  }, {});
};
