import { CRAWFORD_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const LOSS_AND_POLICY_FIELD_ALIASES = CRAWFORD_CLAIM_FORM_FIELD_ALIASES.LOSS_AND_POLICY;

export default () => ({
  // aliases for usage in form templates
  LOSS_AND_POLICY_FIELD_ALIASES: {
    type: Object,
    default: () => LOSS_AND_POLICY_FIELD_ALIASES,
  },
  [LOSS_AND_POLICY_FIELD_ALIASES.POLICY_NUMBER]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TYPE]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.POLICY_STATE]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_DATE]: [String, Number],
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TIME]: [String, Number],
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_DESCRIPTION]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_NAME]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_EVENT_TYPE]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_EVENT_NAME_AND_TYPE]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE1]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE2]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_CITY]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_STATE]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_ZIP]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_COUNTRY]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_IS_ON_PREMISSES]: Boolean,
  [LOSS_AND_POLICY_FIELD_ALIASES.GL_CAUSE_CODE]: String,
  [LOSS_AND_POLICY_FIELD_ALIASES.GL_DESCRIPTION_CODE]: String,
});
