import { USER_ROLES } from '@/utils/constants';

export default {
  authed: state => !!state.token,
  authToken: state => state.token,
  profile: state => state.profile,
  userRole: (state, getters) =>
    getters.authed
      ? state.profile && Object.values(state.profile).length && state.profile.role
      : USER_ROLES.GUEST,
  userIsBrokerAdmin: (state, getters) =>
    getters.authed &&
    state.profile &&
    Object.values(state.profile).length &&
    state.profile.role === 'BROKER_ADMIN',
  userBrokerOrganizations: state =>
    state.profile && Object.values(state.profile).length && state.profile.broker,
  homeRoute: (state, getters) => {
    if (getters.authed) {
      if (getters.userRole === USER_ROLES.SUPER_ADMIN) {
        return 'organizations';
      }
      if (getters.userRole === USER_ROLES.BROKER_ADMIN) {
        return 'fnols';
      }
    }
    return 'login';
  },
};
