import { OLYMPIC_CLAIM_FORM_FIELD_ALIASES } from '@/utils/constants';

const INCIDENT_FIELD_ALIASES = OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT;

export default () => ({
  // aliases for usage in form templates
  INCIDENT_FIELD_ALIASES: {
    type: Object,
    default: () => INCIDENT_FIELD_ALIASES,
  },
  loadingFiles: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_MEDICAL_PAYMENTS]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_HERIBAL_ANY_INSURANCE]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_HERIBAL_ANY_COMMUNICATIONS]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT]: [String, Object, Boolean],
  [INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT_TYPE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_DATE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_DESCRIPTION]: String,
  [INCIDENT_FIELD_ALIASES.INDICATION_TEETH_INVOLVED]: String,
  [INCIDENT_FIELD_ALIASES.DESCRIBTION_TEETH_INVOLVED]: String,
  [INCIDENT_FIELD_ALIASES.NAME_OF_TEAM]: String,
  // [INCIDENT_FIELD_ALIASES.SUPERVISOR_FIRST_NAME]: String,
  // [INCIDENT_FIELD_ALIASES.SUPERVISOR_LAST_NAME]: String,
  // [INCIDENT_FIELD_ALIASES.SUPERVISOR_TITLE]: String,
  [INCIDENT_FIELD_ALIASES.CURRENT_DATE]: String,
  [INCIDENT_FIELD_ALIASES.PLAYING_SURFACE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_TYPE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_TYPE]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_OTHER_TYPE]: String,
  // [INCIDENT_FIELD_ALIASES.INCIDENT_ONSITE_CARE_RECEIVED]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_RESULT_IN_DEATH]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_OTHER_CONTACT_TYPE]: String,
  // [INCIDENT_FIELD_ALIASES.INCIDENT_SEVERITY]: [String, Object],
  [INCIDENT_FIELD_ALIASES.INCIDENT_TAKEN_TO_HOSPITAL_BY_AMBULANCE]: Boolean,
  [INCIDENT_FIELD_ALIASES.INCIDENT_TIME]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE]: String,
  [INCIDENT_FIELD_ALIASES.INCIDENT_NOTE]: String,
});
