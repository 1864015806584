import getErrorMessage from '@/utils/helpers/graphql/getErrorMessage';
import { DIALOG_TYPES, DEFAULT_INFO_BTN_TEXT, DEFAULT_CONFIRM_BTN_TEXT } from './utils/constants';

export default {
  setDialogShown({ commit }, shown) {
    commit('SHOW_DIALOG', shown);
  },
  showDialog({ commit }, params) {
    const paramsPayload = {
      title: params.title || '',
      description: params.description || '',
      status: params.status || '',
      type: params.type || 'info',
      contentTextAlign: params.contentTextAlign || 'center',
      fullscreen: params.fullscreen || false,
    };
    paramsPayload.btnText =
      params.btnText ||
      (paramsPayload.type === DIALOG_TYPES.INFO ? DEFAULT_INFO_BTN_TEXT : DEFAULT_CONFIRM_BTN_TEXT);

    commit('SET_PARAMS', paramsPayload);
    commit('SHOW_DIALOG');

    return new Promise(resolve => {
      commit('SET_RESOLVE_FUNC', resolve);
    }).then(response => {
      commit('SHOW_DIALOG', false);
      return response;
    });
  },
  showInfoDialog({ dispatch }, params) {
    return dispatch('showDialog', Object.assign(params, { type: DIALOG_TYPES.INFO }));
  },
  showConfirmDialog({ dispatch }, params) {
    return dispatch('showDialog', Object.assign(params, { type: DIALOG_TYPES.CONFIRM }));
  },
  showErrorDialog({ dispatch }, { title = 'Error', error, description }) {
    const params = {
      title,
      description,
    };
    if (!description && error instanceof Object) {
      params.description = getErrorMessage(error);
    }
    return dispatch('showInfoDialog', params);
  },
  closeDialog({ commit }) {
    commit('RESOLVE_DIALOG', false);
    commit('SHOW_DIALOG', false);
  },
  resolveDialog({ commit }, payload) {
    commit('RESOLVE_DIALOG', payload);
  },
};
